import React from 'react';
import {Button,CircularProgress,Paper,TextField} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class GeneralElevatorConfig extends React.Component {

    state = {
		show_commission_nr: true,
		commission_nr: '',
		elevator_error_notification_sms: '',
		elevator_error_notification_email: '',
		floor_number_size: 80,
		floor_number_font: 'Roboto-Light.ttf',
		floor_number_color: '#FFC750',
		arrow_size: 200,
		arrow_type: 'YellowDoubleArrowFlat',
		show_door: false,
		door_symbol: 'dotted_arrows_yellow',
		door_size: 50,
		show_date: true,
		date_size: 50,
		time_size: 50,
		date_locale: 'en',
		date_format: 0,
		time_format: 'HH:mm',
		elevator_physical_connection_id: 'usb',
		elevator_data_handler_id:'adam4051',
		server_socket_ip: '192.168.1.101',
		server_socket_port: '502',
		info_message:"",
		info_title:"",
      	proxy_server_addr:"",
		kone_server_addr:'api.kone.com',
		api_login_addr:'',
		api_cmd_addr:'',
		api_socket_addr:'',
		api_gateway:'',
		api_user:'',
		api_password:'',
		building_id:'',
		group_id:'',
		lift_id:'',
      	car_id:'',
		deck_id:'',
		ldi_car_display_destination_floors:'', //5,6,7,8
		ldi_car_display_observation_floor:'',
		controller_type:'',
		can_open_lift_nr:'1',
		can_open_node_id:'18',
		can_baud_rate:'250000',
		debug_can_open_lift_protocol:false,
		baud_rate:'9600',
	    serial_baud_rate:'9600',
      	serial_device:'/dev/ttyS3',
		camera_device:'none',
		intercom_url:'',
		dimmer_on:'false',
      	trip_counter_second:'10',
      	default_floor_distance:'3.5',
		log0:'',
		log1:'',
		log2:'',
    }

	componentDidUpdate(prevProps,prevState) {
		if(prevProps.device_id!==this.props.device_id) {
			this.loadConfig();
		}
	}

    componentDidMount() {
      	this.loadConfig();
    }

    loadConfig() {
      	//console.log("---------------------loading deviceIOs");
		var url = ETVConstants.getServerBase()+"/elevator_config?action=get_connection_config&device_id="+this.props.device_id;
      	//console.log("loading url="+url);
	    fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("----->items="+JSON.stringify(result));
						//console.log("commNr="+result["commission_nr"]);
						this.setState(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      	this.setState({show_overlay:show});
      	show?this.setState({window:divID}):this.setState({window:''});
    }

	setIOMap( key, value) {
		//console.log("xxx setting key="+key+" value="+value);
		var props = this.state.ioMap;
		//console.log(JSON.stringify(props, null, 2));
		props[key]=value;
		this.setState({ioMap:props});
	}

	showInfoMessage(title,msg) {
		this.showWindow('message_div',true);
		this.setState({info_title:title, info_message:msg});
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}

	setDefault() {
		var default_state = {
			show_commission_nr: true,
			commission_nr: '',
			elevator_error_notification_sms: '',
			elevator_error_notification_email: '',
			floor_number_size: 80,
			floor_number_font: 'Roboto-Light.ttf',
			floor_number_color: '#FFC750',
			arrow_size: 200,
			arrow_type: 'YellowDoubleArrowFlat',
			show_door: false,
			door_symbol: 'dotted_arrows_yellow',
			door_size: 50,
			show_date: true,
			date_size: 50,
			time_size: 50,
			date_locale: 'en',
			date_format: 0,
			time_format: 'HH:mm',
			elevator_physical_connection_id: 'usb',
			elevator_data_handler_id:'adam4051',
			server_socket_ip: '192.168.1.101',
			server_socket_port: '502',
			info_message:"",
			info_title:"",
			kone_server_addr:'api.kone.com',
        	proxy_server_addr:"",
			api_cmd_addr:'',
			api_login_addr:'',
			api_socket_addr:'',
			api_gateway:'',
			api_user:'',
			api_password:'',
			building_id:'',
			group_id:'',
			lift_id:'',
			car_id:'',
        	deck_id:'',
			can_open_lift_nr:'1',
        	can_open_node_id:'18',
        	can_baud_rate:'250000',
			debug_can_open_lift_protocol:false,
			baud_rate:'9600',
        	serial_baud_rate:'9600',
        	serial_device:'/dev/ttyS3',
			camera_device:'none',
			intercom_url:'',
			dimmer_on:'false',
        	trip_counter_second:'10',
        	default_floor_distance:'3.5',
			log0:'',
			log1:'',
			log2:'',
    	}
		this.setState(default_state);
	}

	saveConfig() {
		this.showInfoMessage("save config...");
		var url = ETVConstants.getServerBase()+"/elevator_config?action=set_connection_config&device_id="+this.props.device_id;
		var configJSON = JSON.stringify(this.state);
		url += "&json_config="+encodeURIComponent(configJSON);
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					//console.log("Result="+JSON.stringify(result));
					this.showInfoMessage("Config","success");
					//console.log("xxxxxxxxxxxxxx="+result["000000"]);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					//console.log("EERRROR="+error);
					this.showInfoMessage("Config","error="+error);
					this.setState({
						error
					});
				}
			)
	}

	setElevatorConnection( con) {
		var physical = 'usb';
		if(con==='adam6051') {
			physical = 'socket';
		}
		if(con==='kone_api') {
			physical = 'dummy';
		}
		if(con==='kone_spi') {
			physical = 'dummy';
		}
		if(con==='laser_measurement') {
			physical = 'dummy';
		}
		if(con==='schindler_api') {
			physical = 'dummy';
		}
		if(con==='schindler_ldi_display') {
			physical = 'dummy';
		}
		if(con==='data_interpreter_proxy') {
			physical = 'dummy';
		}
		if(con==='can_open_lift') {
			physical = 'usb_can';
		}
		if(con==='schneider_controller') {
			physical = 'integrated_serial_port';
		}
		if(con==='simulator') {
			physical = 'dummy';
		}
		this.setState({elevator_data_handler_id:con, elevator_physical_connection_id:physical});
	}

    printSchindlerAPI() {
      return(
        <div>
            <FormControl fullWidth style={{marginTop:20}}>
                <InputLabel>Controller Type</InputLabel>
                <Select
                  label='Controller Type'
                  id='control_type_id'
                  value={this.state.controller_type}
                  fullWidth
				  size="small"
                  onChange={(e)=>{this.setState({controller_type:e.target.value})}}>
                   	<MenuItem value='destination_ctrl'>Destination Control</MenuItem>
                   	<MenuItem value='conventional_ctrl'>Conventional Control</MenuItem>
                </Select>
            </FormControl>
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_login_addr' label="Schindler API Login address" value={this.state.api_login_addr}  onChange={(e)=>{this.setState({api_login_addr:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_cmd_addr' label="Schindler API command address" value={this.state.api_cmd_addr} onChange={(e)=>{this.setState({api_cmd_addr:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_socket_addr' label="Schindler API WebSocket address" value={this.state.api_socket_addr} onChange={(e)=>{this.setState({api_socket_addr:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_gateway' label="API Gateway" value={this.state.api_gateway} onChange={(e)=>{this.setState({api_gateway:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_user' label="Schindler API UserID" value={this.state.api_user} onChange={(e)=>{this.setState({api_user:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_password' type='password' label="Schindler API Password" value={this.state.api_password} onChange={(e)=>{this.setState({api_password:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='building_id' label="Building ID" value={this.state.building_id} variant="outlined" onChange={(e)=>{this.setState({building_id:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='group_id' label="Group ID" value={this.state.group_id} variant="outlined" onChange={(e)=>{this.setState({group_id:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='car_id' label="Car ID" value={this.state.lift_id} variant="outlined" onChange={(e)=>{this.setState({car_id:e.target.value})}} />
            {this.state.controller_type==='destination_ctrl'?
                <TextField size='small' fullWidth style={{marginTop:20}} key='deck_id' label="Deck ID" value={this.state.deck_id} variant="outlined" onChange={(e)=>{this.setState({deck_id:e.target.value})}} />
            :
	            <span/>
            }
        </div>
      )
    }

    printSchindlerLDIDisplay() {
      return(
        <div >
			<FormControl fullWidth style={{marginTop:20}}>
            	<InputLabel>Controller Type</InputLabel>
                <Select
                  	label='Controller Type'
                  	id='control_type_id'
                  	value={this.state.controller_type}
                  	fullWidth
					size="small"
                  	onChange={(e)=>{this.setState({controller_type:e.target.value})}}>
                   	<MenuItem value='destination_ctrl'>Destination Control</MenuItem>
                </Select>
            </FormControl>
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_login_addr' label="Schindler API Login address" value={this.state.api_login_addr}  onChange={(e)=>{this.setState({api_login_addr:e.target.value})}} />
            <TextField size='small' fullWidth style={{marginTop:20}} key='api_cmd_addr' label="Schindler API command address" value={this.state.api_cmd_addr} onChange={(e)=>{this.setState({api_cmd_addr:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='api_socket_addr' label="Schindler API WebSocket address" value={this.state.api_socket_addr} onChange={(e)=>{this.setState({api_socket_addr:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='api_user' label="Schindler API UserID" value={this.state.api_user} onChange={(e)=>{this.setState({api_user:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='api_password' type='password' label="Schindler API Password" value={this.state.api_password} onChange={(e)=>{this.setState({api_password:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='building_id' label="Building ID" value={this.state.building_id} variant="outlined" onChange={(e)=>{this.setState({building_id:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='group_id' label="Group ID" value={this.state.group_id} variant="outlined" onChange={(e)=>{this.setState({group_id:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='car_id' label="Car ID" value={this.state.car_id} variant="outlined" onChange={(e)=>{this.setState({car_id:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='deck_id' label="Deck ID" value={this.state.deck_id} variant="outlined" onChange={(e)=>{this.setState({deck_id:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='ldi_car_display_destination_floors' label="Destination Floors e.g. 25,26,27,28" value={this.state.ldi_car_display_destination_floors} variant="outlined" onChange={(e)=>{this.setState({ldi_car_display_destination_floors:e.target.value})}} />
			<TextField size='small' fullWidth style={{marginTop:20}} key='ldi_car_display_observation_floor' label="Observation Floor" value={this.state.ldi_car_display_observation_floor} variant="outlined" onChange={(e)=>{this.setState({ldi_car_display_observation_floor:e.target.value})}} />
        </div>
      )
    }

    render() {
      return(
			<span>
				<Typography variant="h6">DeviceID: {this.props.device_id}</Typography>
				<Paper elevation={5} style={{marginTop:10,padding:10}}>
					<FormLabel>Commission Number</FormLabel>
					<FormControl fullWidth style={{marginTop:20}}>
						<InputLabel>Show Commission Number</InputLabel>
						<Select
							label="show commission number"
							id='commissionNr'
							size="small"
							fullWidth
							value={this.state.show_commission_nr}
							onChange={(e)=>{this.setState({show_commission_nr:e.target.value})}}>
							<MenuItem value='true'>YES</MenuItem>
							<MenuItem value='false'>NO</MenuItem>
						</Select>
					</FormControl>
					<TextField size='small' style={{marginTop:10}} fullWidth key='commission_nr' label="Commission Number" variant="outlined" value={this.state.commission_nr} onChange={(e)=>{this.setState({commission_nr:e.target.value})}} />
				</Paper>
				<Paper elevation={5} style={{marginTop:20,padding:10}}>
					<FormControl fullWidth>
						<FormLabel>Color Scheme (Arrows, Texts, Symbols)</FormLabel>
						<div>
							<input style={{marginTop:10}} type="color" value={this.state.floor_number_color} onChange={e => this.setState({floor_number_color:e.target.value} )} />
						</div>
					</FormControl>
					<FormControl fullWidth style={{marginTop:20}}>
						<InputLabel>Floor Number Font</InputLabel>
						<Select
							label='Floor Number Font'
							id='floor_number_font'
							size="small"
							value={this.state.floor_number_font}
							fullWidth
							onChange={(e)=>{this.setState({floor_number_font:e.target.value})}}>
								<MenuItem value='Roboto-Bold.ttf'>Roboto Bold</MenuItem>
								<MenuItem value='Roboto-BoldCondensed.ttf'>Roboto Bold Condensed</MenuItem>
								<MenuItem value='Roboto-Medium.ttf'>Roboto Medium</MenuItem>
								<MenuItem value='Roboto-Regular.ttf'>Roboto Regular</MenuItem>
								<MenuItem value='Roboto-Light.ttf'>Roboto Light</MenuItem>
								<MenuItem value='Roboto-Thin.ttf'>Roboto Thin</MenuItem>
								<MenuItem value='Amazonas.ttf'>Amazonas</MenuItem>
								<MenuItem value='Audiowide-Regular.ttf'>Audiowide-Regular</MenuItem>
								<MenuItem value='Aurach_Heavy.ttf'>Aurach Heavy</MenuItem>
								<MenuItem value='bitcrusher_condensed_bd.ttf'>BitCrusher Condensed</MenuItem>
								<MenuItem value='Biysk.ttf'>Biysk</MenuItem>
								<MenuItem value='Closeness-Bold.ttf'>Closeness-Bold</MenuItem>
								<MenuItem value='ChakraPetch-Semibold.ttf'>ChakraPetch Semibold</MenuItem>
								<MenuItem value='D-DIN-Bold.ttf'>D-DIN-Bold</MenuItem>
								<MenuItem value='Dited.otf'>Dited</MenuItem>
								<MenuItem value='Exo2-Semibold.ttf'>Exo2 Semibold</MenuItem>
								<MenuItem value='hankenround-Regular.ttf'>Hankenround</MenuItem>
								<MenuItem value='Lato-Bold.ttf'>Lato Bold</MenuItem>
								<MenuItem value='Lato-Regular.ttf'>Lato Regular</MenuItem>
								<MenuItem value='Lato-Thin.ttf'>Lato Thin</MenuItem>
								<MenuItem value='LCDPHONE.TTF'>LCD Dotted</MenuItem>
								<MenuItem value='MYRIADPRO-BOLD.OTF'>MYRIADPRO Bold</MenuItem>
								<MenuItem value='MYRIADPRO-REGULAR.OTF'>MYRIADPRO Regular</MenuItem>
								<MenuItem value='Mercy.ttf'>Mercy</MenuItem>
								<MenuItem value='neuropol.ttf'>Neuropol</MenuItem>
								<MenuItem value='NotoSans-Bold.ttf'>NotoSans Bold</MenuItem>
								<MenuItem value='NotoSans-Regular.ttf'>NotoSans Regular</MenuItem>
								<MenuItem value='NotoSans-Variable.ttf'>NotoSans Variable</MenuItem>
								<MenuItem value='Nunito-SemiBold.ttf'>Nunito Semibold</MenuItem>
								<MenuItem value='Orbitron-SemiBold.ttf'>Orbitron Semibold</MenuItem>
								<MenuItem value='OpenSans-Light.ttf'>OpenSans Light (APK91+)</MenuItem>
								<MenuItem value='OpenSans-Medium.ttf'>OpenSans Medium (APK91+)</MenuItem>
								<MenuItem value='OpenSans-Regular.ttf'>OpenSans Regular (APK91+)</MenuItem>
								<MenuItem value='Perfograma.otf'>Perfograma</MenuItem>
								<MenuItem value='SIMPLIFICA.ttf'>Simplifica</MenuItem>
								<MenuItem value='trs-million.ttf'>TRS-million</MenuItem>
								<MenuItem value='led2.ttf'>LED 2</MenuItem>
								<MenuItem value='led3.ttf'>LED 3</MenuItem>
								<MenuItem value='led4.ttf'>LED 4</MenuItem>
								<MenuItem value='led5.ttf'>LED 5</MenuItem>
								<MenuItem value='led6.ttf'>LED 6</MenuItem>
								<MenuItem value='led7.ttf'>LED 7</MenuItem>
								<MenuItem value='led8.ttf'>LED 8</MenuItem>
								<MenuItem value='led9.ttf'>LED 9</MenuItem>
						</Select>
						<TextField style={{marginTop:20}} size='small' key='floor_number_size' label="Floor Number Size" value={this.state.floor_number_size} variant="outlined" onChange={(e)=>{this.setState({floor_number_size:e.target.value})}} />
					</FormControl>
				</Paper>
				<Paper elevation={5} style={{marginTop:20,padding:10}}>
					<FormLabel>Arrows</FormLabel>
					<TextField fullWidth  size="small" style={{marginTop:20}} type="number" label="Arrow Size" value={this.state.arrow_size} variant="outlined" onChange={(e)=>{this.setState({arrow_size:e.target.value})}} />
					<FormControl fullWidth style={{marginTop:20}}>
						<InputLabel>Arrow Type</InputLabel>
						<Select
							label='Arrow Type'
							id='arrow_type'
							value={this.state.arrow_type}
							fullWidth
							onChange={(e)=>{this.setState({arrow_type:e.target.value})}}>
								<MenuItem value='OrangeDotted'>Dotted Arrow</MenuItem>
								<MenuItem value='YellowArrowFlat'>Flat Desgin</MenuItem>
								<MenuItem value='YellowDoubleArrowFlat'>Double Arrow Flat Desgin</MenuItem>
								<MenuItem value='Yellow_Style_1'>Dotted Arrow Bold</MenuItem>
								<MenuItem value='Yellow_Style_2'>Double Arrow Dotted</MenuItem>
								<MenuItem value='Yellow_Style_3'>Square Dotted Triangle</MenuItem>
								<MenuItem value='Yellow_Style_4'>Square Dotted Single Arrow</MenuItem>
								<MenuItem value='Yellow_Style_5'>Yellow Double Arrow</MenuItem>
								<MenuItem value='GreenRedArrow'>Green / Red - Style 1</MenuItem>
								<MenuItem value='arrow_style_6'>Green / Red - Style 2</MenuItem>
								<MenuItem value='arrow_style_7'>Green / Red - Style 3</MenuItem>
								<MenuItem value='arrow_style_8'>Green / Red - Style 4</MenuItem>
								<MenuItem value='arrow_style_9'>Blue Style</MenuItem>
								<MenuItem value='arrow_style_9'>Blue Style</MenuItem>
								<MenuItem value='arrow_style_10'>Arrow Style 10</MenuItem>
								<MenuItem value='arrow_style_11'>Arrow Style 11</MenuItem>
								<MenuItem value='arrow_style_12'>Arrow Style 12</MenuItem>
								<MenuItem value='arrow_style_13'>Arrow Style 13 (green up / red down)</MenuItem>
								<MenuItem value='arrow_style_14'>Arrow Style 14</MenuItem>
								<MenuItem value='arrow_style_15'>Arrow Style 15</MenuItem>
								<MenuItem value='arrow_style_16'>Arrow Style 16</MenuItem>
								<MenuItem value='arrow_style_17'>Arrow Style 17</MenuItem>
								<MenuItem value='arrow_style_18'>Arrow Style 18</MenuItem>
								<MenuItem value='arrow_style_19'>Arrow Style 19</MenuItem>
								<MenuItem value='arrow_style_20'>Arrow Style 20</MenuItem>
								<MenuItem value='arrow_style_21'>Arrow Style 21</MenuItem>
								<MenuItem value='arrow_style_22'>Arrow Style 22</MenuItem>
								<MenuItem value='arrow_style_23'>Arrow Style 23</MenuItem>
								<MenuItem value='arrow_style_24'>Arrow Style 24</MenuItem>
								<MenuItem value='arrow_style_25'>Arrow Style 25</MenuItem>
								<MenuItem value='arrow_style_26'>Arrow Style 26</MenuItem>
								<MenuItem value='arrow_style_27'>Arrow Style 27 (APK83+)</MenuItem>
								<MenuItem value='arrow_style_28'>Arrow Style 28 (APK83+)</MenuItem>
								<MenuItem value='arrow_style_29'>Arrow Style 29 (APK83+)</MenuItem>
								<MenuItem value='arrow_style_30'>Arrow Style 30 (APK91+)</MenuItem>
								<MenuItem value='arrow_style_31'>Arrow Style 31 (APK91+)</MenuItem>
								<MenuItem value='arrow_gif_style_1'>Animated Arrow 1 (Yellow, APK84+)</MenuItem>
								<MenuItem value='arrow_gif_style_2'>Animated Arrow 2 (White, APK84+)</MenuItem>
								<MenuItem value='arrow_gif_style_3'>Animated Arrow 3 (Default Color, APK84+)</MenuItem>
								<MenuItem value='arrow_gif_style_4'>Animated Arrow 4 (Default Color, APK84+)</MenuItem>
								<MenuItem value='arrow_gif_style_5'>Animated Arrow 5 (Default Color, APK84+)</MenuItem>
							 </Select>
					</FormControl>
						{this.state.arrow_type==='GreenRedArrow'?<img alt='Red Green Arrow' src='/images/arrows/green_arrow_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='OrangeDotted'?<img alt='Dotted Arrow' src='/images/arrows/up_on_orange.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='YellowArrowFlat'?<img alt='Yellow Arrow Flt' src='/images/arrows/arrow_up_single_on.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='YellowDoubleArrowFlat'?<img alt='Double Arrow' src='/images/arrows/double_arrow_up_on.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='Yellow_Style_1'?<img alt='Yellow Style 1' src='/images/arrows/up_on_style_1.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='Yellow_Style_2'?<img alt='Yellow Style 2' src='/images/arrows/up_on_style_2.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='Yellow_Style_3'?<img alt='Yellow Style 3' src='/images/arrows/up_on_style_3.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='Yellow_Style_4'?<img alt='Yellow Style 4' src='/images/arrows/up_on_style_4.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='Yellow_Style_5'?<img alt='Yellow Style 5' src='/images/arrows/up_on_style_5.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_6'?<img alt='Style 6' src='/images/arrows/arrow_style_6.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_7'?<img alt='Style 7' src='/images/arrows/arrow_style_7.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_8'?<img alt='Style 8' src='/images/arrows/arrow_style_8.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_9'?<img alt='Style 9' src='/images/arrows/arrow_style_9.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_10'?<img alt='Style 10' src='/images/arrows/arrow_style_10_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_11'?<img alt='Style 11' src='/images/arrows/arrow_style_11_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_12'?<img alt='Style 12' src='/images/arrows/arrow_style_12_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_13'?<img alt='Style 13' src='/images/arrows/arrow_style_13_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_14'?<img alt='Style 14' src='/images/arrows/arrow_style_14_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_15'?<img alt='Style 15' src='/images/arrows/arrow_style_15_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_16'?<img alt='Style 16' src='/images/arrows/arrow_style_16_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_17'?<img alt='Style 17' src='/images/arrows/arrow_style_17_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_18'?<img alt='Style 18' src='/images/arrows/arrow_style_18_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_19'?<img alt='Style 19' src='/images/arrows/arrow_style_19_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_20'?<img alt='Style 20' src='/images/arrows/arrow_style_20_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_21'?<img alt='Style 21' src='/images/arrows/arrow_style_21_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_22'?<img alt='Style 22' src='/images/arrows/arrow_style_22_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_23'?<img alt='Style 23' src='/images/arrows/arrow_style_23_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_24'?<img alt='Style 24' src='/images/arrows/arrow_style_24_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_25'?<img alt='Style 25' src='/images/arrows/arrow_style_25_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_26'?<img alt='Style 26' src='/images/arrows/arrow_style_26_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_27'?<img alt='Style 27' src='/images/arrows/arrow_style_27_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_28'?<img alt='Style 28' src='/images/arrows/arrow_style_28_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_29'?<img alt='Style 29' src='/images/arrows/arrow_style_29_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_30'?<img alt='Style 30' src='/images/arrows/arrow_style_30_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_style_31'?<img alt='Style 31' src='/images/arrows/arrow_style_31_up.png' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_gif_style_1'?<img alt='Animated 1' src='/images/arrows/up_gif_style_1.gif' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_gif_style_2'?<img alt='Animated 2' src='/images/arrows/up_gif_style_2.gif' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_gif_style_3'?<img alt='Animated 3' src='/images/arrows/up_gif_style_3.gif' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_gif_style_4'?<img alt='Animated 4' src='/images/arrows/up_gif_style_4.gif' width='80' style={{marginLeft:20, marginTop:10}}/>:
						this.state.arrow_type==='arrow_gif_style_5'?<img alt='Animated 5' src='/images/arrows/up_gif_style_5.gif' width='80' style={{marginLeft:20, marginTop:10}}/>:
						<span/>}
					</Paper>

					<Paper elevation={5} style={{marginTop:20,padding:10}}>
						<FormLabel>Door Symbol</FormLabel>
						<FormControl fullWidth style={{marginTop:20}}>
							<InputLabel>Show Door Symbol</InputLabel>
							<Select
								label='Show Door Symbol'
								size="small"
								id='show_door'
								value={this.state.show_door}
								fullWidth
								onChange={(e)=>{this.setState({show_door:e.target.value})}}>
									<MenuItem value='true'>YES</MenuItem>
									<MenuItem value='false'>NO</MenuItem>
							</Select>
						</FormControl>
						<TextField size='small' style={{marginTop:20}} key='door_size' type="number" fullWidth label="Door Symbol Size" value={this.state.door_size} variant="outlined" onChange={(e)=>{this.setState({door_size:e.target.value})}} />
						<FormControl fullWidth style={{marginTop:20}}>
							<InputLabel>Door Symbol</InputLabel>
							<Select
								label='Door Symbol'
								id='door_symbol'
								fullWidth
								size="small"
								value={this.state.door_symbol}
								onChange={(e)=>{this.setState({door_symbol:e.target.value})}}>
								<MenuItem value='default_black'>Default Style</MenuItem>
								<MenuItem value='dotted_arrows_lightblue'>Door Style 1</MenuItem>
								<MenuItem value='dotted_arrows_yellow'>Door Style 2</MenuItem>
								<MenuItem value='door_style_3'>Door Style 3</MenuItem>
								<MenuItem value='door_style_4'>Door Style 4</MenuItem>
								<MenuItem value='door_style_5'>Door Style 5</MenuItem>
								<MenuItem value='door_style_6'>Door Style 6</MenuItem>
							 </Select>
						 </FormControl>
					</Paper>

					<Paper elevation={5} style={{marginTop:20,padding:10}}>
						<FormLabel>Date and Time</FormLabel>
						<FormControl style={{marginTop:20}} fullWidth>
							<InputLabel>Show Date</InputLabel>
							<Select
								label='Show Date'
								id='show_date'
								value={this.state.show_date}
								fullWidth
								size="small"
								onChange={(e)=>{this.setState({show_date:e.target.value})}}>
								<MenuItem value='true'>YES</MenuItem>
								<MenuItem value='false'>NO</MenuItem>
							</Select>
						</FormControl>
						<TextField size='small' type="number" fullWidth style={{marginTop:20}} key='time_size' label="Time Size" value={this.state.time_size} variant="outlined" onChange={(e)=>{this.setState({time_size:e.target.value})}} />
						<TextField size='small' type="number" fullWidth style={{marginTop:20}} key='date_size' label="Date Size" value={this.state.date_size} variant="outlined" onChange={(e)=>{this.setState({date_size:e.target.value})}} />
						<FormControl fullWidth style={{marginTop:20}}>
							<InputLabel>Date Locale</InputLabel>
							<Select
								label='Date Locale'
								id='date_locale'
								fullWidth
								size="small"
								value={this.state.date_locale}
								onChange={(e)=>{this.setState({date_locale:e.target.value})}}>
								<MenuItem value='en'>English</MenuItem>
								<MenuItem value='en-SG'>English - Singapore</MenuItem>
								<MenuItem value='de'>German</MenuItem>
								<MenuItem value='ja'>Japanese</MenuItem>
								<MenuItem value='zh'>Chinese</MenuItem>
								<MenuItem value='pt'>Portugese</MenuItem>
								<MenuItem value='fr'>French</MenuItem>
								<MenuItem value='it'>Italian</MenuItem>
								<MenuItem value='ko'>Korean</MenuItem>
								<MenuItem value='es'>Spanish</MenuItem>
								<MenuItem value='th'>Thai</MenuItem>
								<MenuItem value='vi'>Vietnamese</MenuItem>
							</Select>
							</FormControl>
							<FormControl fullWidth style={{marginTop:20}}>
								<InputLabel>Time Format</InputLabel>
								<Select
									label='Time Format'
									id='time_format'
									value={this.state.time_format}
									fullWidth
									size="small"
									onChange={(e)=>{this.setState({time_format:e.target.value})}}>
										<MenuItem value='HH:mm'>HH:mm (13:45)</MenuItem>
										<MenuItem value='HH:mm aa'>HH:mm AM/PM (13:45 PM)</MenuItem>
										<MenuItem value='hh:mm aa'>hh:mm AM/PM (1:45 PM)</MenuItem>
								</Select>
							</FormControl>
							<FormControl fullWidth style={{marginTop:20}}>
								<InputLabel>Date Format</InputLabel>
								<Select
									label='Date Format'
									id='date_format'
									value={this.state.date_format}
									fullWidth
									size="small"
									onChange={(e)=>{this.setState({date_format:e.target.value})}}>
										<MenuItem value='0'>localized Full (e.g. Friday, December 23, 2022)</MenuItem>
										<MenuItem value='1'>localized Long (e.g. December 23, 2022)</MenuItem>
										<MenuItem value='2'>localized Medium (e.g. Dec 23, 2022)</MenuItem>
										<MenuItem value='3'>localized Short (e.g. 12/23/2022)</MenuItem>
										<MenuItem value='EEE'>Weekday short (e.g. Wed)</MenuItem>
										<MenuItem value='EEEE'>Weekday full (e.g. Wednesday)</MenuItem>
										<MenuItem value='dd-MM-yyyy'>dd-MM-yyyy (e.g. 23-12-2022)</MenuItem>
										<MenuItem value='dd/MM/yyyy'>dd/MM/yyyy (e.g. 23/12/2022)</MenuItem>
										<MenuItem value='MM/dd/yyyy'>MM/dd/yyyy (e.g. 12/23/2022)</MenuItem>
										<MenuItem value='MM-dd-yyyy'>MM-dd-yyyy (e.g. 12-23-2022)</MenuItem>
										<MenuItem value='MM-dd'>MM-dd (e.g. 12-23)</MenuItem>
										<MenuItem value='MM.dd'>MM.dd (e.g. 12.23)</MenuItem>
										<MenuItem value='dd.MM'>dd.MM (e.g. 23.12)</MenuItem>
										<MenuItem value='dd-MM'>dd-MM (e.g. 23-12)</MenuItem>
								</Select>
							</FormControl>
						</Paper>

						<Paper elevation={5} style={{marginTop:20,padding:10}}>
							<FormLabel>Camera</FormLabel>
							<FormControl fullWidth style={{marginTop:20}}>
								 <InputLabel>USB Camera</InputLabel>
								 <Select
									label='USB Camera'
									id='camera_device'
									value={this.state.camera_device}
									fullWidth
									size="small"
									onChange={(e)=>{this.setState({camera_device:e.target.value})}}>
										<MenuItem value='none'>none</MenuItem>
										<MenuItem value='/dev/video0'>/dev/video0</MenuItem>
										<MenuItem value='/dev/video1'>/dev/video1</MenuItem>
										<MenuItem value='/dev/video2'>/dev/video2</MenuItem>
										<MenuItem value='/dev/video3'>/dev/video3</MenuItem>
										<MenuItem value='/dev/video4'>/dev/video4</MenuItem>
								</Select>
							</FormControl>
							<TextField size='small' style={{marginTop:20}} key='intercom_url' fullWidth label="Intercom Stream (IP camera)" value={this.state.intercom_url} variant="outlined" onChange={(e)=>{this.setState({intercom_url:e.target.value})}} />
						</Paper>

						<Paper elevation={5} style={{marginTop:20,padding:10}}>
							<FormLabel> Elevator Connection </FormLabel>
							<FormControl fullWidth style={{marginTop:20}}>
								<InputLabel>Connection</InputLabel>
								<Select
									label='Connection'
									id='connection'
									size="small"
									fullWidth
									value={this.state.elevator_data_handler_id}
									onChange={(e)=>{this.setElevatorConnection(e.target.value)}}>
										<MenuItem value='adam6051'>Adam 6050 / Adam 6051</MenuItem>
										<MenuItem value='adam4051'>Adam 4051 (RS485)</MenuItem>
										<MenuItem value='kone_spi'>KONE SPI Interface</MenuItem>
										<MenuItem value='laser_measurement'>Laser Measurement</MenuItem>
										<MenuItem value='data_interpreter_proxy'>Data Interpreter Proxy</MenuItem>
										<MenuItem value='schindler_api'>SCHINDLER API</MenuItem>
										<MenuItem value='schindler_ldi_display'>SCHINDLER LDI Display</MenuItem>
										<MenuItem value='can_open_lift'>CanOpen Lift</MenuItem>
										<MenuItem value='schneider_controller'>Asia-Schneider Controller</MenuItem>
										<MenuItem value='simulator'>Simulator</MenuItem>
								</Select>
							</FormControl>
							{this.state.log0 && this.state.log0.length>0?
								<div style={{marginTop:10}}>
									<Button size='small' color="primary" variant='contained' onClick={() => window.open(this.state.log0,"_blank")}>Log 0</Button>&nbsp;
									<Button size='small' color="primary" variant='contained' onClick={() => window.open(this.state.log1,"_blank")}>Log 1</Button>&nbsp;
									<Button size='small' color="primary" variant='contained' onClick={() => window.open(this.state.log2,"_blank")}>Log 2</Button>&nbsp;
								</div>
								:
								<span/>
							}
							{this.state.elevator_data_handler_id==='adam6051'?
									<div>
										<TextField style={{marginTop:20}} size='small' fullWidth key='server_socket_ip' label="IP Address" value={this.state.server_socket_ip} variant="outlined" onChange={(e)=>{this.setState({server_socket_ip:e.target.value})}} />
										<TextField style={{marginTop:20}} size='small' type="number" fullWidth key='server_socket_port' label="IP Port" value={this.state.server_socket_port} variant="outlined" onChange={(e)=>{this.setState({server_socket_port:e.target.value})}} />
									</div>:

							this.state.elevator_data_handler_id==='adam4051'?
								<div style={{marginTop:20}}>
									<FormControl fullWidth>
										<InputLabel>Input Connection</InputLabel>
										<Select
											label='Input Connection'
											id='input_connection'
											value={this.state.elevator_physical_connection_id}
											fullWidth
											size="small"
											onChange={(e)=>{e.target.value==='usb'?this.setState({baudrate:9600,elevator_physical_connection_id:e.target.value}):this.setState({elevator_physical_connection_id:e.target.value})}}>
												<MenuItem value='integrated_serial_port'>Integrated Serial Port</MenuItem>
												<MenuItem value='usb'>USB (DSDTech)</MenuItem>
										</Select>
									</FormControl>
									{this.state.elevator_physical_connection_id==='integrated_serial_port'?
									<div>
										<FormControl fullWidth style={{marginTop:20}}>
											<InputLabel>COM-Port</InputLabel>
											<Select
												label='serial device'
												id='serial_device'
												value={this.state.serial_device}
												fullWidth
												size="small"
												onChange={(e)=>{this.setState({serial_device:e.target.value})}}>
													<MenuItem value='/dev/ttyS0'>/dev/ttyS0</MenuItem>
													<MenuItem value='/dev/ttyS1'>/dev/ttyS1</MenuItem>
													<MenuItem value='/dev/ttyS2'>/dev/ttyS2</MenuItem>
													<MenuItem value='/dev/ttyS3'>/dev/ttyS3</MenuItem>
													<MenuItem value='/dev/ttyS4'>/dev/ttyS4</MenuItem>
											</Select>
										</FormControl>
										<FormControl fullWidth style={{marginTop:20}}>
											<InputLabel>Serial Baudrate</InputLabel>
											<Select
												label='Serial Baudrate'
												id='serial_baudrate'
												value={this.state.serial_baudrate}
												fullWidth
												size="small"
												onChange={(e)=>{this.setState({serial_baudrate:e.target.value})}}>
												<MenuItem value='9600'>9600</MenuItem>
											</Select>
											</FormControl>
									</div>
									:
									<span/>
									}
								</div>:

							this.state.elevator_data_handler_id==='kone_api'?
								<div>
									<TextField size='small' fullWidth key='kone_server_addr' label="KONE API Server Address" value={this.state.kone_server_addr} variant="outlined" onChange={(e)=>{this.setState({kone_server_addr:e.target.value})}} />
									<TextField size='small' fullWidth key='building_id' label="Building ID" value={this.state.building_id} variant="outlined" onChange={(e)=>{this.setState({building_id:e.target.value})}} />
									<TextField size='small' fullWidth key='group_id' label="Group ID" value={this.state.group_id} variant="outlined" onChange={(e)=>{this.setState({group_id:e.target.value})}} />
									<TextField size='small' fullWidth key='lift_id' label="Lift ID" value={this.state.lift_id} variant="outlined" onChange={(e)=>{this.setState({lift_id:e.target.value})}} />
									{this.state.controller_type==='destination_ctrl'?
										<div style={{marginTop:10}}>
											<TextField size='small' fullWidth key='deck_id' label="Deck ID" value={this.state.deck_id} variant="outlined" onChange={(e)=>{this.setState({deck_id:e.target.value})}} />
										</div>
										:
										<span/>
									}
								</div>
							:

							this.state.elevator_data_handler_id==='data_interpreter_proxy'?
								<TextField fullWidth style={{marginTop:20}} size='small' key='proxy_server_addr' label="Proxy Server Address" value={this.state.proxy_server_addr} variant="outlined" onChange={(e)=>{this.setState({proxy_server_addr:e.target.value})}} />
							:

							this.state.elevator_data_handler_id==='schindler_api'? this.printSchindlerAPI():
							this.state.elevator_data_handler_id==='schindler_ldi_display'? this.printSchindlerLDIDisplay():

							this.state.elevator_data_handler_id==='schneider_controller'?
								<div style={{marginTop:20}}>
									<FormControl fullWidth>
										<InputLabel>COM-Port</InputLabel>
										<Select
											label='Serial Device'
											id='serial_device'
											value={this.state.serial_device}
											fullWidth
											size="small"
											onChange={(e)=>{this.setState({serial_device:e.target.value})}}>
											<MenuItem value='/dev/ttyS0'>/dev/ttyS0</MenuItem>
											<MenuItem value='/dev/ttyS1'>/dev/ttyS1</MenuItem>
											<MenuItem value='/dev/ttyS2'>/dev/ttyS2</MenuItem>
											<MenuItem value='/dev/ttyS3'>/dev/ttyS3</MenuItem>
											<MenuItem value='/dev/ttyS4'>/dev/ttyS4</MenuItem>
										</Select>
									</FormControl>
									<FormControl fullWidth style={{marginTop:20}}>
										<InputLabel>Serial Baudrate</InputLabel>
										<Select
											label='serial baudrate'
											id='serial_baudrate'
											fullWidth
											size="small"
											value={this.state.serial_baudrate}
											onChange={(e)=>{this.setState({serial_baudrate:e.target.value})}}>
											<MenuItem value='9600'>9600</MenuItem>
										</Select>
									</FormControl>
								</div>
							:

							this.state.elevator_data_handler_id==='can_open_lift'?
								<div>
									<FormControl fullWidth style={{marginTop:20}}>
										<InputLabel>Lift Number</InputLabel>
										<Select
											label='Lift Number (1-8)'
											id='can_open_lift'
											value={this.state.can_open_lift_nr}
											fullWidth
											size="small"
											onChange={(e)=>{this.setState({can_open_lift_nr:e.target.value})}}>
												<MenuItem value={1}>1</MenuItem>
												<MenuItem value={2}>2</MenuItem>
												<MenuItem value={3}>3</MenuItem>
												<MenuItem value={4}>4</MenuItem>
												<MenuItem value={5}>5</MenuItem>
												<MenuItem value={6}>6</MenuItem>
												<MenuItem value={7}>7</MenuItem>
												<MenuItem value={8}>8</MenuItem>
										</Select>
									</FormControl>
									<FormControl fullWidth style={{marginTop:20}}>
										<InputLabel>Can Bus Node ID</InputLabel>
										<Select
										label='Can Open Node ID'
										id='can_open_node_id'
										value={this.state.can_open_node_id}
										fullWidth
										size="small"
										onChange={(e)=>{this.setState({can_open_node_id:e.target.value})}}>
											<MenuItem value={17}>0x11 (17)</MenuItem>
											<MenuItem value={18}>0x12 (18)</MenuItem>
											<MenuItem value={19}>0x13 (19)</MenuItem>
											<MenuItem value={20}>0x14 (20)</MenuItem>
											<MenuItem value={21}>0x15 (21)</MenuItem>
											<MenuItem value={22}>0x16 (22)</MenuItem>
											<MenuItem value={23}>0x17 (23)</MenuItem>
											<MenuItem value={24}>0x18 (24)</MenuItem>
											<MenuItem value={25}>0x19 (25)</MenuItem>
											<MenuItem value={26}>0x1A (26)</MenuItem>
											<MenuItem value={27}>0x1B (27)</MenuItem>
											<MenuItem value={28}>0x1C (28)</MenuItem>
											<MenuItem value={29}>0x1D (29)</MenuItem>
											<MenuItem value={30}>0x1E (30)</MenuItem>
											<MenuItem value={31}>0x1F (31)</MenuItem>
											<MenuItem value={32}>0x20 (32)</MenuItem>
											<MenuItem value={33}>0x21 (33)</MenuItem>
											<MenuItem value={34}>0x22 (34)</MenuItem>
											<MenuItem value={35}>0x23 (35)</MenuItem>
											<MenuItem value={36}>0x24 (36)</MenuItem>
											<MenuItem value={37}>0x25 (37)</MenuItem>
											<MenuItem value={38}>0x26 (38)</MenuItem>
											<MenuItem value={39}>0x27 (39)</MenuItem>
											<MenuItem value={40}>0x28 (40)</MenuItem>
											<MenuItem value={41}>0x29 (41)</MenuItem>
											<MenuItem value={42}>0x2A (42)</MenuItem>
											<MenuItem value={43}>0x2B (43)</MenuItem>
											<MenuItem value={44}>0x2C (44)</MenuItem>
											<MenuItem value={45}>0x2D (45)</MenuItem>
											<MenuItem value={46}>0x2E (46)</MenuItem>
											<MenuItem value={47}>0x2F (47)</MenuItem>
											<MenuItem value={48}>0x30 (48)</MenuItem>
											<MenuItem value={49}>0x31 (49)</MenuItem>
											<MenuItem value={50}>0x32 (50)</MenuItem>
										</Select>
									</FormControl>
									<FormControl fullWidth style={{marginTop:20}}>
										<InputLabel>Baud Rate</InputLabel>
										<Select
											label='Baud Rate'
											id='can_baud_rate'
											value={this.state.can_baud_rate}
											fullWidth
											size="small"
											onChange={(e)=>{this.setState({can_baud_rate:e.target.value})}}>
											<MenuItem value='125000'>125 kbit</MenuItem>
											<MenuItem value='250000'>250 kbit</MenuItem>
										</Select>
									</FormControl>
									<FormControl fullWidth style={{marginTop:20}}>
										<InputLabel>Debug Connection</InputLabel>
										<Select
											label='Debug Protocol'
											id='debug_protocol'
											value={this.state.debug_can_open_lift_protocol}
											size="small"
											fullWidth
											onChange={(e)=>{this.setState({debug_can_open_lift_protocol:e.target.value})}}>
												<MenuItem value='true'>YES</MenuItem>
												<MenuItem value='false'>NO</MenuItem>
										</Select>
									</FormControl>
								</div>
							:
							this.state.elevator_data_handler_id==='simulator'?<span/>:<span/>
							}
						</Paper>

						<Paper elevation={5} style={{marginTop:20,padding:10}}>
							<FormLabel>Screen Protection</FormLabel>
							<FormControl fullWidth style={{marginTop:20}}>
								<InputLabel>Screen Dimmer</InputLabel>
								<Select
									label='Screen Dimmer'
									id='screen_dimmer'
									value={this.state.dimmer_on}
									fullWidth
									size="small"
									onChange={(e)=>{this.setState({dimmer_on:e.target.value})}}>
									<MenuItem value='false'>no (no dimming)</MenuItem>
									<MenuItem value='true'>yes (dimming)</MenuItem>
								</Select>
							</FormControl>
						</Paper>
            
						<Paper elevation={5} style={{marginTop:20,padding:10}}>
							<FormLabel>Counters</FormLabel>
		                  	<TextField size='small' fullWidth style={{marginTop:20}} type='number' key='trip_counter_second' label="Trip Counter Seconds [s]" value={this.state.trip_counter_second} onChange={(e)=>{this.setState({trip_counter_second:e.target.value})}} /><br/>
                  			<TextField size='small' fullWidth style={{marginTop:20}} type='number' key='default_floor_distance' label="Standard Floor Distance [m]" value={this.state.default_floor_distance} onChange={(e)=>{this.setState({default_floor_distance:e.target.value})}} />
						</Paper>

						<br/>
						<Button size='small' color="primary" variant='contained' onClick={() => this.setDefault()}>Set Default</Button>
						&nbsp;&nbsp;
						<Button size='small' variant="contained" color="primary" startIcon={<Save/>} onClick={() => this.saveConfig()}>{ETVConstants.trans("save")}</Button>

						
					<Dialog
						open={this.state.window==='message_div'}
						maxWidth='xs'
						fullWidth
						onClose={() => this.setState({window:'',aborted:true, show_progress:false})  }
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
						<DialogContent>
							<Typography variant='body1'>{this.state.info_message}</Typography>
							{this.state.show_progress?<CircularProgress/>:<span/>}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({window:'',aborted:true, showProgress:false}) } color="primary">{ETVConstants.trans("close")}</Button>
						</DialogActions>
					</Dialog>
				   
		   	</span>
			);
	}
}

export default GeneralElevatorConfig
