import React, { Component } from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';

const initialOptions = [];

class ChipInput extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (event, newValue) => {
    // This handles both adding and deleting chips
    if (newValue.length > this.props.value.length) {
      // New chip added
      const newChip = newValue.find(chip => !this.props.value.includes(chip));
      this.props.onAdd(newChip);
    } else {
      // Chip deleted
      const removedChip = this.props.value.find(chip => !newValue.includes(chip));
      this.props.onDelete(removedChip);
    }
  };

  render() {
    const { value, label, placeholder } = this.props;
    return (
      <Autocomplete
        multiple
        id="tags-filled"
        options={initialOptions}
        freeSolo
        value={value} // Pass the value controlled by the parent component
        onChange={this.handleChange} // Handle changes via parent handlers
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label || "Chip Input"} // Use props for label if passed
            placeholder={placeholder || "Add a chip"}
          />
        )}
      />
    );
  }
}

export default ChipInput;