import React from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { LinearProgress, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';

class LoadingItem extends React.Component {

    render() {
      return (
        <Card>
          <CardContent>
              <Grid container justifyContent='center'>
                <CircularProgress style={{marginTop:20}} size={100} />
                <Typography style={{marginTop:30,color:'#505050'}} variant='body2'>{this.props.info?this.props.info:""}</Typography>
              </Grid>
              {this.props.percentage>0 ?
                  <LinearProgress style={{marginBottom:10,marginTop:20}} variant="buffer" value={this.props.percentage} valueBuffer={this.props.percentage} />
                  :
                  <span/>
              }
          </CardContent>
        </Card>
      )
    }
}

export default LoadingItem;
