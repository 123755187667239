import React from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import {Button,Divider,Grid2, Box, Card, Paper, CardContent, CardActions, CardMedia} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as ETVConstants from '../ETVConstants';

class SelectBackground extends React.Component {

    state = {
		list:[],
      	current_bg: undefined,
		bg_color: "#000000",
    }

	componentDidUpdate(prevProps, prevState) {
		if(!this.state.isLoaded) {
			this.loadItems();
		}
		if(prevProps.device_id !== this.props.device_id) {
			this.loadItems();
		}
	}

    componentDidMount() {
      	this.loadItems();
    }

    loadItems() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=get_backgrounds&device_id="+this.props.device_id+"&user_id="+this.props.user_id;
		if(this.props.group_id>0) {
			url = ETVConstants.getServerBase()+"/device_mgmt?action=get_backgrounds&group_id="+this.props.group_id+"&user_id="+this.props.user_id;
		}
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
			var currentBG = result['current'];
			this.setState({list:result.list, isLoaded:true, current_bg:currentBG});
			if(currentBG.startsWith("#")) {
				this.setState({bg_color:currentBG});
			}else{
				this.setState({bg_color:"#000000"});
			}
          },
          (error) => {
            this.setState({
              current_bg: undefined,
            });
          }
        )
    }

	saveBGColor() {
		this.setBGImage(this.state.bg_color);
		this.closeBackgroundSelection();
	}

	setBGImage( bgImg) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_background&device_id="+this.props.device_id+"&background_image="+encodeURIComponent(bgImg);
		if(this.props.group_id>0) {
			url = ETVConstants.getServerBase()+"/device_mgmt?action=set_background&group_id="+this.props.group_id+"&background_image="+encodeURIComponent(bgImg);
		}
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
						this.setState({isLoaded:false});
			},
			(error) => {
			this.setState({
				current_bg: undefined,
			});
			}
		)
	}

	deleteBGImage( uri) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=delete_background&background_id="+uri;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({isLoaded:false});
				},
				(error) => {
					this.setState({
						current_bg: undefined,
					});
				}
			)
	}

	createBackground(jsonObj) {
		var url = jsonObj.public_url;
		var uri = jsonObj.uri;
		var hasBorder = (url===this.state.current_bg);
		var border = '1px solid lightgray';
		if(hasBorder) {
			border='1px solid red';
		}
		return (
			<Card sx={{width:240, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  height: '100%' }}>
				<CardMedia 
					component="img" 
					image={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeContentURLRelative(url):url} 
					sx={{
						width: '100%',
						height: 'auto',       // Automatically adjusts the height based on the aspect ratio
						objectFit: 'cover',   // Ensures the image covers the area while keeping aspect ratio
						}}
				/>
				<CardActions sx={{ mt: 'auto' }}>
					{!hasBorder?
						<span>
							<Button size='small' variant='contained' color='primary' onClick={()=>{this.setBGImage(url)}}>{ETVConstants.trans("set_background")}</Button>
							<IconButton size='small' variant='outlined' color="error" onClick={()=>{this.deleteBGImage(uri)}}><DeleteIcon/></IconButton>
						</span>
						:
						<Button size='small' variant='contained' color='error' startIcon={<DeleteIcon/>} onClick={()=>{this.setBGImage("")}}>{ETVConstants.trans("remove_background")}</Button>
					}
				</CardActions>
			</Card>
			)
	}

	onDrop = (files) => {
		//console.log("onDrop");
		var targetDir = "/"+this.props.user_id+"/backgrounds";
		for( var i=0; i<files.length; i++) {
			if(ETVConstants.ON_PREMISE_VERSION) {
				this.uploadFileToOnPremise( files[i], targetDir);
			}else{
				this.uploadFileToGCS( files[i], targetDir);
			}
		}
	}

	updateProgress( size, loaded) {
		//console.log("size="+size+" loaded="+loaded);
	}

	uploadFileToOnPremise( file, targetDir) {
		var filename = file.name;
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var formData = new FormData();
		formData.append('file', file);
		formData.append('filename', filename);
		formData.append('target_dir',targetDir);
		const config = {
			headers: {	'content-type':'multipart/form-data', 'user_id':reqUserID, 'access_token': accessToken },
			onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
		}
		var url = ETVConstants.getServerBase()+"/file_premise_upload";
		axios.post(url, formData, config)
				.then((response) => {
					this.setState({isLoaded:false});
				})
				.catch((error) => {
						console.error(error);
				});
	}

	uploadFileToGCS( file, targetDir) {
		var filename = file.name;
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
		//console.log("res="+JSON.stringify(result));
					var formData = new FormData();
					Object.entries(result.fields).map(([key,value])=>{
							return formData.append(key,value);
					})
					formData.append('file', file);
					const config = {
						headers: {	'content-type': 'multipart/form-data'}
					}
					axios.post(result.url, formData, config)
							.then((response) => {
				//console.log("give publicAccess");
								this.givePublicAccess(targetDir,filename);
								this.setState({isLoaded:false});
							})
							.catch((error) => {
									console.error(error);
							});
				},
				(error) => {
				}
			)
	}

	givePublicAccess(target_dir,filename) {
		var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
		ETVConstants.sendCMDToServer(url);
	}

	setBGFingerprint(deviceID, fp) {
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_bg_fingerprint&device_id="+deviceID+"&fingerprint="+fp;
		ETVConstants.sendCMDToServer(url);
	}

	closeBackgroundSelection() {
		this.props.closeF();
	}

    render() {
		//<div id='wrapper' style={{width:'100%',border:'',overflow:'hidden',marginTop:10}}>
		return(
			<span>
				<div>
						<Dropzone
								onDrop={this.onDrop}
								disabled={false}
								multiple={false}
								accept={{ 'image/jpeg': ['.jpg'] }} 
								minSize={1024}
								maxSize={5242880}
								style={{width:'100%',height:'100%'}}>

						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: "dropzone" })}>
									<input {...getInputProps()} />
									<p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
							</div>
						)}
						</Dropzone>
				</div>
				<div style={{marginTop:20}}>
					<Grid2 container spacing={2}>
						{ this.state.list.map( (item,key) => { return this.createBackground(item) })}
					</Grid2>
				</div>
				<Divider style={{marginTop:20}}/>
				<br/>
				<div><Typography variant="h6">{ETVConstants.trans("background_color")}</Typography></div>
				<div><input type="color" style={{marginTop:10}} value={this.state.bg_color} onChange={e => this.setState({bg_color:e.target.value} )} /></div>
				<div><Button size='small' variant='contained' color='primary' style={{float: 'left',marginTop:10}} onClick={()=>{this.saveBGColor()}}>{ETVConstants.trans("save_background_color")}</Button></div>
			</span>
		);
	}
}
export default SelectBackground
