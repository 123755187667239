import React from 'react';
import Typography from '@mui/material/Typography';

class InfoMessage2 extends React.Component {

    render() {
      return (
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography component={'span'} gutterBottom variant="h6" >
                      {this.props.title?this.props.title:"info"}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography component={'span'} gutterBottom variant="body2" >
                      {this.props.message}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
      )
    }
}

export default InfoMessage2;
