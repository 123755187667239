import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as ETVConstants from '../ETVConstants';
import { CheckCircle, Upload } from '@mui/icons-material';

class RSSCreator extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
      rss_title:"",
      rss_url:"",
      checking_rss:false,
      test_title_0:"",
      test_desc_0:"",
      test_title_1:"",
      test_desc_1:"",
      test_result:'',
      channel: "0",
      selected_screens: [],
    }

    selectedScreens(screenIDs) {
      //console.log("selected screens="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      //console.log("show window="+divID+" show="+show);
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    getFilename() {
      var m = new Date();
      var dateString = ""+ m.getUTCFullYear() +(m.getUTCMonth()+1) + m.getUTCDate() + m.getUTCHours() +  m.getUTCMinutes() + m.getUTCSeconds();
      return dateString+"_rss.html";
    }

    saveRSS() {
      this.showInfoMessage("Uploading ...");
      var url = ETVConstants.getServerBase()+"/template_servlet?action=create_and_save_template&user_id="+this.props.user_id;
      url += "&template_path=templates/rss/rss_template.html";
      url += "&filename="+this.getFilename();
      url += "&thumbnail="+encodeURIComponent("https://elevatortvfrontend.uc.r.appspot.com/thumbnails/thumb_rss.jpg");
      url += "&showing_time=20";
      url += "&param_0.name=@@rss_url@@&param_0.value="+encodeURIComponent(this.createRSSURL(this.state.rss_url));
      url += "&param_1.name=@@rss_title@@&param_1.value="+encodeURIComponent(this.state.rss_title);
      url += "&desc="+encodeURIComponent(this.state.rss_title);

      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        url += "&channel="+this.state.channel;
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&device_id="+this.state.selected_screens[i];
        }
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              rss_title:"",
              rss_url:"",
              checking_rss:false,
              test_title_0:"",
              test_desc_0:"",
              test_title_1:"",
              test_desc_1:"",
              test_result:'',
              channel: "0",
              selected_screens: [],
            });
            this.showInfoMessage(ETVConstants.trans("the_content_was_stored_in_your_library"));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
            this.setState({
              rss_title:"",
              rss_url:"",
              checking_rss:false,
              test_title_0:"",
              test_desc_0:"",
              test_title_1:"",
              test_desc_1:"",
              test_result:'',
              channel: "0",
              selected_screens: [],
            });
          }
        )
    }

    createRSSURL( url) {
      return ETVConstants.getServerBase()+"/proxy?url="+encodeURIComponent(url);
    }

    testRSS() {
      this.setState({checking_rss:true});
      var url = this.createRSSURL(this.state.rss_url);
      //console.log(url);
      fetch(url)
        .then(response => response.text())
        .then(
          (result) => {
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(result,"text/xml");
            var res = this.parseRSS(xmlDoc);
      			this.receiveData(res);
            this.setState({checking_rss:false});
          },
          (error) => {
            this.setState({checking_rss:false});
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    removeTags(content) {
	     return content.replace(/(<([^>]+)>)/gi, "");
    }

    parseRSS( xmlDoc) {
	     var elems = xmlDoc.getElementsByTagName("item");
    	 var result = [];
	     for(var i=0; i<5; i++) {
         try {
           var elem = elems[i];
		       var childNodes = elem.childNodes;
		       var o = new Object();
		       for(var k=0; childNodes && k<childNodes.length; k++) {
			          if(childNodes[k].nodeName==='title') {
				              o.title = this.removeTags(childNodes[k].childNodes[0].nodeValue);
			          }else if(childNodes[k].nodeName==='description') {
				              o.desc = this.removeTags(childNodes[k].childNodes[0].nodeValue);
			          }else if(childNodes[k].nodeName==='pubDate') {
				              var pDate = new Date(childNodes[k].childNodes[0].nodeValue).toLocaleString();
				              o.pubDate = pDate;
			          }else if(childNodes[k].nodeName==='link') {
				              o.link = childNodes[k].childNodes[0].nodeValue;
			          }
		      }
		      result.push(o);
         }
         catch(err) {
         }
	    }
	    return result;
    }

    receiveData( objects) {
      if(objects && objects.length>0 && objects[0].title && objects[0].title.length>0) {
        this.setState({test_result:'ok'});
        this.setState({test_title_0:objects[0].title, test_desc_0:objects[0].desc});
        this.setState({test_title_1:objects[1].title, test_desc_1:objects[1].desc});
      }else{
        this.setState({test_result:'failed'});
      }
    }

    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }

    render() {
      return (
        <div style={{width:700}}>
          <Card>
            <CardContent>
                <Typography variant="h5">
                    {ETVConstants.trans("rss_feeds")}
                </Typography>
                <div style={{marginTop:20}} />
                <Typography variant="body1">
                    {ETVConstants.trans("before_uploading_please_test_feeds")}
                </Typography>
                <Divider style={{marginTop:20}} />
                <TextField required
                    key='rss_title'
                    label={ETVConstants.trans("title")}
                    inputProps={{ maxLength: 50 }}
                    fullWidth={true}
                    disabled={this.state.checking_rss}
                    value={this.state.rss_title}
                    style={{marginTop:20}}
                    onChange={(event)=>{this.setState({rss_title:event.target.value})}} />
                <TextField required
                          key='rss_url'
                          label={ETVConstants.trans("rss_url")}
                          inputProps={{ maxLength: 200 }}
                          fullWidth={true}
                          disabled={this.state.checking_rss}
                          value={this.state.rss_url}
                          style={{marginTop:20}}
                          onChange={(event)=>{this.setState({rss_url:event.target.value})}} />
                <Button style={{marginTop:10}} disabled={this.state.checking_rss || !this.isValidHttpUrl(this.state.rss_url)} startIcon={<CheckCircle/>} variant="contained" color="primary" onClick={() => this.testRSS()}>{ETVConstants.trans("validate")}</Button>
                <Divider style={{marginTop:20}}/>
                      {this.state.test_result==='failed'?
                          <Typography variant="body1">
                              <div style={{background:'red',color:'white',margin:20,padding:10}}>
                                {ETVConstants.trans("sorry_failed_to_load_rss")}
                              </div>
                          </Typography>:
                       this.state.test_result==='ok'?
                          <div>
                            <Typography variant="body1">
                                <div style={{background:'green',color:'white',margin:20,padding:10}}>
                                  {ETVConstants.trans("success")}
                                </div>
                            </Typography>
                            <div>
                              <Typography variant="body1">
                                <div style={{border:'1px solid green',margin:20,padding:10}}>
                                  <div style={{background: 'rgb(230,230,230)'}}>{this.state.test_title_0}</div>
                                  <div>{this.state.test_desc_0}</div>
                                </div>
                                <div style={{border:'1px solid green',margin:20,padding:10}}>
                                  <div style={{background: 'rgb(230,230,230)'}}>{this.state.test_title_1}</div>
                                  <div>{this.state.test_desc_1}</div>
                                </div>
                              </Typography>
                            </div>
                          </div>:
                      <span/>}
                  <Divider style={{marginBottom:20}}/>
                  <Button variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
                  <br/><br/>
                  <FormControl>
                      <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                      <Select
                        label={ETVConstants.trans("channel")}
                        id="channel_id"
                        style={{ width: 300}}
                        value={this.state.channel}
                        onChange={(e)=>{this.setState({channel:e.target.value})}}>
                        <MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
                        <MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
                        <MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
                        <MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
                        <MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
                      </Select>
                    </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={this.state.test_result!=='ok'} startIcon={<Upload/>} color="primary" variant='contained' onClick={() => this.saveRSS()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
          </div>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default RSSCreator;
