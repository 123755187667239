import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import * as ETVConstants from '../ETVConstants';

class Whatsapp extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
			this.resultListener = this.resultListener.bind(this);
      this.resultListenerConfig = this.resultListenerConfig.bind(this);
      this.resultListenerContact = this.resultListenerContact.bind(this);
    }

    state = {
      selected_channel:0,
      default_playtime:10,
      default_keeptime_days:2,
      need_approval:true,
      show_image_overlay:false,
      device_ids:[],
      accept_movies:false,
      accept_texts:false,
      contacts:[],
      contactsLoaded:false,
      configLoaded:false,
      window:'',
      info_message:'',
      info_message_title:'',
      new_contact_name:'',
      new_contact_id:'',
      contact_id_error:false,
    }

    componentDidUpdate() {
      if(!this.state.contactsLoaded) {
        this.loadContacts();
      }else if(!this.state.configLoaded) {
        this.loadConfig();
      }
		}

    componentDidMount() {
      this.loadContacts();
      this.loadConfig();
    }

    loadConfig() {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/messaging?user_id="+this.props.user_id+"&action=get_config&message_type=whatsapp";
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("--------------------"+JSON.stringify(result));
            this.setState({
              accept_movies: result.config.accept_movies,
              accept_texts: result.config.accept_texts,
              selected_channel:result.config.channel,
              default_playtime:result.config.default_playtime,
              default_keeptime_days:result.config.default_keeptime_days,
              need_approval:result.config.need_approval,
              show_image_overlay:result.config.show_image_overlay,
              device_ids:result.config.device_ids?result.config.device_ids:[],
              configLoaded: true,
            });
          },
          (error) => {
            this.setState({
              config:{},
              configLoaded: false,
              error
            });
          }
        )
    }

    loadContacts() {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/messaging?user_id="+this.props.user_id+"&action=get_contacts&message_type=whatsapp";
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
            this.setState({
              contacts: result,
              contactsLoaded: true,
            });
          },
          (error) => {
            this.setState({
              contacts:[],
              contactsLoaded: false,
              error
            });
          }
        )
    }

    selectedScreens(screenIDs) {
      //console.log("selected deviceIDs="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({device_ids:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    deleteContact(contactID) {
      	var url = ETVConstants.getServerBase()+"/messaging?user_id="+this.props.user_id+"&action=delete_contact&message_type=whatsapp&contact_id="+encodeURIComponent(contactID);
        ETVConstants.sendCMDToServerListener(url,this.resultListener);
    }

    resultListener(val) {
			this.setState({contactsLoaded:false});
		}

    resultListenerConfig(res) {
      //console.log("res="+JSON.stringify(res));
      if(res.result) {
        this.showInfoMessage(ETVConstants.trans("config_updated"));
      }else{
        this.showInfoMessage("config updated failed. Reason="+res.reason);
      }
    }

    resultListenerContact(res) {
      //console.log("res="+JSON.stringify(res));
      if(res.result) {
        //this.showInfoMessage("contact added");
        this.setState({contactsLoaded:false,window:'',new_contact_id:'',new_contact_name:''});
      }else{
          this.showInfoMessage(ETVConstants.trans(res.reason));
      }
    }

    removePlusFromPhone( phoneNr) {
      if(phoneNr.startsWith("+")) {
        return phoneNr.substring(1);
      }
      return phoneNr;
    }

    saveNewContact() {
      if(!this.checkAddContact(this.state.new_contact_id,this.state.new_contact_name)) {
        return;
      }
      var userID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/messaging?user_id="+userID+"&action=insert_contact&message_type=whatsapp";
      url += "&contact_id="+encodeURIComponent(this.removePlusFromPhone(this.state.new_contact_id));
      url += "&contact_name="+encodeURIComponent(this.state.new_contact_name);
      ETVConstants.sendCMDToServerListener(url,this.resultListenerContact);
    }

    saveSettings() {
      var userID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/messaging?user_id="+userID+"&action=update_config";
      url += "&accept_movies="+this.state.accept_movies;
      url += "&accept_texts="+this.state.accept_texts;
      url += "&channel="+this.state.selected_channel;
      url += "&default_playtime="+this.state.default_playtime;
      url += "&default_keeptime_days="+this.state.default_keeptime_days;
      url += "&need_approval="+this.state.need_approval;
      url += "&show_image_overlay="+this.state.show_image_overlay;
      if(this.state.device_ids && this.state.device_ids.length>0) {
        for(var i=0; i<this.state.device_ids.length; i++) {
          url += "&device_id="+this.state.device_ids[i];
        }
      }
      ETVConstants.sendCMDToServerListener(url,this.resultListenerConfig);
    }

    showAddContact() {
      this.showWindow("add_contact_div",true);
    }

    checkAddContact( nr, name) {
      var isPhone = ETVConstants.isPhone(nr);
      var hasName = name.length>0;
      if(ETVConstants.isPhone(nr) && hasName) {
        this.setState({new_contact_id:nr,contact_id_error:false,contact_name_error:false});
        return true;
      }else{
        this.setState({contact_id_error:!isPhone,contact_name_error:!hasName});
        return false;
      }
    }

    render() {

      const tabletitle = "";

      const contactsData= [
          { title: ETVConstants.trans("contact"), field: 'contact_id' },
          { title: ETVConstants.trans("contact_name"), field: 'contact_name' },
          { title: ETVConstants.trans("delete"), field: 'select_layout', render: rowData => <IconButton size='small' color='error' onClick={()=>this.deleteContact(rowData.contact_id)}><DeleteIcon/></IconButton>},
      ];

      return (
        <div style={{width:800}}>
          <Card>
            <CardContent>
                <Typography variant="h5">{ETVConstants.trans("whatsapp_configuration")}</Typography>
                <Typography variant="body1" style={{marginTop:10}}>
                    {ETVConstants.trans("whatsapp_configuration_explanation")}
                </Typography>
                <Tooltip title={ETVConstants.trans("whatsapp_qr_code")}>
                  <a download="whatsapp_qr_code.png" href={ETVConstants.getServerBase()+"/q?c="+encodeURIComponent("https://wa.me/"+ETVConstants.WHATSAPP_BIZ_NR)}>
                    <img style={{marginTop:20,marginLeft:20}} src={"https://backend.elevator.tv/q?c="+encodeURIComponent("https://wa.me/"+ETVConstants.WHATSAPP_BIZ_NR)} width='200' height='200'/>
                  </a>
                </Tooltip>
                <div style={{marginTop:20,width:650}}>
                  <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                            <Typography component={'span'} variant="body1">
                                <Box fontWeight='bold' display='inline'>{ETVConstants.trans("select_screens")}</Box>
                            </Typography>
                            <Typography variant="body2">{ETVConstants.trans("whatsapp_select_screen_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Button color='primary' size='small' variant='contained' onClick={()=>{this.showWindow('select_screens_div',true)}}>{ETVConstants.trans("select_screens")} ({this.state.device_ids.length})</Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("channel")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("whatsapp_channel_help")}</Typography>
                        </TableCell>
                        <TableCell>
                            <FormControl>
                               <InputLabel component="legend">{ETVConstants.trans("channel")}</InputLabel>
                               <Select
                                   label={ETVConstants.trans("channel")}
                                   id="chosen_channel"
                                   fullWidth
                                   style={{ width: 100}}
                                   value={this.state.selected_channel}
                                   onChange={(e)=>{this.setState({selected_channel:e.target.value})}}>
                                   <MenuItem value={0}>Channel 0</MenuItem>
                                   <MenuItem value={1}>Channel 1</MenuItem>
                                   <MenuItem value={2}>Channel 2</MenuItem>
                                   <MenuItem value={3}>Channel 3</MenuItem>
                                   <MenuItem value={4}>Channel 4</MenuItem>
                               </Select>
                             </FormControl>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("default_keeptime_days")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("default_keeptime_days_help")}</Typography>
                        </TableCell>
                        <TableCell>
                        <FormControl>
                           <InputLabel component="legend">{ETVConstants.trans("default_keeptime_days")}</InputLabel>
                           <Select
                               label={ETVConstants.trans("default_keeptime_days")}
                               id="default_keep_time_days"
                               style={{ width: 200}}
                               value={this.state.default_keeptime_days}
                               onChange={(e)=>{this.setState({default_keeptime_days:e.target.value})}}>
                               <MenuItem value={1}>{ETVConstants.trans("1_day")}</MenuItem>
                               <MenuItem value={2}>{ETVConstants.trans("2_days")}</MenuItem>
                               <MenuItem value={3}>{ETVConstants.trans("3_days")}</MenuItem>
                               <MenuItem value={4}>{ETVConstants.trans("4_days")}</MenuItem>
                               <MenuItem value={5}>{ETVConstants.trans("5_days")}</MenuItem>
                               <MenuItem value={6}>{ETVConstants.trans("6_days")}</MenuItem>
                               <MenuItem value={7}>{ETVConstants.trans("1_week")}</MenuItem>
                               <MenuItem value={14}>{ETVConstants.trans("2_weeks")}</MenuItem>
                               <MenuItem value={31}>{ETVConstants.trans("1_month")}</MenuItem>
                               <MenuItem value={10000}>{ETVConstants.trans("forever")}</MenuItem>
                           </Select>
                         </FormControl>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("default_playtime")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("default_playtime_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                             <InputLabel component="legend">{ETVConstants.trans("default_playtime")}</InputLabel>
                             <Select
                                 label={ETVConstants.trans("default_playtime")}
                                 id="default_keep_time_days"
                                 style={{ width: 200}}
                                 value={this.state.default_playtime}
                                 onChange={(e)=>{this.setState({default_playtime:e.target.value})}}>
                                 <MenuItem value={5}>{ETVConstants.trans("5_seconds")}</MenuItem>
                                 <MenuItem value={10}>{ETVConstants.trans("10_seconds")}</MenuItem>
                                 <MenuItem value={15}>{ETVConstants.trans("15_seconds")}</MenuItem>
                                 <MenuItem value={20}>{ETVConstants.trans("20_seconds")}</MenuItem>
                                 <MenuItem value={30}>{ETVConstants.trans("30_seconds")}</MenuItem>
                                 <MenuItem value={60}>{ETVConstants.trans("60_seconds")}</MenuItem>
                             </Select>
                           </FormControl>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("need_approval")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("need_approval_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Switch checked={this.state.need_approval} color='primary' onChange={(e)=>{this.setState({need_approval:!this.state.need_approval})}} />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("show_overlay")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("show_overlay_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Switch checked={this.state.show_image_overlay} color='primary' onChange={(e)=>{this.setState({show_image_overlay:!this.state.show_image_overlay})}} />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("accept_movies")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("accept_movies_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Switch checked={this.state.accept_movies} color='primary' onChange={(e)=>{this.setState({accept_movies:!this.state.accept_movies})}} />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography component={'span'} variant="body1">
                              <Box fontWeight='bold' display='inline'>{ETVConstants.trans("accept_texts")}</Box>
                          </Typography>
                          <Typography variant="body2">{ETVConstants.trans("accept_texts_help")}</Typography>
                        </TableCell>
                        <TableCell>
                          <Switch checked={this.state.accept_texts} color='primary' onChange={(e)=>{this.setState({accept_texts:!this.state.accept_texts})}} />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <Button color='primary' startIcon={<SaveIcon/>} variant='contained' onClick={()=>{this.saveSettings()}}>{ETVConstants.trans("save_settings")}</Button>
                        </TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                  </TableContainer>
                </div>

                <Divider style={{marginTop:20}}/>
                <Typography style={{marginTop:20}} variant="h5">{ETVConstants.trans("accepted_whatsapp_senders")}</Typography>
                <Typography style={{marginTop:10}} variant="body1">{ETVConstants.trans("accepted_whatsapp_senders_help")}</Typography>
                <BasicTable2 style={{marginTop:20}} data={this.state.contacts} columns={contactsData} title={tabletitle} pageSize={5} allow_search={true} />
                <Button color='primary' startIcon={<PersonAddIcon/>} style={{marginTop:10}} variant='contained' onClick={()=>{this.showAddContact()}}>{ETVConstants.trans("add_contact")}</Button>
            </CardContent>
          </Card>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.device_ids?this.state.device_ids:[]}/>
          </div>

          <Dialog
            open={this.state.window==='add_contact_div'}
            maxWidth='sm'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="add_contact_div_title">{ETVConstants.trans("add_accepted_sender")}</DialogTitle>
            <DialogContent>
                <Table>
                  <TableRow>
                    <TableCell width='50%'>
                      <Typography variant="body1"><Box fontWeight='bold' display='inline'>{ETVConstants.trans("whatsapp_number")}</Box></Typography>
                      <Typography variant="body1">{ETVConstants.trans("whatsapp_number_help")}</Typography>
                    </TableCell>
                    <TableCell>
                      <TextField required fullWidth={true} error={this.state.contact_id_error} helperText={this.state.contact_id_error?ETVConstants.trans("whatsapp_contact_id_helper_phone"):""} value={this.state.new_contact_id} onChange={(e)=>{this.setState({new_contact_id:e.target.value,contact_id_error:false})}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width='50%'>
                      <Typography variant="body1"><Box fontWeight='bold' display='inline'>{ETVConstants.trans("whatsapp_display_name")}</Box></Typography>
                      <Typography variant="body1">{ETVConstants.trans("whatsapp_display_name_help")}</Typography>
                    </TableCell>
                    <TableCell>
                      <TextField required fullWidth={true} error={this.state.contact_name_error} helperText={this.state.contact_name_error?ETVConstants.trans("whatsapp_contact_name_helper"):""} value={this.state.new_contact_name} onChange={(e)=>this.setState({new_contact_name:e.target.value,contact_name_error:false})} />
                    </TableCell>
                  </TableRow>

                </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
              <Button onClick={()=>{this.saveNewContact()}} startIcon={<PersonAddIcon/>} variant='contained' color="primary">{ETVConstants.trans("add")}</Button>
            </DialogActions>
          </Dialog>

        </div>
      )
    }
}

export default Whatsapp;
