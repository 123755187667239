import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import * as ETVConstants from '../ETVConstants';

class WeatherInfo extends React.Component {

    constructor(props) {
      super(props);
      this.loadData = this.loadData.bind(this);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
      cities:[],
      selected_city: undefined,
      selected_city_id: undefined,
      content_format: '4_3',
      info_message: undefined,
      lang: 'en',
      celcius: true,
      channel: "0",
      selected_screens: [],
      window:'',
      show_overlay: false,
      override_city_name:'',
      show_date:true,
      use_bg:true,
    }

    resetState() {
      this.setState({
        cities:[],
        selected_city: undefined,
        selected_city_id: undefined,
        content_format: '4_3',
        info_message: undefined,
        lang: 'en',
        celcius: true,
        channel: "0",
        selected_screens: [],
        window:'',
        show_overlay: false,
        override_city_name:'',
        show_date:true,
        use_bg:true,
      })
    }

    selectedScreens(screenIDs) {
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    loadData(likeCity) {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/weather_service?action=query&city_like="+encodeURIComponent(likeCity);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              cities:result.cities,
            });
          },
          (error) => {
            this.resetState();
          }
        )
    }

    handleAutocomplete = (event, newValue) => {
      if (newValue != null) {
        this.setState({selected_city_id:newValue.city_id, selected_city:newValue.cityname});
      }
    }

    saveWeatherInfo() {
      var desc = "weather forecast for "+this.state.selected_city;
      this.showInfoMessage(ETVConstants.trans("uploading..."));
      var url = ETVConstants.getServerBase()+"/weather_service?action=register_city&lang="+this.state.lang+"&user_id="+this.props.user_id;
      url += "&template_path=templates/weather/weather_template_"+this.state.content_format+".html";
      url += "&param_0.name=@@weather_id@@&param_0.value="+this.state.selected_city_id;
      url += "&param_1.name=@@city_name@@&param_1.value="+this.state.selected_city;
      url += "&param_2.name=@@lang@@&param_2.value="+this.state.lang;
      url += "&param_3.name=@@celcius@@&param_3.value="+this.state.celcius;
      url += "&param_4.name=@@use_bg@@&param_4.value="+this.state.use_bg;
      url += "&param_5.name=@@show_date@@&param_5.value="+this.state.show_date;
      url += "&param_6.name=@@override_city_name@@&param_6.value="+this.state.override_city_name;
      url += "&desc="+encodeURIComponent(desc);

      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        url += "&channel="+this.state.channel;
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&device_id="+this.state.selected_screens[i];
        }
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(result);
            this.resetState();
            this.showInfoMessage(ETVConstants.trans("the_content_was_stored_in_your_library"));
          },
          (error) => {
            this.resetState();
          }
        )
    }

    render() {
      return (
        <div style={{width:'700px'}}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h5">
                  {ETVConstants.trans("add_weather_information")}
              </Typography>
              <Autocomplete
                  id="cities"
                  options={this.state.cities}
                  getOptionLabel={(option) => option.cityname + " ("+option.country+")"}
                  style={{ width: 300, marginTop:20 }}
                  onChange={this.handleAutocomplete}
                  onInputChange={(e)=>this.loadData(e.target.value)}
                  renderInput={(params) => <TextField {...params} label={ETVConstants.trans("search_cities")} />}
                />
              <TextField style={{marginTop:10}} key='show_name' label={ETVConstants.trans("alternative_name_to_be_displayed")} inputProps={{ maxLength: 30 }} fullWidth={true} value={this.state.override_city_name} onChange={(e)=>{this.setState({override_city_name:e.target.value})}} />
              
              <Divider style={{marginTop:20,marginBottom:20}}/>

              <FormControl style={{marginTop:10}}>
                <InputLabel>{ETVConstants.trans("content_format")}</InputLabel>
                <Select
                  label={ETVConstants.trans("content_format")}
                  id="content_format"
                  style={{ width: 300}}
                  value={this.state.content_format}
                  onChange={(e)=>{this.setState({content_format:e.target.value})}}>
                    <MenuItem value='16_9'>{ETVConstants.trans("16_9")}</MenuItem>
                    <MenuItem value='4_3'>{ETVConstants.trans("4_3")}</MenuItem>
                    <MenuItem value='1_1'>{ETVConstants.trans("square")}</MenuItem>
                    <MenuItem value='banner'>{ETVConstants.trans("banner")}</MenuItem>
                </Select>
              </FormControl>
              {
                this.state.content_format === '16_9'? <img src='/images/weather/weather_16_9.jpg' width='300' style={{marginLeft:20, marginTop:10}}/>:
                this.state.content_format === '4_3'? <img src='/images/weather/weather_4_3.jpg' width='300' style={{marginLeft:20, marginTop:10}}/>:
                this.state.content_format === '1_1'? <img src='/images/weather/weather_square.jpg' width='200' style={{marginLeft:20, marginTop:10}}/>:
                this.state.content_format === 'banner'? <img src='/images/weather/weather_banner.jpg' width='600' style={{marginLeft:20, marginTop:10}}/>:
                <span/>
              }

              <Divider style={{marginTop:20,marginBottom:20}}/>

              <FormControl style={{marginTop:10,marginLeft:10}}>
                <InputLabel>{ETVConstants.trans("language")}</InputLabel>
                <Select
                    label={ETVConstants.trans("lang_format")}
                    id="lang_format"
                    style={{ width: 150}}
                    value={this.state.lang}
                    onChange={(e)=>{this.setState({lang:e.target.value})}}>
                        <MenuItem value='en'>{ETVConstants.trans("english")}</MenuItem>
                        <MenuItem value='de'>{ETVConstants.trans("german")}</MenuItem>
                        <MenuItem value='ja'>{ETVConstants.trans("japanese")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{marginTop:10,marginLeft:10}}>
                  <InputLabel>{ETVConstants.trans("temperature_units")}</InputLabel>
                  <Select
                    label={ETVConstants.trans("temperature_units")}
                    id="temp_units"
                    style={{ width: 150}}
                    value={this.state.celcius}
                    onChange={(e)=>{this.setState({celcius:e.target.value})}}>
                      <MenuItem value={true}>{ETVConstants.trans("celcius")}</MenuItem>
                      <MenuItem value={false}>{ETVConstants.trans("fahrenheit")}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl style={{marginTop:10, marginLeft:10}}>
                  <InputLabel>{ETVConstants.trans("use_background")}</InputLabel>
                  <Select
                    label={ETVConstants.trans("use_background")}
                    id="bg_id"
                    style={{ width: 150}}
                    value={this.state.use_bg}
                    onChange={(e)=>{this.setState({use_bg:e.target.value})}}>
                      <MenuItem value={true}>{ETVConstants.trans("yes")}</MenuItem>
                      <MenuItem value={false}>{ETVConstants.trans("no")}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl style={{marginTop:10, marginLeft:10}} >
                    <InputLabel>{ETVConstants.trans("show_date")}</InputLabel>
                    <Select
                      label={ETVConstants.trans("show_date")}
                      id="show_date"
                      style={{ width: 150}}
                      value={this.state.show_date}
                      onChange={(e)=>{this.setState({show_date:e.target.value})}}>
                      <MenuItem value={true}>{ETVConstants.trans("show_date")}</MenuItem>
                      <MenuItem value={false}>{ETVConstants.trans("no_date")}</MenuItem>
                    </Select>
                </FormControl>

                <Divider style={{marginTop:20, marginBottom:20}}/>

                <Button variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
                <br/>
                <FormControl style={{marginTop:20}}>
                <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                <Select
                  label={ETVConstants.trans("channel")}
                  id="channel_id"
                  style={{width:200}}
                  size="small"
                  value={this.state.channel}
                  onChange={(e)=>{this.setState({channel:e.target.value})}}>
                    <MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
                    <MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
                    <MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
                    <MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
                    <MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
                </Select>
              </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={!this.state.selected_city_id || !this.state.content_format} startIcon={<UploadFileIcon />}  variant='contained' color="primary" size="medium" onClick={() => this.saveWeatherInfo()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
          </div>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
                <Typography variant="body1">{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default WeatherInfo;
