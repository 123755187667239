import React from 'react';
import {Button,Divider,Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker,TimePicker } from '@mui/x-date-pickers';
import * as ETVConstants from '../ETVConstants';

class SecurityMgmt extends React.Component {

	constructor(props) {
      	super(props);
		this.getCameraViewReplyStatus = this.getCameraViewReplyStatus.bind(this);
    }

    state = {
		items:[],
		isLoaded: false,
		cameraview_url: '',
		show_overlay: false,
		window:'',
		msg_count:1,
		info_title:'',
		info_message:'',
		message_id:'',
		images:[],
		selected_date:new Date(),
		selected_time:'',
		selected_device_id:'',
		loading_images:false,
		no_images_found:false,
		selected_image_url:undefined,
		cur_page:0,
		total_pages:0,
		images_per_page:20,
    }

	componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
	}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
	    fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              items: result.list,
              isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

	getLastConnectSymbol(time, startup) {
		var d = new Date(time.replace(/-/g, "/"));
		var diffMins = this.getLastConnectMins(time);
		var msg = d.toLocaleString();
		if(startup && startup.trim().length>0) {
			var startupDt = new Date(startup);
			msg = Math.round(diffMins)+" min ago (login: "+startupDt.toLocaleString()+")";
		}

		if(diffMins<15) {
				return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'lightgreen' }}  /></Tooltip>
		}else if(diffMins<120) {
				return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'yellow' }}  /></Tooltip>
		}else{
				return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'red' }}  /></Tooltip>
		}
	}

	getLastConnectMins( time) {
		// careful on IOS! --> use ".replace(..)"
		var d = new Date(time.replace(/-/g, "/"));
		var dNow = Date.now();
		var diffMins = (dNow - d)/60000;
		return diffMins;
	}

	showInfoMessage(title,msg) {
		this.showWindow('message_div',true);
		this.setState({info_title:title, info_message:msg});
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}

	searchImagesView( deviceID) {
		this.showWindow("images_div",true);
		this.setState({selected_device_id:deviceID});
	}

	// 00:00-12:00
	getFromTime( timeRange) {
		return timeRange.substring(0,timeRange.indexOf("-"));
	}

	getToTime( timeRange) {
		return timeRange.substring(timeRange.lastIndexOf("-")+1,timeRange.length);
	}

	viewImages(deviceID) {
		this.setState({loading_images:true,images:[]});
		var url = ETVConstants.getServerBase()+"/cam_service?action=list_files&thumbs=true";
		url += "&date="+this.printDate(this.state.selected_date);
		url += "&from_time="+this.getFromTime(this.state.selected_time);
		url += "&to_time="+this.getToTime(this.state.selected_time);
		url += "&dir="+encodeURIComponent(deviceID+"/motion_pics");
		//console.log(url);
		fetch(url)
		.then(res => res.json())
		.then(
			(result) => {
					//console.log('------------->'+JSON.stringify(result));
					var imageList = result.list;
					var totalPages = Math.floor(imageList.length / this.state.images_per_page);
					if(imageList.length % this.state.images_per_page>0) {
						totalPages += 1;
					}
					this.setState({images:imageList,loading_images:false,no_images_found:result.list.length===0,cur_page:0,total_pages:totalPages});
					this.showWindow("images_div",true);
			},
			(error) => {
				//console.log('xxx error happend.............'+error);
				this.setState({loading_images:false});
				this.showInfoMessage('Device','Error: '+error.message);
			}
		)
	}

	liveImage(deviceID) {
		this.showInfoMessage('Camera Snapshot',"sending screenshot request...");
		const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'cameraview' })
		};
		fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
			(result) => {
					//console.log('------------->'+JSON.stringify(result));
					if(result['result']) {
						//this.showInfoMessage('Device','request sent to device');
						this.setState({message_id:result.data.message_id, msg_count:0});
						//console.log("sent message reply="+JSON.stringify(result));
						setTimeout(this.getCameraViewReplyStatus, 1000);
					}else{
						this.showInfoMessage('Device','failed: '+result.reason);
					}
			},
			(error) => {
				//console.log('xxx error happend.............'+error);
				this.showInfoMessage('Device','Error: '+error.message);
			}
		)
	}

	getCameraViewReplyStatus() {
		//console.log("reply status.....");
		var msgCnt = this.state.msg_count;
		//console.log("msg_count="+this.state.msg_count);
		if(msgCnt>10) {
			if(msgCnt===20) {
				return;
			}
			this.showInfoMessage('Device',"Error: could not reach device");
			return;
		}
		this.showInfoMessage('Device',"waiting for reply ("+(10-msgCnt)+")");
		this.setState({msg_count:msgCnt+1})
		var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
		//console.log("url="+url);
		fetch(url)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.hasOwnProperty("reply_message")) {
					var replyMsg = result["reply_message"];
					var url = replyMsg.media_url;
					if(url) {
						this.setState({cameraview_url:url});
						this.showWindow('camera_view_div',true);
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getCameraViewReplyStatus, 1000);
					}
				}else{
					//console.log("<<<<<<<<<<<<<<< NO reply message!!!!!!!!!!!!!!!!!!!!!! ");
					this.setState({msg_count:msgCnt+1});
					setTimeout(this.getCameraViewReplyStatus, 1000);
				}
				// retrieve info and if no message wait 1000 ms and request again
				//setTimeout(this.getReplyStatus,1000);
			},
			(error) => {
				//console.log("error msg="+error.message);
			}
		)
	}

	createUrl(imageURI) {
		var url = ETVConstants.getServerBase()+"/cloud_storage/"+this.state.selected_device_id+"/motion_pics/"+imageURI;
		//console.log(url);
		return url;
	}

	printDate(d) {
		return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
	}

	setChosenDate = (date) => {
		this.setState({images:[],selected_date:date});
	};

	loadImage( img) {
		var imgNoThumb = img.substring(img.indexOf('thumb_')+'thumb_'.length);
		var url = this.createUrl(imgNoThumb);
		this.setState({selected_image_url:url});
	}

	getDateTimeFromImage(image) {
			if(!image) return "";
			var temp = "";
			if(image.indexOf("thumb_")>=0) {
				temp = image.substring(image.indexOf("thumb_")+"thumb_".length,image.length);
			}else{
				temp = image.substring(image.lastIndexOf('/')+1);
			}
			temp = temp.substring(0,temp.lastIndexOf("."));
			var yyyy = temp.substring(0,4);
			var mm = temp.substring(4,6);
			var dd = temp.substring(6,8);
			var hh = temp.substring(9,11);
			var min = temp.substring(11,13);
			var ss = temp.substring(13,15);
			return yyyy+"-"+mm+"-"+dd+" "+hh+":"+min+":"+ss;
	}

	createButtons() {
			//console.log(this.state.total_pages+" curPage="+this.state.cur_page);
			var curPage = this.state.cur_page;
			// 6 pages --> page 0..5
			var nextPage = Math.min(this.state.total_pages-1,this.state.cur_page+1);
			var prevPage = Math.max(0,this.state.cur_page-1);
			//console.log("nextPage="+nextPage+" prevPage="+prevPage);
			return (
				<div>
					<Divider style={{marginTop:20}}/>
					<Button size='large' style={{margin:10}} disabled={curPage===0} onClick={()=>{this.setState({cur_page:prevPage})}} variant='contained' color="primary">prev</Button>
					<Button size='large' style={{margin:10}} disabled={(curPage+1)===this.state.total_pages} onClick={()=>{this.setState({cur_page:nextPage})}} variant='contained' color="primary">next</Button>
				</div>
			);
	}

    render() {
      const columnsData= [
			{ title: 'Last connect', field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {this.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime)} </div>) },
			{ title: 'Device Name', field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
			{ title: 'Location', field: 'location_name'},
			{ title: 'Address', field: 'address'},
			{ title: 'Zip', field: 'zip'},
			{ title: 'City', field: 'city'},
			{ title: 'Live Image', field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.liveImage(rowData.id)}><VideocamIcon/></IconButton>},
			{ title: 'View Images', field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.searchImagesView(rowData.id)}><ImageSearchIcon/></IconButton>},
        ];

      	const tabletitle = "Cameras";
		var startIndex = this.state.images_per_page * this.state.cur_page;
		var endIndex = startIndex + this.state.images_per_page;
		var imagesToShow = this.state.images.slice(startIndex,endIndex);
		//console.log("firstImage="+imagesToShow[0]+" startIndex="+startIndex+" curPage="+this.state.cur_page+" totalImages="+this.state.images.length+" totalPages="+this.state.total_pages);
      	return(
			<div>
	        <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />

			<Dialog
				open={this.state.window==='camera_view_div'}
				onClose={()=>{this.setState({window:'',})}}
				fullWidth={true}
				maxWidth='sm'
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Live Capture</DialogTitle>
				<DialogContent>
							{this.state.cameraview_url?<Box component="img" key={Math.random()} src={this.state.cameraview_url} aspectRatio={4/3} style={{width:"500px", maxHeight: "375px"}} />:<span/>}
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.setState({window:'',})}} color="primary">
					Close
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='message_div'}
				onClose={()=>{this.setState({window:'',})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					<Typography variant='body1' component="span">{this.state.info_message}</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.setState({msg_count:20,window:'',})}} color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.selected_image_url!==undefined && this.state.selected_image_url.length>0}
				maxWidth='lg'
				fullWidth
				onClose={()=>{this.setState({selected_image_url:undefined,})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{this.getDateTimeFromImage(this.state.selected_image_url)}</DialogTitle>
				<DialogContent>
					<Box component="img" src={this.state.selected_image_url?this.state.selected_image_url:""} aspectRatio={4/3} style={{width:"640px", maxHeight: "480px"}} />
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.setState({selected_image_url:undefined,})}} color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='images_div'}
				maxWidth='xl'
				fullWidth={true}
				onClose={()=>{this.setState({window:'',})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Camera Images</DialogTitle>
				<DialogContent>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							id="whichDate"
							label="search date"
							format='yyyy-MM-dd'
							minDate='2021-2-21'
							maxDate={this.printDate(new Date())}
							value={this.printDate(this.state.selected_date)}
							onChange={this.setChosenDate}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}} />
					</LocalizationProvider>
						<FormControl style={{ marginLeft:20, width: 200}}>
								<InputLabel>Time span</InputLabel>
								<Select
									labelId='timespan'
									id='timespan'
									value={this.state.selected_time}
									onChange={(e)=>{this.setState({images:[],selected_time:e.target.value,no_images_found:false})}}>
									<MenuItem value='00:00-00:30'>00:00-00:30</MenuItem>
									<MenuItem value='00:30-01:00'>00:30-01:00</MenuItem>
									<MenuItem value='01:00-01:30'>01:00-01:30</MenuItem>
									<MenuItem value='01:30-02:00'>01:30-02:00</MenuItem>
									<MenuItem value='02:00-02:30'>02:00-02:30</MenuItem>
									<MenuItem value='02:30-03:00'>02:30-03:00</MenuItem>
									<MenuItem value='03:00-03:30'>03:00-03:30</MenuItem>
									<MenuItem value='03:30-04:00'>03:30-04:00</MenuItem>
									<MenuItem value='04:00-04:30'>04:00-04:30</MenuItem>
									<MenuItem value='04:30-05:00'>04:30-05:00</MenuItem>
									<MenuItem value='05:00-05:30'>05:00-05:30</MenuItem>
									<MenuItem value='05:30-06:00'>05:30-06:00</MenuItem>
									<MenuItem value='06:00-06:30'>06:00-06:30</MenuItem>
									<MenuItem value='06:30-07:00'>06:30-07:00</MenuItem>
									<MenuItem value='07:00-07:30'>07:00-07:30</MenuItem>
									<MenuItem value='07:30-08:00'>07:30-08:00</MenuItem>
									<MenuItem value='08:00-08:30'>08:00-08:30</MenuItem>
									<MenuItem value='08:30-09:00'>08:30-09:00</MenuItem>
									<MenuItem value='09:00-09:30'>09:00-09:30</MenuItem>
									<MenuItem value='09:30-10:00'>09:30-10:00</MenuItem>
									<MenuItem value='10:00-10:30'>10:00-10:30</MenuItem>
									<MenuItem value='10:30-11:00'>10:30-11:00</MenuItem>
									<MenuItem value='11:00-11:30'>11:00-11:30</MenuItem>
									<MenuItem value='11:30-12:00'>11:30-12:00</MenuItem>
									<MenuItem value='12:00-12:30'>12:00-12:30</MenuItem>
									<MenuItem value='12:30-13:00'>12:30-13:00</MenuItem>
									<MenuItem value='13:00-13:30'>13:00-13:30</MenuItem>
									<MenuItem value='13:30-14:00'>13:30-14:00</MenuItem>
									<MenuItem value='14:00-14:30'>14:00-14:30</MenuItem>
									<MenuItem value='14:30-15:00'>14:30-15:00</MenuItem>
									<MenuItem value='15:00-15:30'>15:00-15:30</MenuItem>
									<MenuItem value='15:30-16:00'>15:30-16:00</MenuItem>
									<MenuItem value='16:00-16:30'>16:00-16:30</MenuItem>
									<MenuItem value='16:30-17:00'>16:30-17:00</MenuItem>
									<MenuItem value='17:00-17:30'>17:00-17:30</MenuItem>
									<MenuItem value='17:30-18:00'>17:30-18:00</MenuItem>
									<MenuItem value='18:00-18:30'>18:00-18:30</MenuItem>
									<MenuItem value='18:30-19:00'>18:30-19:00</MenuItem>
									<MenuItem value='19:00-19:30'>19:00-19:30</MenuItem>
									<MenuItem value='19:30-20:00'>19:30-20:00</MenuItem>
									<MenuItem value='20:00-20:30'>20:00-20:30</MenuItem>
									<MenuItem value='20:30-21:00'>20:30-21:00</MenuItem>
									<MenuItem value='21:00-21:30'>21:00-21:30</MenuItem>
									<MenuItem value='21:30-22:00'>21:30-22:00</MenuItem>
									<MenuItem value='22:00-22:30'>22:00-22:30</MenuItem>
									<MenuItem value='22:30-23:00'>22:30-23:00</MenuItem>
									<MenuItem value='23:00-23:30'>23:00-23:30</MenuItem>
									<MenuItem value='23:30-24:00'>23:30-24:00</MenuItem>
								</Select>
								</FormControl>
								<Button style={{marginLeft:20, marginTop:10}} disabled={this.state.selected_time.length<=0} onClick={()=>{this.viewImages(this.state.selected_device_id)}} variant='contained' color="primary">load images</Button>
								<Divider style={{marginTop:20}}/>
							<div>
									{this.state.loading_images? <div style={{margin:'auto',width:300,height:300}}><CircularProgress size={100}/></div>:<span/>}
							</div>
							<div>
									{ imagesToShow.map( (image) => {
											return (
														<div key={Math.random()} style={{display: 'inline-block', width: 200, height:150, margin: 2}}>
															<Tooltip title={this.getDateTimeFromImage(image)} arrow><Button onClick={()=>{this.loadImage(image)}}><Box component="img" src={this.createUrl(image)} aspectRatio={4/3} style={{height: "auto", width:"200px", maxHeight: "150px"}} /></Button></Tooltip>
														</div>
											)
										})}
										{this.state.images.length>0?this.createButtons():<span/>}
										{this.state.no_images_found?
											<Typography variant='h6'>
											No images found for the selected date {this.printDate(this.state.selected_date)} and time {this.state.selected_time}
											</Typography>
											:
											<span/>
										}
							</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.setState({window:'',selected_device_id:undefined,images:[],selected_time:'00:00-00:30'})}} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
		   	</div>
			);
	}
}

export default SecurityMgmt