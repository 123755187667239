import React, { Component } from "react";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

class LocationMap extends React.Component {

  state = {
      initial_lat:undefined,
      initial_lgt:undefined,
  };

  componentDidMount() {
      var lat = this.props.lat;
      if(lat==='0' || lat==0) {
        lat = 1.283963993518128;
      }
      var lgt = this.props.lgt;
      if(lgt==='0' || lgt==0) {
        lgt = 103.85129636907892;
      }
      //console.log("iniital GPS="+lat+" this.props.lat="+this.props.lat);
      this.setState({initial_lat:lat, initial_lgt:lgt});
  }

  componentDidUpdate( prevProps, prevState) {
      if(this.props.lat != prevProps.lat || this.props.lgt != prevProps.lgt) {
        var lat = this.props.lat;
        if(lat==='0' || lat==0) {
          lat = 1.283963993518128;
        }
        var lgt = this.props.lgt;
        if(lgt==='0' || lgt==0) {
          lgt = 103.85129636907892;
        }
        this.setState({initial_lat:lat, initial_lgt:lgt});
      }
  }

  onMapClicked = (ev) => {
    //console.log("nested==>"+ev.someNestedProperty); 
    //this.debugEvent(ev);
    var detail = ev.detail;
    var latLng = detail.latLng;
    //this.debugEvent(latLng);
    if (latLng) {
      const latitude = latLng.lat;
      const longitude = latLng.lng;
      //console.log("Clicked location: ", { latitude, longitude });
      this.props.setLocationGPS(latitude,longitude);
    } else {
      //console.log("Event object is null");
    }
  };

  debugEvent( event) {
      console.log("Event object:", event);         // Log the full event object to inspect its structure
      console.log("Available keys:", Object.keys(event)); // Log top-level keys
      if (event) {
        for (let key in event) {
          if (event.hasOwnProperty(key)) {
            console.log("Key:", key, "Value:", event[key]); // Log each key-value pair in the event
          }
        }
      }
  }

  render() {
    if(!this.state.initial_lat) return <span/>
    return (
      <APIProvider apiKey={'AIzaSyDY87mGfUn304KvYQdwSFPdXG010XiwXNM'} onLoad={() => console.log('Maps API has loaded.')}>
        <Map
          onClick={this.onMapClicked}
          style={{width: '100vw', height: '100vh'}}
          defaultZoom={12}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          defaultCenter={{ lat: this.state.initial_lat, lng: this.state.initial_lgt}}
        >
          <Marker
              title={'markertitle'}
              name={'markername'}
              position={{lat: this.state.initial_lat, lng: this.state.initial_lgt}}>
          </Marker>
        </Map>
      </APIProvider>
    );
  }
}

export default LocationMap