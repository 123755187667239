import React from 'react';
import {Button} from '@mui/material';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import * as ETVConstants from '../ETVConstants';

class SelectAvailableScreensNew extends React.Component {

	  constructor(props) {
      super(props);
    }

    state = {
      items:[],
			groups:[],
      selected: [],
      isLoaded: false,
    }

		componentDidUpdate(prevProps, prevState) {
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
      if(!this.props.user_id) {
        return;
      }
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
						var groups = ETVConstants.filterOutEmptyGroups(result.groups);
						//var sorted = this.filterOutScreensInGroups(result.list,groups);
						var sorted = this.filterExcludedScreens(result.list);
						sorted.sort(ETVConstants.compareDevice);
						this.setState({
							items: sorted,
							isLoaded: true,
							groups: groups,
							selected: this.props.selected?this.props.selected:[],
						});
          },
          (error) => {
            this.setState({
							groups:[],
              items:[],
              isLoaded: false,
							selected: this.props.selected?this.props.selected:[],
              error
            });
          }
        )
    }

    filterExcludedScreens(list) {
      if(!this.props.excluded_screen_ids) return list;
      var excl = this.props.excluded_screen_ids;
      var result = [];
      for(var i=0; i<list.length; i++) {
        if(!excl.includes(list[i].id)) {
          result.push(list[i]);
        }
      }
      return result;
    }

    isSelectedScreen( deviceID) {
      if(!this.state.selected) return false;
      for(var i=0; i<this.state.selected.length; i++) {
        if(this.state.selected[i]===deviceID) {
          return true;
        }
      }
      return false;
    }

		isSelectedGroup( groupID) {
			var screensInGroup = this.getScreensInGroup(groupID);
			//console.log("checking if all screens in group selected, screens="+JSON.stringify(screensInGroup));
			for(var i=0; i<screensInGroup.length; i++) {
					if(!this.isSelectedScreen(screensInGroup[i])) {
						//console.log("is not selected: "+screensInGroup[i]+" selectedscreens="+JSON.stringify(this.state.selected));
						return false;
					}
			}
			return true;
		}

		getScreensInGroup( groupID) {
			for(var i=0; i<this.state.groups.length; i++) {
				if(this.state.groups[i].group_id===groupID) return this.state.groups[i].device_ids;
			}
			return [];
		}

		addGroup( groupID) {
			var screensInGroup = this.getScreensInGroup(groupID);
			for(var i=0; i<screensInGroup.length; i++) {
				this.addScreen(screensInGroup[i]);
			}
		}

		removeGroup( groupID) {
			var screensInGroup = this.getScreensInGroup(groupID);
			for(var i=0; i<screensInGroup.length; i++) {
				this.removeScreen(screensInGroup[i]);
			}
		}

    addScreen( screenID) {
      var temp = this.state.selected;
			if(!temp.includes(screenID)) {
				temp.push(screenID);
			}
      this.setState({selected:temp});
    }

    removeScreen( screenID) {
			var temp = this.state.selected;
      temp = this.removeItemOnce(temp,screenID);
      this.setState({selected:temp});
    }

    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    }

    render() {

			const columnsDataGroups= [
        { title: ETVConstants.trans("select"), field: 'selected', render: rowData => this.isSelectedGroup(rowData.group_id)?
              <div style={{width: "10px", paddingLeft: "5px"}}><IconButton variant='contained' color='primary' onClick={()=>this.removeGroup(rowData.group_id)}><CheckBoxIcon/></IconButton></div>
              :
              <div style={{width: "10px", paddingLeft: "5px"}}><IconButton variant='contained' color='primary' onClick={()=>this.addGroup(rowData.group_id)}><CheckBoxOutlineBlankIcon/></IconButton></div>},
        { title: ETVConstants.trans("group_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.group_name} </div>) },
			];
			const tabletitleGroup = <Typography variant='h6'>{ETVConstants.trans("groups")}</Typography>

      const columnsData= [
          { title: '', field: 'selected', render: rowData => this.isSelectedScreen(rowData.id)?
            <div style={{width: "10px", paddingLeft: "5px"}}><IconButton variant='contained' color='primary' onClick={()=>this.removeScreen(rowData.id)}><CheckBoxIcon/></IconButton></div>
            :
            <div style={{width: "10px", paddingLeft: "5px"}}><IconButton variant='contained' color='primary' onClick={()=>this.addScreen(rowData.id)}><CheckBoxOutlineBlankIcon/></IconButton></div>},
					{ title:'', field:'', render: rowData => { return (<div style={{width:'5px'}}>{ETVConstants.getLastConnectSymbol(rowData.last_connected)}</div>) }},
					{ title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "5px"}}>{rowData.device_name}</div>) },
          { title: ETVConstants.trans("location"), field: 'location_name' },
          { title: ETVConstants.trans("address"), field: 'address' },
          { title: ETVConstants.trans("zip"), field: 'zip' },
          { title: ETVConstants.trans("city"), field: 'city' },
      ];

      const tabletitle = <Typography variant='h6'>{ETVConstants.trans("screens")}</Typography>

      return(
        <Dialog
          open={this.props.isOpen}
          maxWidth='md'
          fullWidth
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_screens")}</DialogTitle>
          <DialogContent>
							{this.state.groups && this.state.groups.length>0?
								<div style={{marginLeft:5,width:500}}>
							  	<BasicTable2 data={this.state.groups} columns={columnsDataGroups} title={tabletitleGroup} pageSize={5} allow_search={false} />
								</div>
								:
								<span/>
							}
							<div style={{marginTop:10}}>
								<BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />\
							</div>
          </DialogContent>
          <DialogActions>
						<Button size='small' aria-label="Select" color="primary" onClick={() => this.props.closeHook()}>{ETVConstants.trans("close")}</Button>
            <Button size='small' aria-label="Select" variant="contained" startIcon={<CheckBoxIcon />} color="primary" onClick={() => this.props.selectedScreens(this.state.selected)}>{ETVConstants.trans("select")}</Button>
          </DialogActions>
        </Dialog>
			);
	}
}

export default SelectAvailableScreensNew
