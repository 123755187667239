import React from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import SaveIcon from '@mui/icons-material/Save';
import {Button,TextField,Box,Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Chart from "react-google-charts";
import PollIcon from '@mui/icons-material/Poll';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as ETVConstants from '../ETVConstants';
import { Analytics, Poll, RestartAlt } from '@mui/icons-material';

class SurveyMgmt extends React.Component {

	constructor(props) {
      	super(props);
		this.selectedScreens = this.selectedScreens.bind(this);
		this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
		items:[],
		isLoaded: false,
		selected_device_id: undefined,
		window: '',
		show_overlay: false,
		info_message: '',
		info_title: '',
		selected_language: "all",
		locations: [],
		cur_survey_id: undefined,
		new_question: '',
		ans_1: '',
		ans_2: '',
		ans_3: '',
		ans_4: '',
		ans_5: '',
		selected_screens: 0,
		selected_channel: 0,
		bg_image_url: undefined,
		notification_email: '',
		lang:'en',
		survey_id: undefined,
		survey_type: 0,
    }

	componentDidUpdate() {
		if(!this.state.isLoaded) {
			this.loadItems();
		}
	}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
		var accessToken = localStorage.getItem("etv.access_token");
      	var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/survey_mgmt?user_id="+this.props.user_id;
		if (this.state.selected_language!=="all") {
			url+="&country="+this.state.selected_language;
		}
	    fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
			(result) => {
				this.setState({
				items: result.surveys,
								locations: result.locations,
				isLoaded: true,
				});
							if(this.state.cur_survey_id) {
								var data = this.extractSurveyData(this.state.cur_survey_id);
								this.setState({data:data});
							}
			},
			(error) => {
				this.setState({
				items:[],
				isLoaded: false,
								locations:[],
				error
				});
			}
		)
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

	showInfoMessage(title,msg) {
		this.showWindow('message_div',true);
		this.setState({info_title:title, info_message:msg});
	}

	closeInfoMessage() {
		this.setState({info_message:''});
		this.showWindow('message_div',false);
	}

	resetSurvey( surveyID) {
		var url = ETVConstants.getServerBase()+"/survey_mgmt?action=reset&survey_id="+surveyID;
		this.cmdSubmit(url,ETVConstants.trans("survey_resetted"),ETVConstants.trans("failure"));
	}

	deleteSurvey( surveyID) {
		var url = ETVConstants.getServerBase()+"/survey_mgmt?action=delete&survey_id="+surveyID;
		this.cmdSubmit(url,ETVConstants.trans("survey_deleted"),ETVConstants.trans("failure"));
		this.setState({isLoaded:false});
	}

	cmdSubmit( url, successTxt, failText) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					if(result.result) {
						this.showInfoMessage(ETVConstants.trans("success"),successTxt);
						this.setState({isLoaded:false});
					}else{
						this.showInfoMessage(ETVConstants.trans("error"),failText);
					}
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),error);
				}
			)
	}

	getItem( id) {
		for( var i=0; i<this.state.items.length; i++) {
			if(this.state.items[i].id===id) return this.state.items[i];
		}
		return undefined
	}

	extractSurveyData( id) {
		var data = [];
		data[0] = ["Answer","Hits"];
		var answers = this.getItem(id).ans;
		for(var i=0; i<answers.length; i++) {
			data[i+1] = [answers[i].answer,answers[i].hits];
		}
		return data;
	}

	analyzeSurvey( id) {
		var item = this.getItem(id);
		if(item) {
			var data = this.extractSurveyData(id);
			this.setState({cur_survey_id: id, question:item.question, data:data});
			this.showWindow('analyze_survey_div',true);
		}else{
			this.showWindow('analyze_survey_div',false);
		}
	}

	createLanguageDropDown() {
			return(
				<FormControl>
					<InputLabel>{ETVConstants.trans("select_replies_by_language")}</InputLabel>
					<Select
						label={ETVConstants.trans("select_replies_by_language")}
						id="code_format"
						style={{ width: 250}}
						value={this.state.selected_language}
						onChange={(e)=>{this.setState({selected_language:e.target.value, isLoaded: false})}}>
						<MenuItem value='all'>{ETVConstants.trans("all")}</MenuItem>
						{this.state.locations?this.state.locations.map( (item) => {return <MenuItem key={'lang_'+item} value={item}>{item}</MenuItem>}):<span/>}
					</Select>
				</FormControl>
				)
	}

	createSatisfactionSurvey() {
		this.setState({survey_type:0, ans_1:'excellent',ans_2:"very good",ans_3:"average",ans_4:"poor",ans_5:"very poor","bg_image_url":undefined});
		this.showWindow('create_satisfaction_survey_div',true);
	}

	createGeneralSurvey() {
		this.setState({survey_type:1,notification_email:'',bg_image_url:undefined});
		this.showWindow('create_general_survey_div',true);
	}

	selectedScreens( screenIDs) {
		this.setState({selected_screens:screenIDs});
		if(this.state.survey_type===0) {
			this.showWindow("create_satisfaction_survey_div",true);
		}else{
			this.showWindow("create_general_survey_div",true);
		}
	}

	closeScreenSelection() {
		console.log("close screen selection");
		if(this.state.survey_type===0) {
			this.showWindow("create_satisfaction_survey_div",true);
		}else{
			this.showWindow("create_general_survey_div",true);
		}
	}

	onDrop = (files) => {
		var targetDir = "/"+this.props.user_id+"/surveys";
		for( var i=0; i<files.length; i++) {
			//console.log("drop files="+files[i]);
			this.uploadFileToGCS( files[i], targetDir);
		}
		this.setState({bg_image_url:"loading"})
	}

	uploadFileToGCS( file, targetDir) {
		var filename = file.name;
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					var formData = new FormData();
					Object.entries(result.fields).map(([key,value])=>{
							formData.append(key,value);
							//console.log("1=======>"+key+"="+value);
					})
					formData.append('file', file);
					//formData.append('success_action_redirect',)
					const config = {
						headers: {	'content-type': 'multipart/form-data'}
					}
					axios.post(result.url, formData, config)
							.then((response) => {
								this.givePublicAccess(targetDir,filename);
								this.setState({isLoaded:false});
							})
							.catch((error) => {
									console.error(error);
							});
				},
				(error) => {
					//console.log("errrrrror="+error);
				}
		)
	}

	// make public access to uploaded files
	givePublicAccess(target_dir,filename) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then( res => res.json() )
			.then(
					(result) => {
						//console.log("result after public access="+JSON.stringify(result))
						this.setState({bg_image_url:result.url});
					},
					(error) => {
						//console.log("yyy-------> "+error);
					}
				)
	}

	registerNewSurvey() {
		this.showInfoMessage(ETVConstants.trans("survey"),ETVConstants.trans("creating_survey"));
		var q = this.state.new_question;
		var a1 = this.state.ans_1;
		var a2 = this.state.ans_2;
		var a3 = this.state.ans_3;
		var a4 = this.state.ans_4;
		var a5 = this.state.ans_5;
		var bgUrl = this.state.bg_image_url;
		var notification_email = this.state.notification_email;
		var url = ETVConstants.getServerBase()+"/survey_mgmt?action=create_survey&survey_type="+this.state.survey_type+"&question="+encodeURIComponent(q)+"&user_id="+this.props.user_id+"&bg_image="+encodeURIComponent(bgUrl);
		if(a1) {
			url += "&ans_1="+encodeURIComponent(a1);
		}
		if(a2) {
			url += "&ans_2="+encodeURIComponent(a2);
		}
		if(a3) {
			url += "&ans_3="+encodeURIComponent(a3);
		}
		if(a4) {
			url += "&ans_4="+encodeURIComponent(a4);
		}
		if(a5) {
			url += "&ans_5="+encodeURIComponent(a5);
		}
		if(notification_email && notification_email.length>0) {
			url += "&notification_email="+encodeURIComponent(notification_email);
		}
		url+="&channel="+this.state.selected_channel;
		var deviceIDs = this.state.selected_screens;
		for(var i=0; i<deviceIDs.length; i++) {
			url += "&device_id="+deviceIDs[i];
		}
		url += "&template_path=templates/survey/survey_on_screen.html";

		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then( res => res.json() )
			.then(
					(result) => {
						//console.log("result after registering survey="+JSON.stringify(result))
						//this.setState({isLoaded:false});
						this.showInfoMessage(ETVConstants.trans("survey"),ETVConstants.trans("survey_registered"));
						this.setState({isLoaded:false, new_question: '', bg_image_url: undefined, channel:0, notification_email: ''});
						this.showWindow('create_survey_div',false);
					},
					(error) => {
						//console.log("yyy-------> "+error);
						this.showInfoMessage(ETVConstants.trans("survey"),error);
					}
				)
	}

	checkEmailError() {
		if (!this.state.notification_email || this.state.notification_email.length===0) return false;
		const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if(reg.test(this.state.notification_email) === false){
			return true;
		}
		return false;
	}

	getCheckEmailErrorText() {
		if (this.checkEmailError()) {
			return ETVConstants.trans("invalid_email");
		}
		return undefined;
	}

	render() {
	    const columnsData= [
          	{ title: ETVConstants.trans("question"), field: 'question', render: rowData => (<div style={{minWidth: "350px", paddingLeft: "10px"}}> {rowData.question} </div>) },
          	{ title: ETVConstants.trans("hits"), field: 'total_hits'},
			{ title: ETVConstants.trans("analyze"), field: '', render: rowData => <Button size="small" startIcon={<Analytics/>} variant='contained' color='primary' onClick={()=>this.analyzeSurvey(rowData.id)}>{ETVConstants.trans("analyze")}</Button>},
        	{ title: ETVConstants.trans("reset"), field: '', render: rowData => <Button size="small" startIcon={<RestartAlt/>} variant='contained' color='primary' onClick={()=>this.resetSurvey(rowData.id)}>{ETVConstants.trans("reset")}</Button>},
          	{ title: ETVConstants.trans("delete"), field: 'select_layout', render: rowData => <IconButton size='small' color='error' onClick={()=>this.deleteSurvey(rowData.id)}><DeleteIcon/></IconButton>},
		];

      	const tabletitle = "Surveys Management";

      return(
		<div>
		<BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={false} />
		<br/>
		<Button color="primary" variant='contained' startIcon={<PollIcon/>} onClick={() => this.createSatisfactionSurvey()}>{ETVConstants.trans("create_satisfaction_survey")}</Button>
		<Button style={{marginLeft:30}} startIcon={<PollIcon/>} color="primary" variant='contained' onClick={() => this.createGeneralSurvey()}>{ETVConstants.trans("create_general_survey")}</Button>

		<Dialog
			open={this.state.window==='message_div'}
			maxWidth='xs'
			fullWidth={true}
			onClose={()=>{this.setState({window:''})}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
			<DialogContent>
					<Typography variant='body1'>{this.state.info_message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={this.state.window==='create_satisfaction_survey_div'}
			maxWidth='lg'
			fullWidth={false}
			onClose={()=>{}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{ETVConstants.trans("create_new_satisfaction_survey")}</DialogTitle>
			<DialogContent>
						<div style={{marginTop:20}}>
							<TextField required key='new_question' label={ETVConstants.trans("survey_question")} fullWidth={true} value={this.state.new_question} onChange={(event)=>{this.setState({new_question:event.target.value})}} />
						</div>
						<br/>
						<div>
							<TextField required key='notification_email' error={this.checkEmailError()} helperText={this.getCheckEmailErrorText()} label={ETVConstants.trans("notification_email")} fullWidth={true} value={this.state.notification_email} onChange={(event)=>{this.setState({notification_email:event.target.value})}} />
						</div>
						<br/><br/>
						<table>
							<tbody>
								<tr>
									<td width='200px'>
										<div id='bgimg' style={{width:200, float:'left', border:'0px solid lightgray', padding:10,}} >
											<Dropzone
												onDrop={this.onDrop}
												disabled={false}
												accept={{ 'image/jpeg': ['.jpg'] }} 
												multiple={false}
												minSize={1024}
												maxSize={5242880}
												style={{width:'100%',height:'100%'}}>

												{({ getRootProps, getInputProps }) => (
													<div {...getRootProps({ className: "dropzone" })}>
															<input {...getInputProps()} />
															<p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
													</div>
												)}
											</Dropzone>
										</div>
									</td>
									<td width='300px'>
										{this.state.bg_image_url?<Box component="img" aspectRatio={4/3} src={this.state.bg_image_url} style={{ aspectRatio: 4/3 }}  />:<span/>}
									</td>
								</tr>
							</tbody>
						</table>
						<div>
							<Button style={{marginTop:20}} color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
							<br/>
							<FormControl style={{marginTop:20}}>
								<InputLabel>{ETVConstants.trans("channel")}</InputLabel>
								<Select
									label={ETVConstants.trans("channel")}
									id="code_format"
									style={{ width: 250}}
									value={this.state.selected_channel}
									onChange={(e)=>{this.setState({selected_channel:e.target.value})}}>
									<MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
									<MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
									<MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
									<MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
									<MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
								</Select>
							</FormControl>
						</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>{this.setState({window:''})}} color="primary">{ETVConstants.trans("close")}</Button>
				<Button disabled={ this.state.new_question.length===0 || this.state.notification_email.length===0 || !this.state.bg_image_url} onClick={()=>{this.registerNewSurvey()}}  startIcon={<SaveIcon />} variant="contained" color="success">{ETVConstants.trans("save")}</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={this.state.window==='create_general_survey_div'}
			maxWidth='xs'
			fullWidth={true}
			onClose={()=>{}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{ETVConstants.trans("create_general_survey")}</DialogTitle>
			<DialogContent>
				<TextField style={{marginTop:10}} variant='outlined' required key='new_question_gs' label={ETVConstants.trans("survey_question")} fullWidth={true} value={this.state.new_question} onChange={(event)=>{this.setState({new_question:event.target.value})}} />
				<TextField style={{marginTop:10}} required key='ans_1' label={ETVConstants.trans("answer")} fullWidth={true} value={this.state.ans_1} onChange={(event)=>{this.setState({ans_1:event.target.value})}} />
				<TextField style={{marginTop:10}} required key='ans_2' label={ETVConstants.trans("answer")} fullWidth={true} value={this.state.ans_2} onChange={(event)=>{this.setState({ans_2:event.target.value})}} />
				<TextField style={{marginTop:10}} key='ans_3' label={ETVConstants.trans("answer")} fullWidth={true} value={this.state.ans_3} onChange={(event)=>{this.setState({ans_3:event.target.value})}} />
				<TextField style={{marginTop:10}} key='ans_4' label={ETVConstants.trans("answer")} fullWidth={true} value={this.state.ans_4} onChange={(event)=>{this.setState({ans_4:event.target.value})}} />
				<TextField style={{marginTop:10}} key='ans_5' label={ETVConstants.trans("answer")} fullWidth={true} value={this.state.ans_5} onChange={(event)=>{this.setState({ans_5:event.target.value})}} />

				<br/><br/>
						<table>
							<tbody>
								<tr>
									<td width='200px'>
										<div id='bgimg' style={{width:200, float:'left', border:'0px solid lightgray', padding:10,}} >
											<Dropzone
												onDrop={this.onDrop}
												disabled={false}
												accept={{ 'image/jpeg': ['.jpg'] }} 
												multiple={false}
												minSize={1024}
												maxSize={5242880}
												style={{width:'100%',height:'100%'}}>

												{({ getRootProps, getInputProps }) => (
													<div {...getRootProps({ className: "dropzone" })}>
															<input {...getInputProps()} />
															<p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
													</div>
												)}
											</Dropzone>
										</div>
									</td>
									<td width='300px'>
										{this.state.bg_image_url?<Box component="img" src={this.state.bg_image_url} />:<span/>}
									</td>
								</tr>
							</tbody>
						</table>
						<div>
							<Button style={{marginTop:20}} color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
							<FormControl style={{marginTop:20}}>
									<InputLabel>{ETVConstants.trans("channel")}</InputLabel>
									<Select
										label={ETVConstants.trans("channel")}
										id="code_format"
										style={{ width: 250}}
										value={this.state.selected_channel}
										onChange={(e)=>{this.setState({selected_channel:e.target.value})}}>
										<MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
										<MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
										<MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
										<MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
										<MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
									</Select>
							</FormControl>
						</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>{this.setState({window:''})}} color="primary">{ETVConstants.trans("close")}</Button>
				<Button disabled={ this.state.new_question.length===0 || this.state.ans_1.length===0 || this.state.ans_2.length===0 || !this.state.bg_image_url} onClick={()=>{this.registerNewSurvey()}} variant="contained"  startIcon={<SaveIcon />} color="success">{ETVConstants.trans("save")}</Button>
			</DialogActions>
		</Dialog>

		<div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
			<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
		</div>

		<Dialog
			open={this.state.window==='analyze_survey_div'}
			maxWidth='lg'
			fullWidth={false}
			onClose={()=>{}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
			<DialogContent>
					<Typography variant="h5" component="h6">{this.state.question}</Typography>
					<div style={{marginTop:20}}>
						{this.createLanguageDropDown()}
					</div>
					<Chart
						width={600}
						height={400}
						chartType="BarChart"
						loader={<div>{ETVConstants.trans("loading_chart")}</div>}
						data={this.state.data}
						options={{
							title: this.props.chart_title,
							chartArea: { width: '50%' },
							hAxis: {
								title: '',
								minValue: 0,
							},
							vAxis: {
								title: '',
								minValue: 0,
							},
						}}
						legendToggle
					/>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
			</DialogActions>
		</Dialog>
		   	</div>
			);
	}
}

export default SurveyMgmt
