import React from 'react';
import {Button} from '@mui/material';
import BasicTable2 from "../tools/BasicTable2";
import * as ETVConstants from '../ETVConstants';

class ElevatorStatistics extends React.Component {

	constructor(props) {
      super(props);
    }

    state = {
      	items:[],
      	isLoaded: false,
		selected_comm_nr: undefined,
    }

	componentDidUpdate() {
      	if(!this.state.isLoaded) {
        	this.loadItems();
      	}
	}

    componentDidMount() {
      	this.loadItems();
    }

    loadItems() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/trip_mgmt?action=list&user_id="+this.props.user_id;
		//console.log("url="+url);
      	fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
            this.setState({
              items: result.list,
              isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
          }
        )
    }

    showWindow(divID, show) {
      	{ show?this.setState({window:divID}):this.setState({window:''}) };
    }

    getItem( deviceID) {
      	for(var i=0; i<this.state.items.length; i++) {
			if(this.state.items[i].id===deviceID) {
			return this.state.items[i];
			}
      	}
      	return undefined;
    }

	showTravelStatistics( commNr) {
		this.showWindow("travel_statistics_div",true);
		this.setState({selected_comm_nr:commNr});
	}

	formatDist( num) {
		return ( Math.round(num*100)/100000).toFixed(2)+" km";
	}

	formatAvgDay( num) {
		return num.toFixed(2)+"/day";
	}

	resetCounter( deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/trip_mgmt?action=reset_counter&device_id="+deviceID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						isLoaded: false,
					});
				},
				(error) => {
				}
			)
	}

	resetCounterComplete( deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/trip_mgmt?action=reset_counter_complete&device_id="+deviceID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						isLoaded: false,
					});
				},
				(error) => {
				}
			)
	}

	calcAvg( rowData, initialDate) {
		//console.log("rowData="+JSON.stringify(rowData));
		var now = new Date();
		var d0 = new Date(rowData.initial_date);
		var trips = rowData.trips_total;
		if(!initialDate) {
			d0 = new Date(rowData.current_date);
			trips = rowData.trips_current;
		}
		//console.log("isNan(trips): "+isNaN(trips)+" trips="+trips);
		if(isNaN(trips)) return 0;
		if(isNaN(d0)) return 0;

		var diffMs = now.getTime()-d0.getTime();
		var diffDays = diffMs/1000/3600/24;
		diffDays = Math.max(1.0,diffDays);
		//console.log("diffdays="+diffDays);
		return this.formatAvgDay(trips/diffDays);
	}

    render() {

      const columnsData= [
          	{ title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "150px", paddingLeft: "10px"}}> {rowData.device_name} </div>) },
			{ title: ETVConstants.trans("location_name"), field: 'location_name'},
			{ title: ETVConstants.trans("trips_current"), field: 'trips_current', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {rowData.trips_current} </div>)},
			{ title: ETVConstants.trans("distance_current"), field: 'distance_current', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.formatDist(rowData.distance_current)} </div>)},
			{ title: ETVConstants.trans("current_average_day"), field: '', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.calcAvg(rowData,false)} </div>)},
			{ title: ETVConstants.trans("reset"), field: 'reset', render: rowData => (<Button variant='contained' size='small' color='primary' onClick={()=>this.resetCounter(rowData.device_id)}>{ETVConstants.trans("reset")}</Button>)},
			{ title: ETVConstants.trans("trips_total"), field: 'trips_total', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {rowData.trips_total} </div>)},
			{ title: ETVConstants.trans("distance_total"), field: 'distance_total', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.formatDist(rowData.distance_total)} </div>)},
			{ title: ETVConstants.trans("overall_average"), field: '', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.calcAvg(rowData,true)} </div>)},
			{ title: ETVConstants.trans("reset_completely"), field: 'reset', render: rowData => (<Button variant='contained' size='small' color='secondary' onClick={()=>this.resetCounterComplete(rowData.device_id)}>{ETVConstants.trans("reset_completely")}</Button>)},
        ];
      const tabletitle = "Elevator Screens";

      return(
		<div>
	        <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={20} allow_search={true} />
	   	</div>
		);
	}
}

export default ElevatorStatistics
