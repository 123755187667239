import React from 'react';
import {Card,Button, CircularProgress} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import BasicTable2 from "../tools/BasicTable2";
import Checkbox from '@mui/material/Checkbox';
import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class ExtraAccessScreens extends React.Component {

    state = {
      result_parent: undefined,
      result_user: undefined,
      isLoaded: false,
      hasChanges: false,
    }

		componentDidUpdate(prevProps, prevState) {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
      if(this.props.user_id!==prevProps.user_id) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
      if(!this.props.user_id) {
        return;
      }
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.parent_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              result_parent: result,
            });
            // load user accesss
            var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
            //console.log("loading user access url="+url);
            fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
              .then(res => res.json())
              .then(
                (result) => {
                  //console.log(JSON.stringify(result));
                  this.setState({
                    result_user: result,
                    isLoaded:true,
                  });
                },)
          },
          (error) => {
            this.setState({
              result_user: undefined,
              error
            });
          }
        )
    }

    isSelectedScreenChannel( deviceID, channel) {
      if(!this.state.result_user) return false;
      var channels = this.state.result_user[deviceID];
      // note: channels may be empty --> means no access defined
      var res = false;
      if(channels) {
        res = channels.includes(channel);
      }
      //console.log("----> isSelectedChannel: deviceID="+deviceID+" channel="+channel+" result="+res+" data="+this.state.result_user[deviceID]);
      return res;
    }

    addScreenChannel( deviceID, channel) {
      var userData = this.state.result_user;
      if(userData[deviceID]) {
        var curChannels = userData[deviceID];
        curChannels.push(channel);
        userData[deviceID] = curChannels;
      }else{
        var channels = [];
        channels.push(channel);
        userData[deviceID] = channels;
      }
      this.setState({result_user:userData, hasChanges:true});
    }

    removeScreenChannel( deviceID, channel) {
      var userData = this.state.result_user;
      if(userData[deviceID]) {
        var curChannels = userData[deviceID];
        this.removeItemOnce(curChannels,channel);
        userData[deviceID] = curChannels;
      }else{
      }
      this.setState({result_user:userData, hasChanges:true});
    }

    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    }

    parentHasAccess( deviceID, channelNr) {
      if(!this.state.result_parent) return false;
      var channels = this.state.result_parent[deviceID];
      if(!channels) return true;
      return channels.includes(channelNr);
    }

    printChannelAccess( deviceID, channelNr) {
      var checked = this.isSelectedScreenChannel(deviceID,channelNr);
      var parentHasAccess = this.parentHasAccess(deviceID,channelNr);
      return (
          <Checkbox
                disabled={!parentHasAccess}
                checked={checked}
                onChange={(e)=>{checked?this.removeScreenChannel(deviceID,channelNr):this.addScreenChannel(deviceID,channelNr)}}
                inputProps={{ 'aria-label': 'primary checkbox' }}
          />
      );
    }

    saveAccess() {
			var url = ETVConstants.getServerBase()+'/device_mgmt?action=extra_access&user_id='+this.props.user_id;
      var parentDeviceIDs = this.state.result_parent["device_ids"];
      var cnt = 0;
      for(var i=0; i<parentDeviceIDs.length; i++) {
        if(this.state.result_user[parentDeviceIDs[i]]) {
          var channels = this.state.result_user[parentDeviceIDs[i]];
          url+="&elem."+cnt+".device_id="+parentDeviceIDs[i]+"&elem."+cnt+".channels="+encodeURIComponent(this.printChannelArray(channels));
          cnt++;
        }
      }
      console.log(url);
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    			.then((response) => response.json())
    			.then((responseJson) => {
            console.log(JSON.stringify(responseJson));
            this.setState({hasChanges:false});
    			})
    			.catch((error) => {
    	});
		}

    printChannelArray(arr) {
      var s = "[";
      for(var i=0; i<arr.length; i++) {
        s+=arr[i];
        if(i!==arr.length-1) s+=",";
      }
      s+="]";
      return s;
    }


    render() {

      const columnsData= [
          { title: 'status', field: 'device_name', render: rowData => (<div style={{width: 10, paddingLeft: "5px"}}>{ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)}</div>) },
          { title: ETVConstants.trans("screen"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
          { title: ETVConstants.trans("location"), field: 'location_name' },
          { title: "Channel 0", field: 'channel0', render: rowData => this.printChannelAccess(rowData.id,0) },
          { title: 'Channel 1', field: 'channel1', render: rowData => this.printChannelAccess(rowData.id,1) },
          { title: 'Channel 2', field: 'channel2', render: rowData => this.printChannelAccess(rowData.id,2) },
        ];

      const tabletitle = "Select Screens and Channels";

      if(!this.state.isLoaded) {
        return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}}><CircularProgress /></div>
      }

      return(
          <span>
            <div>
                {this.state.result_parent?<BasicTable2 data={this.state.result_parent.list} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />:<span/>}
            </div>
            <div style={{marginTop:20}}>
                <Button disabled={!this.state.hasChanges} aria-label="Select" startIcon={<Save/>} color="primary" variant="contained" onClick={() => this.saveAccess()}>{ETVConstants.trans("save")}</Button>
            </div>
          </span>
			);
	}
}

export default ExtraAccessScreens
