import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import * as ETVConstants from '../ETVConstants';
import { OutlinedInput } from '@mui/material';

class Newsticker extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    componentDidMount() {
      this.loadItems();
    }

    componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
    }

    state = {
      items:[],
      isLoaded:false,
      edit_id:'',
      edit_name:'',
      edit_value:'',
      edit_textColor:'#FFFFFF',
      edit_bgColor:'#000000',
      edit_size:'medium',
      selected_screens: [],
    }

    selectedScreens(screenIDs) {
      //console.log("selected screens="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    loadItems() {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/marquee?action=list_marquees&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
            this.setState({
              items: result.data,
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: true,
            });
          }
        )
    }

    addNewTickers() {
      if(this.state.selected_screens.length===0) {
        this.showInfoMessage(ETVConstants.trans("no_screen_selected"));
        return;
      }
      this.showInfoMessage(ETVConstants.trans("saving_ticker"));
      var url = ETVConstants.getServerBase()+"/marquee?action=save&bg_color="+encodeURIComponent(this.state.edit_bgColor)+"&text_size="+this.state.edit_size+"&text_color="+encodeURIComponent(this.state.edit_textColor);
      url += "&ticker_text="+encodeURIComponent(this.state.edit_value);
      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&screen_id="+this.state.selected_screens[i];
        }
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log(url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false,edit_value:'',selected_screens:[]});
            this.showWindow("edit_div",false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    updateTicker() {
      //this.showInfoMessage(ETVConstants.trans("update ticker"));
      var url = ETVConstants.getServerBase()+"/marquee?action=save&screen_id="+this.state.edit_id+"&bg_color="+encodeURIComponent(this.state.edit_bgColor)+"&text_size="+this.state.edit_size+"&text_color="+encodeURIComponent(this.state.edit_textColor);
      url += "&ticker_text="+encodeURIComponent(this.state.edit_value);
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log("xxxx="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false,edit_id:'',edit_value:''});
            this.showWindow("edit_div",false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    deleteTicker(screenID) {
      var url = ETVConstants.getServerBase()+"/marquee?action=delete&screen_id="+screenID;
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log(url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    editTicker(screenID,screenName,tickerText,bgColor,txtColor,textSize) {
      this.setState({edit_id:screenID,edit_name:screenName,edit_value:tickerText,edit_bgColor:bgColor,edit_textColor:txtColor,edit_size:textSize});
      this.showWindow("edit_div",true);
    }

    closeScreenSelection() {
      this.showWindow('add_static_ticker_div',true);
    }

    selectedScreens(screenIDs) {
      this.showWindow('add_static_ticker_div',true);
      this.setState({selected_screens:screenIDs});
    }

    showAddTickerDiv() {
      this.setState({ticker_text:'',selected_screens:[],edit_value:'',edit_bgColor:'#000000',edit_textColor:'#FFFFFF'});
      this.showWindow('add_static_ticker_div',true)
    }

    render() {

      const columnsData= [
          { title: ETVConstants.trans("device_name"), field: 'screen_name',render: rowData => (<div style={{minWidth: "100px", paddingLeft: "0px"}}> {rowData.screen_name} </div>) },
          { title: ETVConstants.trans("ticker_text"), field: 'ticker_text',render: rowData => (<div style={{minWidth: "300px", paddingLeft: "0px"}}> {rowData.ticker_text} </div>) },
          { title: ETVConstants.trans("edit"), field: '', render: rowData => <Button size='small' startIcon={<EditIcon/>} variant='contained' color='primary' onClick={()=>{this.editTicker(rowData.screen_id,rowData.screen_name,rowData.ticker_text,rowData.bg_color,rowData.text_color,rowData.text_size)}}>{ETVConstants.trans("edit")}</Button>},
          { title: ETVConstants.trans("delete"), field: 'select_layout', render: rowData => <Tooltip title={ETVConstants.trans("delete")}><IconButton size='small' color='error' onClick={()=>this.deleteTicker(rowData.screen_id)}><DeleteIcon/></IconButton></Tooltip>},
			];

      const tabletitle = "Ticker Text";

      return (
        <div style={{width:'700px'}}>
          <Card>
            <CardContent>
                <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />
                <Divider style={{marginTop:20}} />
                <Button style={{marginTop:20}} variant="contained" color="primary" onClick={() => {this.showAddTickerDiv()}}>{ETVConstants.trans("add_ticker")}</Button>
            </CardContent>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
          </div>

          <Dialog
            open={this.state.window==='add_static_ticker_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("add_ticker")}</DialogTitle>
            <DialogContent>
                <FormControl style={{marginTop:10}} variant='outlined' fullWidth> 
                  <InputLabel htmlFor="ticker_text">{ETVConstants.trans("ticker_text")}</InputLabel>
                  <OutlinedInput
                      id="ticker_text"
                      multiline
                      minRows={4}
                      onChange={(e)=>{this.setState({edit_value:e.target.value})}}
                      value={this.state.edit_value}
                      label={ETVConstants.trans("ticker_text")}/>
                </FormControl>
                <div style={{marginTop:5}}>
                    <Typography variant="body2">{ETVConstants.trans("font_color")}</Typography>
                    <input type="color" style={{marginTop:5}} value={this.state.edit_textColor} onChange={e => this.setState({edit_textColor:e.target.value} )} />
                </div>
                <br/>
                <div>
                    <Typography variant="body2">{ETVConstants.trans("background_color")}</Typography>
                    <input type="color" style={{marginTop:5}} value={this.state.edit_bgColor} onChange={e => this.setState({edit_bgColor:e.target.value} )} />
                </div>
                <br/>
                <div>
                    <FormControl style={{marginTop:20, width:200}}>
                        <InputLabel>{ETVConstants.trans("text_size")}</InputLabel>
                        <Select
                          label={ETVConstants.trans("text_size")}
                          id="text_size"
                          value={this.state.edit_size}
                          onChange={(e)=>{this.setState({edit_size:e.target.value})}}>
                          <MenuItem value='small'>{ETVConstants.trans("small")}</MenuItem>
                          <MenuItem value='medium'>{ETVConstants.trans("medium")}</MenuItem>
                          <MenuItem value='large'>{ETVConstants.trans("large")}</MenuItem>
                          <MenuItem value='extra_large'>{ETVConstants.trans("extra_large")}</MenuItem>
                        </Select>
                      </FormControl>
                </div>
                <Button style={{marginTop:30}} color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.showWindow("add_static_ticker_div",false)}} size="medium" color="primary">{ETVConstants.trans("close")}</Button>
              <Button onClick={()=>{this.addNewTickers()}} variant='contained' size="medium" disabled={this.state.selected_screens.length===0} startIcon={<SaveIcon />} color="primary">{ETVConstants.trans("save")}</Button>
            </DialogActions>
          </Dialog>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens}/>
          </div>

          <Dialog
            open={this.state.window==='edit_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.edit_name}</DialogTitle>
            <DialogContent>
                <Typography variant="body1"></Typography>
                <FormControl style={{marginTop:10}} variant='outlined' fullWidth> 
                  <InputLabel htmlFor="ticker_text">{ETVConstants.trans("ticker_text")}</InputLabel>
                  <OutlinedInput
                      id="ticker_text"
                      multiline
                      minRows={4}
                      onChange={(e)=>{this.setState({edit_value:e.target.value})}}
                      value={this.state.edit_value}
                      label={ETVConstants.trans("ticker_text")}/>
                </FormControl>
                <div style={{marginTop:10}}>
                    <Typography variant="body2">{ETVConstants.trans("font_color")}</Typography>
                    <input type="color" style={{marginTop:5}} value={this.state.edit_textColor} onChange={e => this.setState({edit_textColor:e.target.value} )} />
                </div>
                <br/>
                <div>
                    <Typography variant="body2">{ETVConstants.trans("background_color")}</Typography>
                    <input type="color" style={{marginTop:5}} value={this.state.edit_bgColor} onChange={e => this.setState({edit_bgColor:e.target.value} )} />
                </div>
                <br/>
                <div>
                    <FormControl>
                        <InputLabel>{ETVConstants.trans("text_size")}</InputLabel>
                        <Select
                          label={ETVConstants.trans("text_size")}
                          id="text_size_id_edit"
                          style={{ width: 200}}
                          size="medium"
                          value={this.state.edit_size}
                          onChange={(e)=>{this.setState({edit_size:e.target.value})}}>
                          <MenuItem value='small'>{ETVConstants.trans("small")}</MenuItem>
                          <MenuItem value='medium'>{ETVConstants.trans("medium")}</MenuItem>
                          <MenuItem value='large'>{ETVConstants.trans("large")}</MenuItem>
                          <MenuItem value='extra_large'>{ETVConstants.trans("extra_large")}</MenuItem>
                        </Select>
                      </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} size="medium" color="primary">{ETVConstants.trans("close")}</Button>
              <Button onClick={()=>{this.updateTicker()}} size="medium" variant='contained' startIcon={<SaveIcon />} color="primary">{ETVConstants.trans("save")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default Newsticker;
