import React from 'react';
import {Button,TextField} from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LocationMap from '../devicemgmt/LocationMap';
import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class DeviceAddress extends React.Component {

	constructor(props) {
      	super(props);
		this.setLocationGPS = this.setLocationGPS.bind(this);
		this.state.mode=0;
		if(this.props.device) {
			//console.log("edit....");
			this.state.mode=1;
			this.state.new_device_id=this.props.device.id;
			this.state.new_device_name=this.props.device.device_name;
			this.state.new_location=this.props.device.location_name;
			this.state.new_address=this.props.device.address;
			this.state.new_zip=this.props.device.zip;
			this.state.new_city=this.props.device.city;
			this.state.new_country=this.props.device.country;
			this.state.new_contact_phone=this.props.device.contact_phone;
			this.state.new_contact_email=this.props.device.contact_email;
			this.state.new_lat=this.props.device.lat;
			this.state.new_lgt=this.props.device.lgt;
			this.state.canSave=true;
		}
    };

    state = {
		mode:0, // 0=new 1=edit
		window:'',
		new_device_id:'',
		new_device_id_err:'',
		new_device_name:'',
		new_device_name_err:'',
		new_location:'',
		new_location_err:'',
		new_address:'',
		new_zip:'',
		new_city:'',
		new_country:'',
		new_contact_phone:'',
		new_contact_email:'',
		new_lgt:0.0,
		new_lgt_err:'',
		new_lat:0.0,
		new_lat_err:'',
		canSave:false,
		info_message:'',
    }

	saveDevice() {
		var action = 'add_new_device';
		if(this.state.mode===1) {
			action="update_device";
		}

		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/device_mgmt?';
		url+='&action='+action;
		url+='&device_type='+this.props.device_type;
		url+='&owner_id='+reqUserID;
		url+='&device_id='+encodeURIComponent(this.state.new_device_id);
		url+='&device_name='+encodeURIComponent(this.state.new_device_name);
		url+='&location_name='+encodeURIComponent(this.state.new_location);
		url+='&address='+encodeURIComponent(this.state.new_address);
		url+='&zip='+encodeURIComponent(this.state.new_zip);
		url+='&city='+encodeURIComponent(this.state.new_city);
		url+='&country='+encodeURIComponent(this.state.new_country);
		url+='&contact_phone='+encodeURIComponent(this.state.new_contact_phone);
		url+='&contact_email='+encodeURIComponent(this.state.new_contact_email);
		url+='&lat='+this.state.new_lat;
		url+='&lgt='+this.state.new_lgt;

		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
				//console.log(JSON.stringify(result));
				if(!result['result']) {
					this.setState({new_device_id:'',new_device_name:'',new_location:'',new_address:'',new_zip:'',new_city:'',new_country:'',new_contact_phone:'',new_contact_email:'',new_lat:0.0,new_lgt:0.0});
					this.showInfoMessage(result['reason']);
				}else{
					this.setState({isLoaded:false,new_device_id:'',new_device_name:'',new_location:'',new_address:'',new_zip:'',new_city:'',new_country:'',new_contact_phone:'',new_contact_email:'',new_lat:0.0,new_lgt:0.0});
					this.props.closeF(true);
				}
			},
			(error) => {
				this.setState({new_device_id:'',new_device_name:'',new_location:'',new_address:'',new_zip:'',new_city:'',new_country:'',new_contact_phone:'',new_contact_email:'',new_lat:0.0,new_lgt:0.0});
				//console.log("error msg="+error.message);
			}
		)
	}

	showInfoMessage(msg) {
		this.setState({window:'message_div',info_message:msg});
	}

	setLocationGPS( lat, lgt) {
		this.setState({new_lat:lat,new_lgt:lgt, hasChanged: true});
		this.showWindow('location_map_div',false);
	}

	showWindow(divID, show) {
		{ show?this.setState({window:divID}):this.setState({window:''}) };
	}

	checkInputs() {
		var hasErrors = false;

		if(this.state.new_device_id.length<5) {
			hasErrors = true;
			this.setState({new_device_id_err:'must have at least 5 characters'});
		}else{
			this.setState({new_device_id_err:''});
		}

		if(this.state.new_device_name.length<3) {
			hasErrors = true;
			this.setState({new_device_name_err:'must have at least 3 characters'});
		}else{
			this.setState({new_device_name_err:''});
		}

		if(hasErrors) {
			this.setState({canSave:false});
		}else{
			this.setState({canSave:true});
		}

	}

    render() {

      return(
		<div>
			<TextField required disabled={this.state.mode===1} error={this.state.new_device_id_err.length>0} helperText={this.state.new_device_id_err} autoComplete='off' style={{marginTop:10}} fullWidth={true} size="small" id="device_id" label={ETVConstants.trans("device_id")}  value={this.state.new_device_id} onChange={(e)=>{this.checkInputs();this.setState({new_device_id:e.target.value})}} />
			<TextField required error={this.state.new_device_name_err.length>0} helperText={this.state.new_device_name_err} autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="device_name" label={ETVConstants.trans("elevator_name")}  value={this.state.new_device_name} onChange={(e)=>{this.checkInputs();this.setState({new_device_name:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_location" label={ETVConstants.trans("building")}  value={this.state.new_location} onChange={(e)=>{this.setState({new_location:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_address" label={ETVConstants.trans("address")}  value={this.state.new_address} onChange={(e)=>{this.setState({new_address:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_zip" label={ETVConstants.trans("zip")}  value={this.state.new_zip} onChange={(e)=>{this.setState({new_zip:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_city" label={ETVConstants.trans("city")}  value={this.state.new_city} onChange={(e)=>{this.setState({new_city:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_country" label={ETVConstants.trans("country")}  value={this.state.new_country} onChange={(e)=>{this.setState({new_country:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_contact_phone" label={ETVConstants.trans("phone")}  value={this.state.new_contact_phone} onChange={(e)=>{this.setState({new_contact_phone:e.target.value})}} />
			<TextField autoComplete='off' style={{marginTop:15}} fullWidth={true} size="small" id="new_contact_email" label={ETVConstants.trans("email")}  value={this.state.new_contact_email} onChange={(e)=>{this.setState({new_contact_email:e.target.value})}} />
			<Paper elevation={5} style={{marginTop:10,paddingTop:10, paddingBottom:20, paddingLeft:10}}>
				<div style={{marginTop:10}}><Button size='small' variant='contained' color='primary' onClick={()=>this.showWindow("location_map_div",true)}>{ETVConstants.trans("set_location")}</Button></div>
				<TextField autoComplete='off' style={{marginTop:15}} disabled={true} fullWidth={false} size="small" id="new_lgt" label={ETVConstants.trans("longitude")}  value={this.state.new_lgt} onChange={(e)=>{this.setState({new_lgt:e.target.value})}} />
				<TextField autoComplete='off' style={{marginTop:15}} disabled={true} fullWidth={false} size="small" id="new_lat" label={ETVConstants.trans("latitude")}  value={this.state.new_lat} onChange={(e)=>{this.setState({new_lat:e.target.value})}} />
			</Paper>
			<Button style={{marginTop:20}} startIcon={<Save/>} disabled={!this.state.canSave} onClick={() => this.saveDevice()} variant='contained' color='primary' >{this.state.mode===0?ETVConstants.trans("save"):ETVConstants.trans("update")}</Button>

			<Dialog
				open={this.state.window==='location_map_div'}
				onClose={()=>{}}
				fullWidth={true}
				maxWidth='xl'
				scroll='paper'
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_location")}</DialogTitle>
				<DialogContent>
						<div style={{height:800,maxHeight:800}}>
							{this.state.window==='location_map_div'?<LocationMap lat={this.state.new_lat} lgt={this.state.new_lgt} setLocationGPS={this.setLocationGPS} />:<span/>}
						</div>
				</DialogContent>
				<DialogActions>
						<Button onClick={()=>{this.showWindow('location_map_div',false)}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='message_div'}
				maxWidth='xs'
				fullWidth={true}
				onClose={()=>{this.setState({window:''})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title"></DialogTitle>
				<DialogContent>
						<Typography variant="body1">{this.state.info_message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
	}
}

export default DeviceAddress
