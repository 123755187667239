import React from 'react';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Delete from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class Jobs extends React.Component {

    state = {
      	isLoaded: false,
      	list: [],
		all_skills:[],
		elevators:[],
		users:[],
		definitions:[],
		edit_assigned_user_id:this.props.user_id,
		edit_job_status:0,
		edit_comment:'',
		edit_job_def_id:-1,
		edit_device_id:'',
		edit_job_id:-1,
		available_users_for_device:[],
		filter_user_id:-1,
		filter_device_id:-1,
		filter_priority:-1,
		filter_status:-1
    }

    componentDidMount() {
		this.loadUsers();
		this.loadElevators();
		this.loadJobDefinitions();
    }

	componentDidUpdate() {
		if(!this.state.isLoaded) {
			this.loadJobs();
		}
	}

	loadUsers() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/emergency?action=list_service_technicians&user_id="+this.props.user_id;
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("users="+JSON.stringify(result));
            var rx = result.children;
            this.setState({
              users: rx,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

	loadAvailableUsersForDevice(deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/emergency?action=list&device_id="+deviceID;
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("users for device="+JSON.stringify(result));
            var rx = result.list;
            this.setState({
              available_users_for_device: rx,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

	loadElevators() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=list_all_devices&owner_id="+this.props.user_id;
		url += "&device_type=0&device_type=1&device_type=2";
      	fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
            this.setState({
              elevators: result.list,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              elevators:[],
              error
            });
          }
        )
    }


	loadJobs() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/job_mgmt?action=list&user_id="+this.props.user_id;
		//console.log("url="+url);
      	fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+JSON.stringify(result.list));
            this.setState({
              list: result.list,
							isLoaded:true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

	loadJobDefinitions() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/job_definition?action=list&user_id="+this.props.user_id;
		//console.log("url="+url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log("items="+JSON.stringify(result.list));
					this.setState({
						definitions: result.list,
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					this.setState({
						error
					});
				}
			)
	}

////////////////////////////////////////////////////////////////////////////////
// server update
////////////////////////////////////////////////////////////////////////////////

	insertNewJob() {
		var url = ETVConstants.getServerBase()+"/job_mgmt?action=create_job&comment="+encodeURIComponent(this.state.edit_comment);
		url +="&job_def_id="+this.state.edit_job_def_id;
		url +="&job_owner_id="+this.props.user_id;
		url +="&device_id="+this.state.edit_device_id;
		url +="&assigned_user_id="+this.state.edit_assigned_user_id;
		url +="&priority="+this.state.edit_priority;
		//console.log("url="+url);
		this.sendCMDToServerLocal(url);
		this.showWindow('add_new_job',false);
	}

	updateJob() {
		//console.log("-------> update job def <--------------");
		var url = ETVConstants.getServerBase()+"/job_mgmt?action=update_job&comment="+encodeURIComponent(this.state.edit_comment);
		url +="&assigned_user_id="+this.state.edit_assigned_user_id;
		url +="&job_id="+this.state.edit_job_id;
		url +="&job_status="+this.state.edit_job_status;
		url +="&priority="+this.state.edit_priority;
		console.log("url="+url);
		//if(true) return;
		this.sendCMDToServerLocal(url);
		this.showWindow('edit_job',false);
		this.setState({edit_assigned_user_id:-1,edit_comment:'',edit_job_id:-1});
	}

    deleteJob( jobID) {
		var url = ETVConstants.getServerBase()+"/job_mgmt?action=delete_job&job_id="+jobID;
		console.log(url);
		this.sendCMDToServerLocal(url);
	}

	sendCMDToServerLocal(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
			if(result["result"]) {
				//console.log("ok");
			}else{
				console.log("result not ok="+JSON.stringify(result));
			}
					this.setState({isLoaded:false});
			},
			(error) => {
			console.log("error="+error);
			}
		)
	}


////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////

	showWindow(divID, show) {
		show?this.setState({window:divID}):this.setState({window:''});
	}

	showInfoMessage(title,text) {
		this.setState({info_title:title,info_message:text});
		this.showWindow('message_div',true);
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}


////////////////////////////////////////////////////////////////////////////////
// requried skills
////////////////////////////////////////////////////////////////////////////////

	printRequiredSkills(skills) {
		return skills.map( (value) => <Chip size='small' color="primary" key={value} label={value} style={{marginLeft:1,marginTop:1}}/> );
	}

////////////////////////////////////////////////////////////////////////////////
// edit
////////////////////////////////////////////////////////////////////////////////

	getJobDefinition(jobDefID) {
		for(var i=0; i<this.state.list.length; i++) {
			if(this.state.list[i].job_def_id===jobDefID) return this.state.list[i];
		}
		return null;
	}

	getUserName( userID) {
		for(var i=0; i<this.state.users.length; i++) {
			if(this.state.users[i].user_id===userID) return this.state.users[i].user_name;
		}
		if(this.props.user_id===userID) return "(myself)";
		return "---------";
	}

	getDeviceName( deviceID) {
		for(var i=0; i<this.state.elevators.length; i++) {
			if(this.state.elevators[i].id===deviceID) return this.state.elevators[i].device_name;
		}
		return "(ID="+deviceID+")";
	}

	getJobStatus(status) {
		return <Chip size='small' color={status<=1?'secondary':'default'} key={status} label={this.getStatusValue(status)} style={{marginLeft:1,marginTop:1}}/>
	}

	getStatusValue( status) {
		if(status===0) return ETVConstants.trans("new");
		if(status===1) return ETVConstants.trans("assigned");
		if(status===2) return ETVConstants.trans("confirmed");
		if(status===3) return ETVConstants.trans("on_hold");
		if(status===4) return ETVConstants.trans("in_work");
		if(status===5) return ETVConstants.trans("for_review");
		if(status===6) return ETVConstants.trans("completed");
		return "unknown";
	}

	getPriorityValue( priority) {
		if(priority===0) return ETVConstants.trans("immediately");
		if(priority===1) return ETVConstants.trans("very_high");
		if(priority===2) return ETVConstants.trans("high");
		if(priority===3) return ETVConstants.trans("medium");
		if(priority===4) return ETVConstants.trans("low");
		return "unknown";
	}

	showCreateNewJob() {
		this.setState({edit_job_status:0,edit_priority:3,edit_assigned_user_id:this.props.user_id,edit_comment:''});
		this.showWindow("add_new_job",true);
	}

	editJob(job) {
		//make sure only those users appear who are registered for this device
		this.loadAvailableUsersForDevice(job.device_id);
		this.setState({edit_job_id:job.job_id,edit_priority:job.priority,edit_job_status:job.job_status,edit_assigned_user_id:job.assigned_user_id,edit_comment:job.comment,edit_device_id:job.device_id});
		this.showWindow("edit_job",true);
	}

	createSelectUser(editDeviceID) {
		if(editDeviceID===null || editDeviceID.length===0) return <span/>
      	return (
			<FormControl style={{width:300,marginTop:10}} >
				<InputLabel>{ETVConstants.trans("assigned_user")}</InputLabel>
				<Select
					label={ETVConstants.trans("assigned_user")}
					size="small"
					id="assigned_user"
					value={this.state.edit_assigned_user_id}
					onChange={(e)=>{this.setState({edit_assigned_user_id:e.target.value})}}
				>
				<MenuItem value={-1}>{"----unassigned----"}</MenuItem>
				<MenuItem value={this.props.user_id}>{"----myself----"}</MenuItem>
				{this.state.available_users_for_device.map( (user,index) => {return <MenuItem value={user.user_id}>{user.user_name}</MenuItem>})}
				</Select>
			</FormControl>
		)
	}

	createFilters() {
		return (
			<div>
				<FormControl style={{width:200,marginTop:10}} >
					<InputLabel>{ETVConstants.trans("filter_by_user")}</InputLabel>
					<Select
							label={ETVConstants.trans("filter_by_user")}
							id="filter_user"
							value={this.state.filter_user_id}
							onChange={(e)=>{this.setState({filter_user_id:e.target.value})}}>
						<MenuItem value={-1}>{"------- all -------"}</MenuItem>
						<MenuItem value={this.props.user_id}>{"----myself----"}</MenuItem>
						{this.state.users.map( (user,index) => {return <MenuItem value={user.user_id}>{user.user_name}</MenuItem>})}
					</Select>
				</FormControl>&nbsp;&nbsp;
				<FormControl style={{width:200,marginTop:10}} >
					<InputLabel>{ETVConstants.trans("filter_by_device")}</InputLabel>
					<Select
							label={ETVConstants.trans("filter_by_device")}
							id="filter_device"
							value={this.state.filter_device_id}
							onChange={(e)=>{this.setState({filter_device_id:e.target.value})}}>
						<MenuItem value={-1}>{"------- all -------"}</MenuItem>
						{this.state.elevators.map( (elevator,index) => {return <MenuItem value={elevator.id}>{elevator.device_name}</MenuItem>})}
					</Select>
				</FormControl>&nbsp;&nbsp;
				<FormControl style={{width:200,marginTop:10}} >
					<InputLabel>{ETVConstants.trans("filter_by_priority")}</InputLabel>
					<Select
						label={ETVConstants.trans("filter_by_priority")}
						id="filter_priority"
						value={this.state.filter_priority}
						onChange={(e)=>{this.setState({filter_priority:e.target.value})}}>
						<MenuItem value={-1}>{"------- all -------"}</MenuItem>
						<MenuItem value={0}>{ETVConstants.trans("immediately")}</MenuItem>
						<MenuItem value={1}>{ETVConstants.trans("very_high")}</MenuItem>
						<MenuItem value={2}>{ETVConstants.trans("high")}</MenuItem>
						<MenuItem value={3}>{ETVConstants.trans("medium")}</MenuItem>
						<MenuItem value={4}>{ETVConstants.trans("low")}</MenuItem>
					</Select>
				</FormControl>&nbsp;&nbsp;
				<FormControl style={{width:200,marginTop:10}} >
					<InputLabel>{ETVConstants.trans("filter_by_status")}</InputLabel>
					<Select
						label={ETVConstants.trans("filter_by_status")}
						id="filter_status"
						value={this.state.filter_status}
						onChange={(e)=>{this.setState({filter_status:e.target.value})}}>
						<MenuItem value={-1}>{"------- all -------"}</MenuItem>
						<MenuItem value={0}>{ETVConstants.trans("new")}</MenuItem>
						<MenuItem value={1}>{ETVConstants.trans("assigned")}</MenuItem>
						<MenuItem value={2}>{ETVConstants.trans("confirmed")}</MenuItem>
						<MenuItem value={3}>{ETVConstants.trans("on_hold")}</MenuItem>
						<MenuItem value={4}>{ETVConstants.trans("in_work")}</MenuItem>
						<MenuItem value={5}>{ETVConstants.trans("for_review")}</MenuItem>
						<MenuItem value={6}>{ETVConstants.trans("completed")}</MenuItem>
					</Select>
				</FormControl>
			</div>
		)
	}

	createJobStatusSelect() {
		return (
			<FormControl style={{width:200,marginTop:10}} >
				<InputLabel>{ETVConstants.trans("job_status")}</InputLabel>
				<Select
					label={ETVConstants.trans("job_status")}
					id="job_status"
					size="small"
					value={this.state.edit_job_status}
					onChange={(e)=>{this.setState({edit_job_status:e.target.value})}}>
					<MenuItem value={0}>{ETVConstants.trans("new")}</MenuItem>
					<MenuItem value={1}>{ETVConstants.trans("assigned")}</MenuItem>
					<MenuItem value={2}>{ETVConstants.trans("confirmed")}</MenuItem>
					<MenuItem value={3}>{ETVConstants.trans("on_hold")}</MenuItem>
					<MenuItem value={4}>{ETVConstants.trans("in_work")}</MenuItem>
					<MenuItem value={5}>{ETVConstants.trans("for_review")}</MenuItem>
					<MenuItem value={6}>{ETVConstants.trans("completed")}</MenuItem>
				</Select>
			</FormControl>
		)
	}

	createPrioritySelect() {
		return (
			<FormControl style={{width:200,marginTop:10}} >
				<InputLabel>{ETVConstants.trans("priority")}</InputLabel>
				<Select
						label={ETVConstants.trans("priority")}
						id="job_priority"
						size="small"
						value={this.state.edit_priority}
						onChange={(e)=>{this.setState({edit_priority:e.target.value})}}	>
					<MenuItem value={-1}>{this.getPriorityValue(-1)}</MenuItem>
					<MenuItem value={0}>{this.getPriorityValue(0)}</MenuItem>
					<MenuItem value={1}>{this.getPriorityValue(1)}</MenuItem>
					<MenuItem value={2}>{this.getPriorityValue(2)}</MenuItem>
					<MenuItem value={3}>{this.getPriorityValue(3)}</MenuItem>
					<MenuItem value={4}>{this.getPriorityValue(4)}</MenuItem>
				</Select>
			</FormControl>
		)
	}

	createDeviceSelect() {
      return (
			<Autocomplete
				id="combo-box-demo"
				options={this.state.elevators}
				getOptionLabel={(option) => option.device_name}
				style={{ width: 300, marginTop:20 }}
				onChange={(e,value)=>{this.setState({edit_device_id:value.id})}}
				renderInput={(params) => <TextField {...params} label="select elevator" variant="outlined" />}
			/>
       );
	}

	createJobDefinitionSelect() {
		return (
			<FormControl style={{width:200,marginTop:10}} >
				<InputLabel id="job_definition">{ETVConstants.trans("job_template")}</InputLabel>
				<Select
					label={ETVConstants.trans("job_template")}
					id="job_template"
					size="small"
					value={this.state.edit_job_def_id}
					onChange={(e)=>{this.setState({edit_job_def_id:e.target.value})}}	>
					{this.state.definitions.map( (defObj,index) => {return <MenuItem value={defObj.job_def_id}>{defObj.desc}</MenuItem>}  )}
				</Select>
			</FormControl>
		)
	}

	getJobDesc( jobDefID) {
		for(var i=0; i<this.state.definitions.length; i++) {
			if(this.state.definitions[i].job_def_id===jobDefID) return this.state.definitions[i].desc;
		}
		return "("+jobDefID+")";
	}

	filterJobList() {
		var result = [];
		var list = this.state.list;
		//console.log("filter job list.len="+list.length);
		if(!this.state.list || this.state.list.length===0) return this.state.list;
		for(var i=0; i<list.length; i++) {
			//check user
			if (this.isValidForFilter(list[i])) {
				result.push(list[i]);
			};
		}
		return result;
	}

	isValidForFilter( elem) {
		//console.log("filteruser="+this.state.filter_user_id+" assigned_user_id="+elem.assigned_user_id);
		if(this.state.filter_user_id!==-1 && this.state.filter_user_id!==elem.assigned_user_id) return false;
		if(this.state.filter_device_id!==-1 && this.state.filter_device_id!==elem.device_id) return false;
		if(this.state.filter_priority!==-1 && this.state.filter_priority!==elem.priority) return false;
		if(this.state.filter_status!==-1 && this.state.filter_status!==elem.job_status) return false;
		return true;

	}

    render() {
		if(!this.state.isLoaded) {
			return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
		}

	    const columnsData= [
			{ title: ETVConstants.trans("desc"), field: 'job_def_id', render: rowData => <div style={{width:150}}>{this.getJobDesc(rowData.job_def_id)}</div>},
			{ title: ETVConstants.trans("assigned_user"), field: 'assigned_user_id', render: rowData => <div style={{width:180}}>{this.getUserName(rowData.assigned_user_id)}</div>},
			{ title: ETVConstants.trans("device_id"), field: 'device_id', render: rowData => <div style={{width:100}}>{this.getDeviceName(rowData.device_id)}</div>},
			{ title: ETVConstants.trans("priority"), field: 'priority', render: rowData => <div style={{width:50}}>{this.getPriorityValue(rowData.priority)}</div>},
			{ title: ETVConstants.trans("status"), field: 'status', render: rowData => <div style={{width:100}}>{this.getJobStatus(rowData.job_status)}</div>},
			{ title: ETVConstants.trans("edit"), field: 'edit', render: rowData => <div style={{width:5}}><IconButton size='small' label='edit' variant='contained' color='primary' onClick={()=>this.editJob(rowData)}><EditIcon/></IconButton></div>},
			{ title: ETVConstants.trans("delete"), field: 'delete', render: rowData => <div style={{width:5}}><IconButton size='small' label='delete' variant='contained' color='error' onClick={()=>this.deleteJob(rowData.job_id)}><Delete/></IconButton></div>},
        ];

      	const tabletitle = <Typography variant='h6'>{ETVConstants.trans("jobs")}</Typography>;
		var filteredList = this.filterJobList();

      	return(
	        <div style={{width:1300}}>
				<div style={{marginLeft:30}}>
					{this.createFilters()}
				</div>

				<BasicTable2 data={filteredList} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />

				<Dialog
					open={this.state.window==='add_new_job'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("add_new_job")}</DialogTitle>
					<DialogContent>
							{this.createJobDefinitionSelect()}
							{this.createPrioritySelect()}
                    		{this.createDeviceSelect()}
							{this.createSelectUser(this.state.edit_device_id)}
							<TextField style={{marginTop:20}} fullWidth={true} size="small" multiline={true} id="comment" variant='outlined' label={ETVConstants.trans("Comment")} value={this.state.edit_comment} onChange={(e)=>{this.setState({edit_comment:e.target.value})}} />
					</DialogContent>
					<DialogActions>
							<Button size='small' color="primary" onClick={() => this.showWindow('add_job_definition',false)}>{ETVConstants.trans("cancel")}</Button>
							<Button size='small' variant="contained" color="secondary" disabled={this.state.edit_device_id.length===0 || !this.state.edit_job_def_id>0} onClick={()=>{this.insertNewJob()}}>{ETVConstants.trans("insert")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='edit_job'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("edit_job")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" component={'span'}>
								{this.createJobStatusSelect()}
								{this.createSelectUser(this.state.edit_device_id)}
								{this.createPrioritySelect()}
								<TextField style={{marginTop:20}} fullWidth={true} size="small" multiline={true} id="comment" variant='outlined' label={ETVConstants.trans("Comment")} value={this.state.edit_comment} onChange={(e)=>{this.setState({edit_comment:e.target.value})}} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
							<Button size='small' color="primary" onClick={() => this.showWindow('add_job_definition',false)}>{ETVConstants.trans("cancel")}</Button>
							<Button size='small' variant="contained" color="primary" startIcon={<Save/>} disabled={false} onClick={()=>{this.updateJob()}}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='message_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{this.state.info_title}</DialogTitle>
					<DialogContent>
							<Typography variant="body1">{this.state.info_message}</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

        	<div>
          <br/>
          <Button variant="contained" color="primary" onClick={() => this.showCreateNewJob()} >{ETVConstants.trans("create_job")}</Button>
        </div>
	   </div>);
	}
}

export default Jobs
