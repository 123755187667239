import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {CheckCircle, Upload} from '@mui/icons-material';
import * as ETVConstants from '../ETVConstants';

class Calendar extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
      channel: "0",
      selected_screens:[],
      uploading: false,
      room_name: "",
      calendar_url: "",
      url_valid: false,
      lang: "",
      calendar_style: "style_a",
    }

    selectedScreens(screenIDs) {
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    getFilename() {
      var m = new Date();
      var dateString = ""+m.getUTCFullYear() + (m.getUTCMonth()+1) + m.getUTCDate() + m.getUTCHours() +  m.getUTCMinutes() + m.getUTCSeconds();
      return dateString+"_calendar.html";
    }

    saveCalendar() {
      this.setState({uploading:true});
      this.showInfoMessage(ETVConstants.trans("uploading..."));
      var url = ETVConstants.getServerBase()+"/template_servlet?action=create_and_save_template&user_id="+this.props.user_id;
      url += "&template_path=templates/calendar/calendar_oneday.html";
      url += "&filename="+encodeURIComponent(this.getFilename());
      url += "&showing_time=20";
      url += "&desc="+encodeURIComponent("Calendar Template "+(this.state.room_name!==null&&this.state.room_name.trim().length>0?this.state.room_name:""));
      url += "&param_0.name=@@url@@&param_0.value="+encodeURIComponent(this.createCalendarURL(this.state.calendar_url));
      url += "&param_1.name=@@lang@@&param_1.value="+this.state.lang;
      url += "&param_2.name=@@room@@&param_2.value="+encodeURIComponent(this.state.room_name);
      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        url += "&channel="+this.state.channel;
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&device_id="+this.state.selected_screens[i];
        }
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              channel: "0",
              selected_screens:[],
              uploading: false,
              room_name: "",
              calendar_url: "",
              url_valid: false,
              lang: "",
              calendar_style: "",
            });
            this.showInfoMessage(ETVConstants.trans("the_calendar_was_stored_in_your_content_library"));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
            this.setState({
              channel: "0",
              selected_screens:[],
              uploading: false,
              room_name: "",
              calendar_url: "",
              url_valid: false,
              lang: "",
              calendar_style: "",
            });
          }
        )
    }

    buttonIsDisabled() {
      if (!this.state.calendar_url || this.state.calendar_url.trim().length==0) return true;
      if (!this.state.url_valid) return true;
      return false;
    }

    createCalendarURL( urlStr) {
      return "https://elevatortv-calendar.appspot.com/calendar?scope=today_upcoming&calendar_url="+encodeURIComponent(urlStr);
    }

    checkURL() {
      this.showInfoMessage('checking...');
      var url = this.createCalendarURL(this.state.calendar_url);
      //console.log("url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            var valid = result["result"];
            this.setState({
                url_valid:valid,
            });
            if(!valid) {
              this.showInfoMessage(ETVConstants.trans("invalid_url"));
              this.setState({calendar_url:"",});
            }else{
              this.closeInfoMessage();
            }
          },
          (error) => {
            this.setState({
              url_valid:false,
              calendar_url:"",
            });
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    render() {
      return (
        <div style={{width:'700px'}}>
          <Card>
            <CardContent>
                <Typography variant="h5">
                    {ETVConstants.trans("calendar_integration")}
                </Typography>
                <div style={{marginTop:20}} />
                <Typography component={'span'} variant="body1">
                    {ETVConstants.trans("calendar_instr_1")} <br/>
                    {ETVConstants.trans("calendar_instr_2")}
                    <ol>
                      <li>{ETVConstants.trans("open_calendar_in_goole_calendar")}</li>
                      <li>{ETVConstants.trans("go_to_calendar_settings")}</li>
                      <li>{ETVConstants.trans("public_address_in_ical_format")}</li>
                    </ol>
                </Typography>
                <Divider style={{marginTop:20}} />
                <div style={{display:'flex',width:'100%',margin:'5px', marginTop:20}}>
                     <TextField required key='calendar_url' size="small" label={ETVConstants.trans("calendar_url")} fullWidth={false} style={{width:'500px'}} InputProps={{ maxLength: 200 }} value={this.state.calendar_url} onChange={(event)=>{this.setState({calendar_url:event.target.value})}} />
                     {this.state.url_valid?
                       <CheckCircle fontSize="large" style={{ color: "green" }} />:
                       <Button style={{marginLeft:10}} startIcon={<CheckCircle/>} disabled={!this.state.calendar_url || this.state.calendar_url.trim().length===0} variant="contained" size="small" color="secondary" onClick={() => this.checkURL()}>{ETVConstants.trans("validate")}</Button>
                     }
                </div>
                <div style={{display:'flex',width:'100%',margin:'5px', marginTop:20}}>
                     <TextField key='room_name' size="small" label={ETVConstants.trans("location_name")} fullWidth={false} style={{width:'300px'}} InputProps={{ maxLength: 25 }} value={this.state.room_name} onChange={(event)=>{this.setState({room_name:event.target.value})}} />
                </div>
                <div style={{display:'flex',width:'100%',margin:'5px', marginTop:20}}>
                  <FormControl>
                      <InputLabel>{ETVConstants.trans("language")}</InputLabel>
                      <Select
                          label={ETVConstants.trans("language")}
                          id="language"
                          style={{ width: 300}}
                          value={this.state.lang}
                          onChange={(e)=>{this.setState({lang:e.target.value})}}>
                           <MenuItem value='en'>English</MenuItem>
                           <MenuItem value='de'>Deutsch</MenuItem>
                           <MenuItem value='fr'>French</MenuItem>
                           <MenuItem value='es'>Spanish</MenuItem>
                           <MenuItem value='ja'>Japanese</MenuItem>
                           <MenuItem value='zh'>Chinese</MenuItem>
                           <MenuItem value='kor'>Korean</MenuItem>
                      </Select>
                      </FormControl>
                </div>
                <div style={{display:'flex',width:'100%',margin:'5px', marginTop:20}}>
                  <FormControl>
                     <InputLabel>{ETVConstants.trans("template_style")}</InputLabel>
                     <Select
                       label={ETVConstants.trans("template_style")}
                       id="style_id"
                       style={{ width: 300}}
                       value={this.state.calendar_style}
                       onChange={(e)=>{this.setState({calendar_style:e.target.value})}}>
                        <MenuItem value='style_a'>{ETVConstants.trans("daily_calendar")}</MenuItem>
                     </Select>
                      {this.state.calendar_style === 'style_a'? <img src='/images/calendar/calendar_daily_demo.jpg' width='450' alt='Daily Calendar' style={{marginLeft:20, marginTop:10}}/>:
                        <span/>
                      }
                   </FormControl>
                </div>
                <Divider style={{marginTop:20, marginBottom:20}}/>
                <Button variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
                <br/><br/>
                <FormControl>
                    <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                    <Select
                      label={ETVConstants.trans("channel")}
                      id="channel_id"
                      style={{ width: 300}}
                      value={this.state.channel}
                      onChange={(e)=>{this.setState({channel:e.target.value})}}>
                      <MenuItem value='0'>{ETVConstants.trans("channel")}&nbsp; 0</MenuItem>
                      <MenuItem value='1'>{ETVConstants.trans("channel")}&nbsp; 1</MenuItem>
                      <MenuItem value='2'>{ETVConstants.trans("channel")}&nbsp; 2</MenuItem>
                      <MenuItem value='3'>{ETVConstants.trans("channel")}&nbsp; 3</MenuItem>
                      <MenuItem value='4'>{ETVConstants.trans("channel")}&nbsp; 4</MenuItem>
                    </Select>
                  </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={this.buttonIsDisabled()} color="primary" startIcon={<Upload/>} variant='contained' onClick={() => this.saveCalendar()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
								<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
					</div>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default Calendar;
