import React from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as ETVConstants from '../ETVConstants';
import { CircularProgress } from '@mui/material';
import { Save } from '@mui/icons-material';

class StatusSetting extends React.Component {

    state = {
		isLoaded:false,
		curStatus: 'offline',
		status_validity_date: undefined,
		status_validity_comment: '',
		hasChanged: false,
		needReloadUser: false,
		showValidityDate: false,
		isSaving:false,
    }

    componentDidMount() {
		this.setState({curStatus:this.props.user_object.status});
		this.loadMembership(this.props.user_object.user_id);
    }

	componentDidUpdate(prevProps,prevState) {
		if(prevProps.user_object!==this.props.user_object) {
			this.setState({curStatus:this.props.user_object.status});
			this.loadMembership(this.props.user_object.user_id);
		}
	}

	loadMembership(userID) {
      	var accessToken = localStorage.getItem("etv.access_token");
      	var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/membershp?action=get_status&user_id="+this.props.user_object.user_id;
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
				console.log(JSON.stringify(result));
				var status = result.status;
				var statusDate = ETVConstants.createDate(result.until_date);
				var comment = result.comment;
				this.setState({hasChanged:false, isLoaded:true, curStatus:status, status_validity_date:statusDate, status_validity_comment: comment});
          },
          (error) => {
          }
        )
    }


    saveStatus() {
      	var accessToken = localStorage.getItem("etv.access_token");
      	var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/membershp?action=set_status&user_id="+this.props.user_object.user_id+"&status="+this.state.curStatus+"&is_trial=0&until_date="+ETVConstants.formatDate(this.state.status_validity_date);
		urlStr+="&comment="+encodeURIComponent(this.state.status_validity_comment&&this.state.status_validity_comment.length>0?this.state.status_validity_comment:"");
		this.setState({isSaving:true});
		//console.log("---------------------saveStatus, url="+urlStr);
      	fetch(urlStr,{ headers: { 'user_id':reqUserID,'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
						this.setState({hasChanged:false, needReloadUser:true, isSaving:false});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {

          }
        )
    }

	changeStatus(nextStatus) {
		if(this.state.curStatus===nextStatus) {
			return;
		}
		if(nextStatus==='premium' || nextStatus==='basic') {
			this.setState({curStatus:nextStatus, hasChanged:true,showValidityDate:true, status_validity_comment:'', status_validity_date: ETVConstants.oneYearLater()});
		}else{
			this.setState({curStatus:nextStatus, hasChanged:true,showValidityDate:false, status_validity_comment:'', status_validity_date: ETVConstants.forever()});
		}
	}

	showValidityDate( status) {
		return status===this.state.curStatus && this.state.status_validity_comment;
	}

	setStatusDate = (date) => {
		this.setState({status_validity_date:date, updateDate:true, hasChanged:true});
	};

	getValidityDate() {
      	//console.log("validityDate="+this.state.status_validity_date);
		if (!this.state.status_validity_date || this.state.status_validity_date.length===0) {
			return ETVConstants.createDateYearsPlus(1);
		}
		return this.state.status_validity_date;
	}

	printValidity( status) {
		var showDate = (status==='basic' || status==='premium' || status==='enterprise');
		return (
			<div style={{marginTop:10}}>
				{!showDate?<span/>:
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							margin="normal"
							id="status_date_until"
							label={ETVConstants.trans("until_date")}
							format='YYYY-MM-DD'
							maxDate={ETVConstants.forever()}
							value={this.getValidityDate()}
							onChange={this.setStatusDate}
							KeyboardButtonProps={{
								'aria-label': 'change date',
						}} />
					</LocalizationProvider>
				}
				<div style={{marginTop:10}}>
					<TextField multiline 
						label={ETVConstants.trans("comment")}
						fullWidth
						minRows={2} 
						variant='outlined' 
						value={this.state.status_validity_comment} 
						onChange={(e)=>{this.setState({status_validity_comment:e.target.value, hasChanged:true})}}
					/>
				</div>
			</div>
		)
	}

    render() {
		if(!this.state.isLoaded || this.state.isSaving) {
			return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
		}
		var parentStatus = this.props.status;
		return (
			<span>
				<span>
					<FormControl fullWidth>
						<FormLabel component="legend"></FormLabel>
						<RadioGroup style={{marginTop:20}} aria-label="Membership Status" name="status" value={this.state.curStatus} onChange={(e)=>{this.changeStatus(e.target.value)}}>
						<span>
							<FormControlLabel value="offline" control={<Radio />} label="offline" />
							{this.state.curStatus==='offline'?this.printValidity('offline'):<span/>}
						</span>
						<Divider style={{marginTop:10,marginBottom:10}}/>
						<FormControlLabel value="content_provider" control={<Radio />} label="Content Provider" />
						<Divider style={{marginTop:10,marginBottom:10}}/>
						<span>
							<FormControlLabel value="basic" control={<Radio />} label="basic" />
							{this.state.curStatus==='basic'?this.printValidity('basic'):<span/>}
						</span>
						<Divider style={{marginTop:10,marginBottom:10}}/>
						<span>
							<FormControlLabel value="premium" control={<Radio />} label="premium" />
							{this.state.curStatus==='premium'?this.printValidity('premium'):<span/>}
						</span>
						<Divider style={{marginTop:10,marginBottom:10}}/>
						<span>
						<FormControlLabel value="enterprise" control={<Radio />} label="enterprise" />
						{this.state.curStatus==='enterprise'?this.printValidity('enterprise'):<span/>}
						</span>
						<Divider style={{marginTop:10,marginBottom:10}}/>
						{parentStatus==='superadmin'?
							<span>
								<FormControlLabel value="oem" control={<Radio />} label="OEM" /><br/>
								<Divider style={{marginTop:10,marginBottom:10}}/>
								<FormControlLabel value="marketplace_provider" control={<Radio />} label="Market Place Provider" /><br/>
								<Divider style={{marginTop:10,marginBottom:10}}/>
								<FormControlLabel value="superadmin" control={<Radio />} label="Superadmin" /><br/>
								<Divider style={{marginTop:10,marginBottom:10}}/>
								<FormControlLabel value="service_technician" control={<Radio />} label="Service Technician" /><br/>
							</span>
							:
							<span/>
						}
						</RadioGroup>
					</FormControl>
				</span>
				<div style={{marginTop:20}}>
					<Button variant='contained' color='primary' startIcon={<Save/>} disabled={!this.state.hasChanged}  onClick={() => this.saveStatus()}>{ETVConstants.trans("save")}</Button>
				</div>
			</span>
		)
	}

}

export default StatusSetting
