import React from 'react';
import {Card,Button} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import * as ETVConstants from '../ETVConstants';

class LayoutItem extends React.Component {

    render() {
      var id = this.props.id;
      //console.log("myID="+id+" selected="+this.props.current_layout_id+" isMine="+(this.props.current_layout_id===id));
      var imageUrl = this.props.thumbnail;
      var canDelete = this.props.can_delete;
      var bgColor = 'white';
      var selected = false;
      if(this.props.current_layout_id==id) {
          bgColor='#b3ffd9';
          selected = true;
      }
      var paddingTop = this.props.landscape?'56.25%':'178%';
      if(this.props.screen_ratio>0) {
        //0 means general
        if(this.props.landscape) {
          paddingTop = 100.0/this.props.screen_ratio+"%";
        }else{
          paddingTop = 100.0*this.props.screen_ratio+"%";
        }
      }
      //console.log("layout_id="+this.props.id+" screenRatio="+this.props.screen_ratio+" landscape="+this.props.landscape);

      return (
        <div>
            <Card style={{backgroundColor:bgColor}}>
              <CardMedia
                style={{width: '100%', height: 'auto', paddingTop: paddingTop}}
                image={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeLayoutURLRelative(imageUrl):imageUrl}
                title="layout"
              />
              <CardActions>
                  {selected?
                    <Button disabled size='small' outline="contained" color="primary" ><Typography component={'span'}> <Box sx={{ textAlign: 'center',fontWeight:'bold',color:'black'}}> {ETVConstants.trans("current_layout")} </Box></Typography></Button>
                    :
                    <Button size='small' outline="contained" color="primary" onClick={() => this.props.saveLayoutF(this.props.layout_id)}>{ETVConstants.trans("select")}</Button>
                  }
                  { (canDelete && !selected) ?<IconButton size='small' disabled={!canDelete} aria-label="delete" color="primary" onClick={() => this.props.deleteLayoutF(this.props.layout_id)}><DeleteIcon/></IconButton>:<span/>}
              </CardActions>
            </Card>
        </div>
      )
    }
}

export default LayoutItem;
