import React from 'react';
import {Button,TextField,Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import UploadFileIcon from '@mui/icons-material/CloudUpload';
import FileUploadAdmin from './FileUploadAdmin';
import * as ETVConstants from '../ETVConstants';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ScreenShotIcon from '@mui/icons-material/AddToHomeScreen';
import StorageIcon from '@mui/icons-material/Storage';
import { Send } from '@mui/icons-material';

class RemoteDeviceMgmt extends React.Component {

	constructor(props) {
      super(props);
			this.getRestartReplyStatus = this.getRestartReplyStatus.bind(this);
			this.getScreenShotReplyStatus = this.getScreenShotReplyStatus.bind(this);
			this.getLogReplyStatus = this.getLogReplyStatus.bind(this);
    }

    state = {
      items:[],
			groups:[],
      isLoaded: false,
      selected_device_id: undefined,
			selected_group_id: undefined,
			window: undefined,
			show_overlay: false,
			info_message: undefined,
			info_title: undefined,
			message_id: undefined,
			msg_count: 0,
			screenshot_url: undefined,
			screen_ratio: 16/9,
			is_landscape: true,
			aborted: false,
			info_message_spinning:false,
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

		loadItems() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						var groups = ETVConstants.filterOutEmptyGroups(result.groups);
						//var sorted = this.filterOutScreensInGroups(result.list,groups);
						var sorted = result.list;
						sorted.sort(ETVConstants.compareDevice);
            this.setState({
              items: sorted,
              isLoaded: true,
							groups: groups,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    getItem( deviceID) {
      for(var i=0; i<this.state.items.length; i++) {
        if(this.state.items[i].id===deviceID) {
          return this.state.items[i];
        }
      }
      return undefined;
    }

		showInfoMessage(title,msg) {
			this.showInfoMessageSpinning(title,msg,false);
		}

		showInfoMessageSpinning(title,msg,spinning) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg, info_message_spinning:spinning});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		extractMediaUrlFromOldVersion(txt) {
			if(txt.indexOf("https:")>=0) {
				var temp = txt.substring(txt.indexOf("https:"));
				temp = temp.substring(0,temp.indexOf("\\"));
				//console.log("11temp="+temp);
				return temp;
			}
			return null;
		}

		getScreenShotReplyStatus() {
			//console.log("reply status");
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if(msgCnt>10) this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.showInfoMessageSpinning(ETVConstants.trans("screen"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					var replyMSG = result["reply_message"];
					if(!replyMSG) {
						// message not yet available
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getScreenShotReplyStatus, 1000);
						return;
					}

					// new version of app (from 56)
					var url = replyMSG["media_url"];
					if(url) {
						this.setState({screenshot_url:url});
						this.showWindow('screenshot_div',true);
						return;
					}

					// old version, no proper json...
					var obj0 = this.extractMediaUrlFromOldVersion(JSON.stringify(replyMSG));
					if(obj0) {
						this.setState({screenshot_url:obj0});
						this.showWindow('screenshot_div',true);
						return;
					}

					// should not happen, but otherwise generates a timeout
					this.setState({msg_count:msgCnt+1});
					setTimeout(this.getScreenShotReplyStatus, 1000);
				},
				(error) => {
				}
			)
		}

		getRestartReplyStatus() {
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.showInfoMessageSpinning(ETVConstants.trans("restart"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			//var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id=8177f31a-95df-4036-a126-43caf4c36b03';
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];

						var isStopped = replyMsg.stopped;
						if(isStopped) {
							this.showInfoMessage(ETVConstants.trans("stopped"),ETVConstants.trans("application_is_stopped"));
							return;
						}

						var isRestarting = replyMsg.restarting;
						if(isRestarting) {
							this.showInfoMessage(ETVConstants.trans("restart"),ETVConstants.trans("device_is_restarting"));
						}else{
							setTimeout(this.getRestartReplyStatus, 1000);
							this.setState({msg_count:msgCnt+1});
						}
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getRestartReplyStatus, 1000);
					}
				},
				(error) => {
				}
			)
		}

		getLogReplyStatus() {
			//console.log("log reply status");
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if(msgCnt>10) this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_reach_device"));
				//console.log("1");
				return;
			}
			//console.log("2");
			this.showInfoMessageSpinning(ETVConstants.trans("logs"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			//console.log("3");
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					var replyMSG = result["reply_message"];
					if(!replyMSG) {
						// message not yet available
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getLogReplyStatus, 1000);
						return;
					}
					if(replyMSG["device_id"]) {
						var msg11 = "https://storage.googleapis.com/hotel-screen.appspot.com/"+replyMSG["device_id"]+"/log/general_0.txt";
						//var msg = "https://console.cloud.google.com/storage/browser/_details/hotel-screen.appspot.com/"+replyMSG["device_id"]+"/log";
						this.showInfoMessage("logs",msg11);
						this.setState({message_id:'',msg_count:0});
					}
					//console.log(JSON.stringify(result));
				},
				(error) => {
				}
			)
		}

		screenShot(deviceID, ratio, isLandscape) {
			this.setState({screen_ratio:ratio, is_landscape:isLandscape, aborted:false});
			this.showInfoMessage(ETVConstants.trans("screen_shot"),ETVConstants.trans("sending_screen_shot_request"));
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'screenshot' })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						//console.log("screenshotreq="+JSON.stringify(result));
						if(result['result']) {
							this.showInfoMessage(ETVConstants.trans("screen"),ETVConstants.trans("sending_screen_shot_request"));
							this.setState({message_id:result.data.message_id, msg_count:0});
							if(!this.state.aborted) setTimeout(this.getScreenShotReplyStatus, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result.reason);
						}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),error.message);
				}
			)
		}

		restartDevice(deviceID, soft) {
			this.setState({aborted:false});
			this.showInfoMessage(ETVConstants.trans("restart"),ETVConstants.trans("sending_device_restart_request"));
			var softRestartParam = soft?"soft_restart":"reboot";
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'app_restart',fb_param_0:softRestartParam })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						if(ETVConstants.ON_PREMISE_VERSION) {
							this.showInfoMessage(ETVConstants.trans("restart"),"restart "+(soft?"application":"device")+" command was sent to device");
							return;
						}
						if(result['result']) {
							this.showInfoMessage(ETVConstants.trans("restart"),ETVConstants.trans("sending_device_restart_request"));
							this.setState({message_id:result.data.message_id, msg_count:0});
							setTimeout(this.getRestartReplyStatus, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result.reason);
						}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),'Error: '+error.message);
				}
			)
		}

		restartDeviceGroup(groupID, soft) {
			this.showInfoMessage(ETVConstants.trans("restart"),ETVConstants.trans("sending_device_restart_request"));
			var softRestartParam = soft?"soft_restart":"reboot";

			var deviceIDs = this.getDeviceIDsInGroup(groupID);
			for(var i=0; i<deviceIDs.length; i++) {
					var deviceID = deviceIDs[i];
					var isLast = (i===deviceIDs.length-1);
					//console.log("restarting deviceID="+deviceID);

					const requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'app_restart',fb_param_0:softRestartParam })
					};
					fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
					.then(res => res.json())
					.then(
						(result) => {
							if(isLast) {
								this.showInfoMessage(ETVConstants.trans("restart"),ETVConstants.trans("device_restart_request_sent"));
							}
						},
						(error) => {
							this.showInfoMessage(ETVConstants.trans("error"),'Error: '+error.message);
						}
					)
			}
		}

		showEmergencyMessage(deviceID, isGroup) {
			this.showWindow("emergency_message_div",true);
			if(isGroup) {
				this.setState({selected_group_id:deviceID, selected_device_id:undefined});
			}else{
				this.setState({selected_group_id:undefined, selected_device_id:deviceID});
			}
		}

		getGroup(groupID) {
			for(var i=0; i<this.state.groups.length; i++) {
				if(this.state.groups[i].group_id===groupID) return this.state.groups[i];
			}
			return null;
		}

		getDeviceIDsInGroup( groupID) {
			var group = this.getGroup(groupID);
			return group.device_ids;
		}

		submitEmergencyMessageA() {
			if(this.state.selected_device_id) {
				this.submitEmergencyMessage(this.state.selected_device_id,true);
			}else if(this.state.selected_group_id) {
				var deviceIDs = this.getDeviceIDsInGroup(this.state.selected_group_id);
				for(var i=0; i<deviceIDs.length; i++) {
					this.submitEmergencyMessage(deviceIDs[i],i===deviceIDs.length-1);
				}
			}
		}

		submitEmergencyMessage(deviceID, showMessage) {
			//console.log("submit message to deviceID="+deviceID);
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'emergency_message', fb_param_0:this.state.emergency_message })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						if(result['result']) {
							if(showMessage) {
								this.showInfoMessage(ETVConstants.trans("emergency_message"),ETVConstants.trans("emergency_message_sent"));
							}
							this.setState({selected_device_id:undefined,selected_group_id:undefined});
						}else{
						}
				},
				(error) => {
				}
			)
		}

		stopEmergencyMessageA(deviceIDorGroupID, isGroup) {
			//console.log("deviceIDorGroup="+deviceIDorGroupID+" isGroup="+isGroup);
			if(!isGroup) {
				this.stopEmergencyMessage(deviceIDorGroupID,true);
			}else{
				var deviceIDs = this.getDeviceIDsInGroup(deviceIDorGroupID);
				for(var i=0; i<deviceIDs.length; i++) {
					this.stopEmergencyMessage(deviceIDs[i],i===deviceIDs.length-1);
				}
			}
		}

		stopEmergencyMessage(deviceID,showMessage) {
			//console.log("emergency message stopped on deviceID="+deviceID);
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'emergency_message', fb_param_0:'' })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						if(result['result']) {
							this.showInfoMessage(ETVConstants.trans("emergency_message"),ETVConstants.trans("emergency_message_removed"));
						}else{
						}
				},
				(error) => {
				}
			)
		}

		showMessageButtonOn(rowData) {
			if (rowData.sw_version<44) return <span/>
			return <IconButton color='primary' onClick={()=>this.showEmergencyMessage(rowData.id,false)}><SpeakerNotesIcon/></IconButton>
		}

		showMessageButtonOnGroup(rowData) {
			if (rowData.sw_version<44) return <span/>
			return <IconButton color='primary' onClick={()=>this.showEmergencyMessage(rowData.group_id,true)}><SpeakerNotesIcon/></IconButton>
		}

		showMessageButtonOff(rowData) {
			if (rowData.sw_version<44) return <span/>
			return <IconButton color='primary' onClick={()=>this.stopEmergencyMessageA(rowData.id,false)}><SpeakerNotesOffIcon/></IconButton>
		}

		showMessageButtonOffGroup(rowData) {
			if (rowData.sw_version<44) return <span/>
			return <IconButton color='primary' onClick={()=>this.stopEmergencyMessageA(rowData.group_id,true)}><SpeakerNotesOffIcon/></IconButton>
		}


		fileUpload( deviceID) {
			this.showWindow('file_upload_div',true)
			this.setState({selected_device_id: deviceID});
		}

		getLogs( deviceID) {
				const requestOptions = {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'get_logs' })
				};
				fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
					.then(res => res.json())
					.then(
						(result) => {
							if(result['result']) {
								this.showInfoMessage(ETVConstants.trans("logs"),ETVConstants.trans("getting logs request"));
								this.setState({message_id:result.data.message_id, msg_count:0});
								if(!this.state.aborted) setTimeout(this.getLogReplyStatus, 1000);
							}else{
								this.showInfoMessage(ETVConstants.trans("error"),result.reason);
							}
						}
					)
		}

		openLog(deviceID,nr) {
			window.open('https://storage.googleapis.com/hotel-screen.appspot.com/'+deviceID+'/log/log_'+nr+'.txt',"_blank");
		}

    render() {

		if(!this.state.isLoaded) {
			return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
		}
		
		var isSuperAdmin = this.props.status === "superadmin";

		const tableTitleScreenGroups = <Typography variant="h6">{ETVConstants.trans("groups")}</Typography>;
		var columnsDataScreenGroups= [
				{ title: ETVConstants.trans('group_name'), field: 'group_name', render: rowData => <Typography style={{fontWeight:600}} variant='body2'>{rowData.group_name}</Typography> },
				{ title: ETVConstants.trans("message_on"), field: '', render: rowData => this.showMessageButtonOnGroup(rowData)},
				{ title: ETVConstants.trans("message_off"), field: '', render: rowData => this.showMessageButtonOffGroup(rowData)},
				{ title: ETVConstants.trans("reboot"), field: '', render: rowData => <IconButton color='primary' onClick={()=>this.restartDeviceGroup(rowData.group_id,false)}><RotateLeftIcon/></IconButton>},
				{ title: ETVConstants.trans("restart_app"), field: '', render: rowData => <IconButton color='primary' onClick={()=>this.restartDeviceGroup(rowData.group_id,true)}><RotateLeftIcon/></IconButton>},
      	];

      	var columnsData= [];
		if (isSuperAdmin) {
			columnsData= [
					{ title: ETVConstants.trans("last_connect"), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
			{ title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>)  },
			{ title: ETVConstants.trans("location"), field: 'location_name'},
					{ title: ETVConstants.trans("message_on"), field: '', render: rowData => this.showMessageButtonOn(rowData)},
					{ title: ETVConstants.trans("message_off"), field: '', render: rowData => this.showMessageButtonOff(rowData)},
					//{ title: ETVConstants.trans("reboot"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,false)}><RotateLeftIcon/></IconButton>},
					//{ title: ETVConstants.trans("restart_app"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,true)}><RotateLeftIcon/></IconButton>},
					//{ title: ETVConstants.trans("screen_shot"), field: 'screenshot', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.screenShot(rowData.id, rowData.screen_ratio)}><ScreenShotIcon/></IconButton>},
					{ title: ETVConstants.trans("reboot"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={false} onClick={()=>this.restartDevice(rowData.id,false)}><RotateLeftIcon/></IconButton>},
					{ title: ETVConstants.trans("restart_app"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={false} onClick={()=>this.restartDevice(rowData.id,true)}><RotateLeftIcon/></IconButton>},
					{ title: ETVConstants.trans("screen_shot"), field: 'screenshot', render: rowData => <IconButton variant='contained' color='primary' disabled={false} onClick={()=>this.screenShot(rowData.id, rowData.screen_ratio)}><ScreenShotIcon/></IconButton>},
					{ title: ETVConstants.trans('file_upload'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={false} onClick={()=>this.fileUpload(rowData.id)}><UploadFileIcon/></IconButton>},
					{ title: ETVConstants.trans('get_logs'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={false} onClick={()=>this.getLogs(rowData.id)}><StorageIcon/></IconButton>},
					//{ title: "Log 0", field: '', render: rowData => <Button variant='contained' color='primary' onClick={() => this.openLog(rowData.id,0)}>Log0</Button>},
					//{ title: "Log 1", field: '', render: rowData => <Button variant='contained' color='primary' onClick={() => this.openLog(rowData.id,1)}>Log1</Button>},
					//{ title: "Log 2", field: '', render: rowData => <Button variant='contained' color='primary' onClick={() => this.openLog(rowData.id,2)}>Log2</Button>},
			];
		}else{
			if(!ETVConstants.ON_PREMISE_VERSION){
				columnsData= [
					{ title: ETVConstants.trans("last_connect"), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
					{ title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>)  },
					{ title: ETVConstants.trans("location"), field: 'location_name'},
					{ title: ETVConstants.trans("message_on"), field: '', render: rowData => this.showMessageButtonOn(rowData)},
					{ title: ETVConstants.trans("message_off"), field: '', render: rowData => this.showMessageButtonOff(rowData)},
					{ title: ETVConstants.trans("reboot"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,false)}><RotateLeftIcon/></IconButton>},
					{ title: ETVConstants.trans("restart_app"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,true)}><RotateLeftIcon/></IconButton>},
					{ title: ETVConstants.trans("screen_shot"), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.screenShot(rowData.id, rowData.screen_ratio)}><ScreenShotIcon/></IconButton>},
				];
			}else{
				columnsData= [
					{ title: ETVConstants.trans("last_connect"), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
					{ title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>)  },
					{ title: ETVConstants.trans("location"), field: 'location_name'},
					{ title: ETVConstants.trans("message_on"), field: '', render: rowData => this.showMessageButtonOn(rowData)},
					{ title: ETVConstants.trans("message_off"), field: '', render: rowData => this.showMessageButtonOff(rowData)},
					{ title: ETVConstants.trans("reboot"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,false)}><RotateLeftIcon/></IconButton>},
					{ title: ETVConstants.trans("restart_app"), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.restartDevice(rowData.id,true)}><RotateLeftIcon/></IconButton>},
				];
			}
		}

      	const tabletitle = <Typography variant="h6">{ETVConstants.trans("screens")}</Typography>;

      	return(
			<div>
				{this.state.groups && this.state.groups.length>0?
					<div style={{marginLeft:5,width:600}}>
						<BasicTable2 data={this.state.groups} columns={columnsDataScreenGroups} title={tableTitleScreenGroups} pageSize={5} allow_search={false} />
					</div>
					:
					<span/>
				}
				<div style={{marginLeft:5, marginTop:20}}>
	          	<BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={20} allow_search={true} />
			</div>

			<Dialog
				open={this.state.window==='screenshot_div'}
				maxWidth='xs'
				fullWidth
				onClose={() => {} }
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Screenshot</DialogTitle>
				<DialogContent>
						{!this.state.screenshot_url?<span/>:
						<Box 
							sx={{
								width: '100%',     // Box takes full width of its container
								maxWidth: '400px',  // Limit the maximum width (optional)
								//aspectRatio: '16/9',  // Maintains the aspect ratio of the image (16:9 in this case)
								overflow: 'hidden',  // Ensures the image doesn't overflow the box
							}}							
						>
						<img
							src={this.state.screenshot_url} 
							style={{
								width: '100%',        // Image takes full width of the Box
								height: '100%',       // Image takes full height of the Box
								objectFit: 'cover',   // Ensures the image covers the box and maintains its aspect ratio				  
							}} />
						</Box>
						}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({window:'',aborted:true})} color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='message_div'}
				maxWidth='xs'
				fullWidth={true}
				onClose={() => this.setState({window:'',aborted:true})  }
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
				<DialogContent>
						<Typography variant='body1'>{this.state.info_message}</Typography>
						<div style={{marginTop:10}}>
							{this.state.info_message_spinning?<CircularProgress />:<span/>}
						</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({window:'',aborted:true}) } color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='emergency_message_div'}
				maxWidth='sm'
				fullWidth={true}
				onClose={() => {}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{ETVConstants.trans("emergency_message")}</DialogTitle>
				<DialogContent>
					<br/>
					<TextField multiline minRows={5} maxRows={5} variant="outlined" fullWidth label={ETVConstants.trans("emergency_message")} value={this.state.description} onChange={(e)=>{this.setState({emergency_message:e.target.value})}}/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.showWindow('emergency_message_div',false)} color="primary" size="small">{ETVConstants.trans("close")}</Button>
					<Button disabled={!this.state.emergency_message || this.state.emergency_message.trim().length===0} size="small" startIcon={<Send/>} onClick={() => this.submitEmergencyMessageA()} variant='contained' color="primary">{ETVConstants.trans("submit")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='file_upload_div'}
				onClose={()=>{}}
				maxWidth='xs'
				fullWidth={true}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{ETVConstants.trans("file_upload")}</DialogTitle>
				<DialogContent>
					<FileUploadAdmin user_id={this.props.user_id} device_id={this.state.selected_device_id} />
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.setState({window:''})}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>
	   	</div>
		);
	}
}

export default RemoteDeviceMgmt
