import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Paper from '@mui/material/Paper';
import SelectDeviceDropDown from './SelectDeviceDropDown';
import * as ETVConstants from '../ETVConstants';
import { Upload } from '@mui/icons-material';

class FloorDirectory extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
      this.deviceSelected = this.deviceSelected.bind(this);
      this.devicesSelected = this.devicesSelected.bind(this);
    }

    state = {
      isLoaded:false,
      user_access:[],
      selected_screens: [],
      directoryElements:[],
      edit_id:'',
      table_edited:false
    }

    loadData( deviceID) {
      var url = ETVConstants.getServerBase()+"/directory?action=get_directory&device_id="+deviceID;
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
            var elems = this.createLocalElements(result.directory);
            this.setState({directoryElements:elems,table_edited:false});
          },
          (error) => {
            this.showInfoMessage(ETVConstants.trans("error"),error);
          }
        )
    }

    createLocalElements( serverElems) {
      var res = [];
      for(var i=0; i<serverElems.length; i++) {
        var serverE = serverElems[i];
        serverE.id = this.createID();
        serverE.position = i;
        serverE.has_error = false;
        res.push(serverE);
      }
      return res;
    }

    deviceSelected( deviceObj) {
      if(!deviceObj) return;
      var deviceID = deviceObj.id;
      //console.log("deviceID="+deviceObj.id+" selected");
      this.setState({selected_screens:[deviceID]});
      this.loadData(deviceObj.id);
    }

    devicesSelected( deviceIDs) {
      var firstID = deviceIDs[0];
      this.setState({selected_screens:deviceIDs});
      this.loadData(firstID);
    }

    selectedScreens(screenIDs) {
      //console.log("selected screens="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    changeFloor(id,value) {
      //console.log("elemToChange="+id);
      var elems = this.state.directoryElements;
      for(var i=0; i<elems.length; i++) {
        if(elems[i].id===id) {
          elems[i].floor=value;
        }
      }
      this.setState({directoryElements:elems,table_edited:true});
    }

    existsValueInFloors( value) {
      return this.state.floor_keys.includes(value);
    }

    changeDesc(id,value) {
      var elems = this.state.directoryElements;
      for(var i=0; i<elems.length; i++) {
        if(elems[i].id===id) {
          elems[i].info=value;
        }
      }
      this.setState({directoryElements:elems,table_edited:true});
    }

    deleteElement(id) {
      var existingElems = this.state.directoryElements;
      var newElements = [];
      var counter = 0;
      for(var i=0; i<existingElems.length; i++) {
        var checkingElem = existingElems[i];
        if(checkingElem.id!==id) {
          checkingElem.position=counter;
          newElements.push(checkingElem);
          counter++;
        }
      }
      this.setState({directoryElements:newElements,table_edited:true});
    }

    checkInputs(id) {
      //console.log("checkInputs of id="+id);
      var allFloors = [];
      var whichElem;
      for(var i=0; i<this.state.directoryElements.length; i++) {
        if(this.state.directoryElements[i].id===id) {
          whichElem = this.state.directoryElements[i];
        }else{
          allFloors.push(this.state.directoryElements[i].floor);
        }
      }
      //console.log("allFloors="+allFloors+" whichFloor="+whichElem.floor);
      return !allFloors.includes(whichElem.floor);
    }

    switchEditing( id) {
      //console.log("switch editing="+id);
      if(this.state.edit_id===id) {
        if (this.checkInputs(id)) {
          this.setState({edit_id:''});
          this.removeError(id);
        }else{
          this.setError(id,ETVConstants.trans("already_exists"));
          //wrong inputs (floor already exists)
        }
      }else{
        this.setState({edit_id:id});
      }
    }

    setError(id, val) {
      var res = [];
      for(var i=0; i<this.state.directoryElements.length; i++) {
        var elem = this.state.directoryElements[i];
        if(elem.id===id) {
          elem.has_error = true;
          elem.error_info = val;
        }
        res.push(elem);
      }
      this.setState({directoryElements:res});
    }

    removeError(id) {
      var res = [];
      for(var i=0; i<this.state.directoryElements.length; i++) {
        var elem = this.state.directoryElements[i];
        if(elem.id===id) {
          elem.has_error = false;
          elem.error_info = "";
        }
        res.push(elem);
      }
      this.setState({directoryElements:res});
    }

    submitElements() {
      var elems = this.state.directoryElements;
      var url = ETVConstants.getServerBase()+"/directory?action=save";
      for(var i=0; i<elems.length; i++) {
        url+="&position."+(i+1)+".floor="+encodeURIComponent(elems[i].floor)+"&position."+(i+1)+".desc="+encodeURIComponent(elems[i].info);
      }
      for(var k=0; k<this.state.selected_screens.length; k++) {
        url+="&device_id="+this.state.selected_screens[k];
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            if(result.result) {
              this.showInfoMessage(ETVConstants.trans("success"),"saved");
              this.setState({isLoaded:false,table_edited:false});
            }else{
              this.showInfoMessage(ETVConstants.trans("error"),"failed");
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage(ETVConstants.trans("error"),error);
          }
        )
    }

    guessNextFloorNr() {
      var len = this.state.directoryElements.length;
      if(len===0) return "1";
      var lastFloor = this.state.directoryElements[len-1].floor;
      try{
        var next = parseInt(lastFloor);
        if(!isNaN(next)) {
          return next+1;
        }
      }catch(error) {
        //console.log("could not parse "+lastFloor);
      };
      if(lastFloor.startsWith("B2")) return "B1";
      if(lastFloor.startsWith("B1")) return "L";
      if(lastFloor.startsWith("L")) return "1";
      return "0";
    }

    createID() {
      return Math.random()+"_"+Math.random();
    }

    addDirectoryRow() {
      var elem = {};
      elem.id = this.createID();
      elem.position = this.state.directoryElements.length;
      elem.floor = this.guessNextFloorNr();
      elem.info = "";
      elem.has_error=false;
      elem.error_info="";
      var newElems = this.state.directoryElements;
      newElems.push(elem);
      this.setState({directoryElements:newElems,edit_id:elem.id});
    }

    canAddRow() {
      var elems = this.state.directoryElements;
      for(var i=0; i<elems.length; i++) {
        if(this.state.edit_id!=='' || !elems[i].floor || elems[i].floor.length===0 ) {
          return false;
        }
      }
      return true;
    }

    printDirectory(elems) {
      return(
        <div>
          <TableContainer component={Paper}>
            <Table>
                <TableHead>
                  <TableCell width='10%'>Floor Number</TableCell>
                  <TableCell width='55%'>Description</TableCell>
                  <TableCell width='2.5%'></TableCell>
                  <TableCell width='2.5%'></TableCell>
                </TableHead>
                <TableBody>
                  {elems.map( item => {return this.printDirectoryElement(item)})}
                </TableBody>
                <TableFooter>
                </TableFooter>
            </Table>
          </TableContainer>
          <Button style={{marginTop:5}} disabled={!this.canAddRow()} color='primary' size='small' variant='contained' onClick={()=>this.addDirectoryRow()}>{ETVConstants.trans("add_entry")}</Button>
        </div>
      )
    }

    printDirectoryElement(elem) {
      var iamEditing = (elem.id===this.state.edit_id);
      var otherIsEditing = this.state.edit_id!=='';
      return(
        <TableRow key={"dir_pos_"+elem.position}>
            <TableCell width="10%"><TextField fullWidth={true} error={elem.has_error} helperText={elem.error_info} disabled={!iamEditing} value={elem.floor} onChange={e=>this.changeFloor(elem.id,e.target.value)}/></TableCell>
            <TableCell width="55%"><TextField fullWidth={true} disabled={!iamEditing} value={elem.info} onChange={e=>this.changeDesc(elem.id,e.target.value)}/></TableCell>
            <TableCell width="2.5%"><IconButton color='primary' disabled={otherIsEditing && !iamEditing} onClick={()=>{this.switchEditing(elem.id)}}> {iamEditing?<DoneIcon/>:<EditIcon/>}</IconButton></TableCell>
            <TableCell width="2.5%"><IconButton color='error' disabled={otherIsEditing && !iamEditing} onClick={()=>{this.deleteElement(elem.id)}}> {iamEditing?<span/>:<DeleteIcon/>}</IconButton></TableCell>
        </TableRow>
      )
    }

    render() {
      return (
        <div style={{width:'700px'}}>
          <Card>
            <CardContent>
                <Typography variant="h5">
                    {ETVConstants.trans("floor_directory")}
                </Typography>
                <div style={{marginTop:20}} />
                <Typography variant="body2">
                    {ETVConstants.trans("floor_directory_explanation")}
                </Typography>
                <Divider style={{marginTop:20}} />
                <SelectDeviceDropDown user_id={this.props.user_id} deviceSelected={this.deviceSelected} devicesSelected={this.devicesSelected} />
                <Divider style={{marginTop:20}} />
                {this.state.selected_screens.length>0?this.printDirectory(this.state.directoryElements):<span/>}
                <Button style={{marginTop:20}} variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
            </CardContent>
            <CardActions>
                <Button disabled={this.state.edit_id!=='' || !this.state.table_edited} startIcon={<Upload/>} color="primary" variant='contained' onClick={() => this.submitElements()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
          </div>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default FloorDirectory;
