import React from 'react';
import Paper from '@mui/material/Paper';
import {InputLabel,MenuItem,FormControl,Select} from '@mui/material';
import * as ETVConstants from '../ETVConstants';

class DefaultColorFontsChoices extends React.Component {

    state={
      floor_number_font:'Roboto-Regular.ttf',
      floor_number_color:'#FFC750'
    }

    componentDidMount() {
      this.loadConfig();
    }

    loadConfig() {
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=get_config&device_id="+this.props.device_id;
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
            //console.log("xxcolor="+result.floor_number_color);
						this.setState({floor_number_font:result.floor_number_font,floor_number_color:result.floor_number_color});
          },
          (error) => {
            console.log("error="+error);
          }
        )
    }

    changeTextColor(color) {
      this.setState({floor_number_color:color});
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=update_config_property&device_id="+this.props.device_id+"&key=floor_number_color&value="+encodeURIComponent(color);
      //console.log(url);
      ETVConstants.sendCMDToServer(url);
      this.props.onChange();
    }

    changeTextFont(font) {
      this.setState({floor_number_font:font});
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=update_config_property&device_id="+this.props.device_id+"&key=floor_number_font&value="+font;
      //console.log(url);
      ETVConstants.sendCMDToServer(url);
      this.props.onChange();
    }

    render() {
      return (
        <Paper elevation={5} style={{paddingTop:10, paddingLeft:10, marginTop:10}}>
          <div style={{marginTop:10,width:300}}>
            <InputLabel>{ETVConstants.trans("default_text_color")}</InputLabel>
            <input style={{marginTop:10}} type="color" value={this.state.floor_number_color} onChange={(e) => {this.changeTextColor(e.target.value)}} />
          </div>
          <FormControl style={{marginTop:20,marginBottom:20}}>
               <InputLabel>{ETVConstants.trans("default_font")}</InputLabel>
               <Select
                 label={ETVConstants.trans("default_font")}
                 size="small"
                 id='floor_number_font'
                 value={this.state.floor_number_font}
                 style={{ width: 300}}
                 onChange={(e)=>{this.changeTextFont(e.target.value)}}>
                  <MenuItem value='Roboto-Bold.ttf'>Roboto Bold</MenuItem>
                  <MenuItem value='Roboto-BoldCondensed.ttf'>Roboto Bold Condensed</MenuItem>
                  <MenuItem value='Roboto-Medium.ttf'>Roboto Medium</MenuItem>
                  <MenuItem value='Roboto-Regular.ttf'>Roboto Regular</MenuItem>
                  <MenuItem value='Roboto-Light.ttf'>Roboto Light</MenuItem>
                  <MenuItem value='Roboto-Thin.ttf'>Roboto Thin</MenuItem>
                  <MenuItem value='Amazonas.ttf'>Amazonas</MenuItem>
                  <MenuItem value='Audiowide-Regular.ttf'>Audiowide-Regular</MenuItem>
                  <MenuItem value='Aurach_Heavy.ttf'>Aurach Heavy</MenuItem>
                  <MenuItem value='bitcrusher_condensed_bd.ttf'>BitCrusher Condensed</MenuItem>
                  <MenuItem value='Biysk.ttf'>Biysk</MenuItem>
                  <MenuItem value='Closeness-Bold.ttf'>Closeness-Bold</MenuItem>
                  <MenuItem value='ChakraPetch-Semibold.ttf'>ChakraPetch Semibold</MenuItem>
                  <MenuItem value='D-DIN-Bold.ttf'>D-DIN-Bold</MenuItem>
                  <MenuItem value='Dited.otf'>Dited</MenuItem>
                  <MenuItem value='Exo2-Semibold.ttf'>Exo2 Semibold</MenuItem>
                  <MenuItem value='hankenround-Regular.ttf'>Hankenround</MenuItem>
                  <MenuItem value='Lato-Bold.ttf'>Lato Bold</MenuItem>
                  <MenuItem value='Lato-Regular.ttf'>Lato Regular</MenuItem>
                  <MenuItem value='Lato-Thin.ttf'>Lato Thin</MenuItem>
                  <MenuItem value='LCDPHONE.TTF'>LCD Dotted</MenuItem>
                  <MenuItem value='MYRIADPRO-BOLD.OTF'>MYRIADPRO Bold</MenuItem>
                  <MenuItem value='MYRIADPRO-REGULAR.OTF'>MYRIADPRO Regular</MenuItem>
                  <MenuItem value='Mercy.ttf'>Mercy</MenuItem>
                  <MenuItem value='neuropol.ttf'>Neuropol</MenuItem>
                  <MenuItem value='NotoSans-Bold.ttf'>NotoSans Bold</MenuItem>
                  <MenuItem value='NotoSans-Regular.ttf'>NotoSans Regular</MenuItem>
                  <MenuItem value='NotoSans-Variable.ttf'>NotoSans Variable</MenuItem>
                  <MenuItem value='Nunito-SemiBold.ttf'>Nunito Semibold</MenuItem>
                  <MenuItem value='Orbitron-SemiBold.ttf'>Orbitron Semibold</MenuItem>
                  <MenuItem value='OpenSans-Light.ttf'>OpenSans Light (APK91+)</MenuItem>
                  <MenuItem value='OpenSans-Medium.ttf'>OpenSans Medium (APK91+)</MenuItem>
                  <MenuItem value='OpenSans-Regular.ttf'>OpenSans Regular (APK91+)</MenuItem>
                  <MenuItem value='Perfograma.otf'>Perfograma</MenuItem>
                  <MenuItem value='SIMPLIFICA.ttf'>Simplifica</MenuItem>
                  <MenuItem value='trs-million.ttf'>TRS-million</MenuItem>
                  <MenuItem value='led2.ttf'>LED 2</MenuItem>
                  <MenuItem value='led3.ttf'>LED 3</MenuItem>
                  <MenuItem value='led4.ttf'>LED 4</MenuItem>
                  <MenuItem value='led5.ttf'>LED 5</MenuItem>
                  <MenuItem value='led6.ttf'>LED 6</MenuItem>
                  <MenuItem value='led7.ttf'>LED 7</MenuItem>
                  <MenuItem value='led8.ttf'>LED 8</MenuItem>
                  <MenuItem value='led9.ttf'>LED 9</MenuItem>
               </Select>
          </FormControl>
        </Paper>
      )
    }
}

export default DefaultColorFontsChoices;
