import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage} from 'react-intl';
import {loadStripe} from '@stripe/stripe-js';

// for production mode
const PROD_MODE = true;

const stripePromise =  PROD_MODE?
	loadStripe('pk_live_51DDoCYLECPh9Zj2ox5cedGc2Xc6uZXe0wEZzSOc5foqDCqoORN2IefvOXyq880dA2FDuWSmbCGRVIo8wEeUEOgd7007SqX3koA'):
	loadStripe('pk_test_51DDoCYLECPh9Zj2oloWnt7avLWHOaua3vN8fXMm88ZRnGjmKzArdBXVJY9VOc3TnMAFu9JxYVsqheDJfKsBbmFo700aDlE8w8f');


export default class AccountUpgrade extends React.Component {

    state = {
				checkoutSessionID: undefined,
				showCheckout: false,
				isLoaded: false,
				cur_status:undefined,
				until_date:'',
				is_trial:'',
				customer_id:'',
				showUpgradeDialog: false,
				upgradePlan: undefined,
				isNewSubscripe: false,
    }

		trans( msgID) {
			return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
		}

		getPricePlan(which) {
			if(PROD_MODE===false) {
				//console.log("testing price plan");
				if(which==='basic') {
					return "price_1IH7y7LECPh9Zj2oYlBcIr8h";

				}else if(which==='premium') {
					return "price_1IH7xgLECPh9Zj2olJ1b9km6";

				}else if(which==='enterprise') {
					return "price_1IJa23LECPh9Zj2oLd5mSber";
				}

			}else{
				//console.log("production price plan");
				if(which==='basic') {
					return "price_1IGLwJLECPh9Zj2ocUGro3co";

				}else if(which==='premium') {
					return "price_1IH7xALECPh9Zj2oD4iajsL3";

				}else if(which==='enterprise') {
					return "price_1IGLwzLECPh9Zj2oKqbDCr4g";
				}
			}
		}

		componentDidMount() {
			this.loadStatus();
		}

		componentDidUpdate(prevProps,prevState) {
			if(!this.state.isLoaded) {
				this.loadStatus();
			}
		}

		loadStatus() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = 'https://elevatortvbackend.appspot.com/membershp?action=get_status&user_id='+this.props.user_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((json) => {
					var isTrial = json["is_trial"];
					var userStatus = json["status"];
					this.setState({isLoaded:true, cur_status:userStatus,until_date:json["until_date"],is_trial:isTrial,customer_id:json["customer_id"]});
			})
			.catch((error) => {
				//console.log(error);
			});
		}

		upgradeSubscription( planID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = 'https://elevatortvbackend.appspot.com/payment?action=upgrade_price_plan&user_id='+this.props.user_id+"&price_id="+planID;
			//console.log(url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				  //console.log(JSON.stringify(responseJson));
					this.setState({showUpgradeDialog: false, upgradePlan: undefined,isLoaded:false});
					if(responseJson["result"]===false) {
						this.showInfoMessage(this.trans("transaction_failed"),responseJson["reason"]);
						return;
					}else{
						this.showInfoMessage(this.trans("success"),this.trans("please_logout_to_make_changes_effective"));
					}
					//console.log(JSON.stringify(json));
			})
			.catch((error) => {
				//console.log(error);
			});
		}


		createSubscription = async ( priceID ) => {
				this.showInfoMessage(this.trans("re_directing"),this.trans("re_directing_to_payment_provider"));
				this.setState({showUpgradeDialog: false, upgradePlan: undefined,});
				var successPage = encodeURIComponent(window.location.href+"?payment_info=success");
				var failPage = encodeURIComponent(window.location.href+"?payment_info=fail");
				var url = 'https://elevatortvbackend.appspot.com/payment?action=create_checkout_session&price_id='+priceID+'&user_id='+this.props.user_id+"&success_page="+successPage+"&fail_page="+failPage;
				var accessToken = localStorage.getItem("etv.access_token");
				var reqUserID = localStorage.getItem("etv.user_id");
				const stripe = await stripePromise;
				fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((responseJson) => {
						if(responseJson["result"]===false) {
							this.showInfoMessage("Transaction failed",responseJson["reason"]);
							return;
						}
						var checkoutSessionID = responseJson["checkoutSessionId"];
	        	this.setState({checkoutSessionID:checkoutSessionID});
						const result = stripe.redirectToCheckout({
							sessionId: checkoutSessionID,
						});
						if (result.error) {
							this.showInfoMessage(this.trans("error"),result.error);
						}
				})
				.catch((error) => {
					this.showInfoMessage(this.trans("error"),error);
				});
		}

		manageSubscription = async ( ) => {
				var accessToken = localStorage.getItem("etv.access_token");
				var reqUserID = localStorage.getItem("etv.user_id");
				var url = "https://elevatortvbackend.appspot.com/payment?action=redirect_to_customer_portal&user_id="+this.props.user_id+"&access_token="+accessToken;
				window.location.href=url;
		}

		showWindow(divID, show) {
			this.setState({show_overlay:show});
			{ show?this.setState({window:divID}):this.setState({window:''}) };
		}

		showInfoMessage(title,text) {
			this.setState({info_message_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		isForever( date) {
			//console.log("isForever, date="+date);
			var d = new Date(date);
			var now = new Date();
			var msDiff = d-now;
			var days = msDiff/1000/3600/24;
			var years = days/365;
			//console.log("msDiff="+msDiff+" days="+days+" years="+years);
			return years>=99.0;
		}

		getButton(isForever,isTrial,curStatus,status) {
			if (curStatus==='superadmin' || curStatus==='oem' || (curStatus!=='offline' && isForever) || (curStatus===status && !isTrial)  ) {
				return <span/>
			}
			var pricePlan = this.getPricePlan(status);
			var hasCustomerID = this.state.customer_id!==undefined && this.state.customer_id!==null && this.state.customer_id.length>0;
			var isSubscribe = !hasCustomerID || curStatus==='offline';
			if(isTrial) {
				return <Button size="large" variant='contained' color='primary' onClick={() => {this.setState({showUpgradeDialog:true, upgradePlan:pricePlan, isNewSubscripe:isSubscribe})}}>{this.trans("subscribe")}</Button>
			}
			return <Button size="large" variant='contained' color='primary' onClick={() => {this.setState({showUpgradeDialog:true, upgradePlan:pricePlan, isNewSubscripe:isSubscribe})}}>{isSubscribe?this.trans("subscribe"):this.trans("change_plan")}</Button>
		}

	  render() {
			const {stripe} = this.props;
			//console.log("xxxxxxxxxxx:"+(this.state.cur_status==="basic"));
			var basic = (this.state.cur_status==='basic');
			var offline = (this.state.cur_status==='offline');
			var premium = (this.state.cur_status==='premium');
			var enterprise = (this.state.cur_status==='enterprise');

			var isTrial = this.state.is_trial;
			var isForever = this.isForever(this.state.until_date);

			const selectedStyle={backgroundColor:'green', color:'white', justifyContent:'center'};
			const defaultStyle={backgroundColor:'lightgray', color:'#3e3e3e', justifyContent:'center'};

			var curStatus = this.state.cur_status;
			var hasCustomerID = this.state.customer_id!==undefined && this.state.customer_id!==null && this.state.customer_id.length>0;

			return (
					 (!this.state.isLoaded ?
						<span>{this.trans("loading")}</span>
						:
						<div>
							<div style={{display: 'flex'}}>
									<Card style={{width:220, margin:10}}>
										<CardMedia style={offline?selectedStyle:defaultStyle}>
											<Typography align='center' variant="h5" style={{justifyContent:'center'}}>Offline</Typography>
										</CardMedia>
											<CardContent>
												<Typography component={'span'} align='center'>
													<Typography align='center' variant='h6' style={{backgroundColor:'white',color:'green',marginBottom:30}}>
														{this.trans("always_free")}
													</Typography>
													<div>{this.trans("offline_operation")}</div>
													<div>{this.trans("usb_sync")}</div>
													<div>{this.trans("ftp_sync")}</div>
													<div>{this.trans("image_movie_html")}</div>
												</Typography>
											</CardContent>
										<CardActions style={{justifyContent: 'center'}}>
										</CardActions>
									</Card>

									<Card style={{width:220, margin:10}}>
										<CardMedia style={basic?selectedStyle:defaultStyle}>
											<Typography align='center' variant="h5">Basic</Typography>
										</CardMedia>
											<CardContent>
												<Typography component={'span'} align='center'>
												<Typography align='center' variant='h6' style={{backgroundColor:'white',color:'green',marginBottom:30}}>
													{this.trans("us_5_month_unit")}
												</Typography>
												<div><b>10GB</b>&nbsp;{this.trans("cloud_storage")}</div>
												<Divider style={{marginTop:10,marginBottom:10}}/>
												<div>{this.trans("30_display_layouts")}</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<Divider style={{marginTop:10,marginBottom:10}}/>
												<div>{this.trans("content_playlists")}</div>
												<div>{this.trans("image_movie_html")}</div>
												<div>{this.trans("qr_codes")}</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<div>&nbsp;</div>
												<Divider style={{marginTop:10,marginBottom:10}}/>
												<div>&nbsp;</div>
												<Divider style={{marginTop:10,marginBottom:10}}/>
												<div>&nbsp;</div>
												<Divider style={{marginTop:10,marginBottom:10}}/>
												<div>{this.trans("online_support")}<b>&nbsp;{this.trans("within_48h")}</b></div>
													{isTrial && basic?
														<div style={{marginTop:20}}>
															<Typography variant='body2' align='center' color='error'> {this.trans("trial_ends")}&nbsp; {this.state.until_date}</Typography><br/>
														</div>
														:
														<span/>
													}
												</Typography>
											</CardContent>
										<CardActions style={{justifyContent: 'center'}}>
											{this.getButton(isForever,isTrial,curStatus,'basic')}
										</CardActions>
									</Card>

									<Card style={{width:220, margin:10}}>
										<CardMedia style={premium?selectedStyle:defaultStyle}>
											<Typography align='center' variant="h5" style={{justifyContent:'center'}}>
													Premium
											</Typography>
										</CardMedia>
											<CardContent>
												<Typography component={'span'} align='center'>
													<Typography align='center' variant='h6' style={{backgroundColor:'white',color:'green',marginBottom:30}}>
														{this.trans("us_20_month_unit")}
													</Typography>
													<div><b>100GB</b>&nbsp;{this.trans("cloud_storage")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("30_display_layouts")}</div>
													<div>{this.trans("layout_designer")}</div>
													<div>{this.trans("remote_management")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("content_playlists")}</div>
													<div>{this.trans("image_movie_html")}</div>
													<div>{this.trans("qr_codes")}</div>
													<div>{this.trans("text_editor")}</div>
													<div>{this.trans("weather_information")}</div>
													<div>{this.trans("content_marketplace")}</div>
													<div>{this.trans("rss_feeds")}</div>
													<div>{this.trans("calendar_integration")}</div>
													<div>&nbsp;</div>
													<div>&nbsp;</div>
													<div>&nbsp;</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("2_factor_authentication")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("user_mgmt")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("online_support")}<b>&nbsp;{this.trans("within_24h")}</b></div>
													{isTrial && premium?
														<div style={{marginTop:20}}>
															<Typography variant='body2' align='center' color='error'>{this.trans("trial_ends")}&nbsp; {this.state.until_date}</Typography><br/>
														</div>
														:
														<span/>
													}
												</Typography>
											</CardContent>
										<CardActions style={{justifyContent: 'center'}}>
											{this.getButton(isForever,isTrial,curStatus,'premium')}
										</CardActions>
									</Card>

									<Card style={{width:220, margin:10}}>
										<CardMedia style={enterprise?selectedStyle:defaultStyle}>
											<Typography align='center' variant="h5" style={{justifyContent:'center'}}>
													Enterprise
											</Typography>
										</CardMedia>
											<CardContent>
												<Typography component={'span'} align='center'>
													<Typography align='center' variant='h6' style={{backgroundColor:'white',color:'green',marginBottom:30}}>
														{this.trans("us_35_month_unit")}
													</Typography>
													<div><b>1TB</b>&nbsp;{this.trans("cloud_storage")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("30_display_layouts")}</div>
													<div>{this.trans("layout_designer")}</div>
													<div>{this.trans("remote_management")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("content_playlists")}</div>
													<div>{this.trans("image_movie_html")}</div>
													<div>{this.trans("qr_codes")}</div>
													<div>{this.trans("text_editor")}</div>
													<div>{this.trans("weather_information")}</div>
													<div>{this.trans("content_marketplace")}</div>
													<div>{this.trans("rss_feeds")}</div>
													<div>{this.trans("calendar_integration")}</div>
													<div>{this.trans("distribute_vouchers")}</div>
													<div>{this.trans("customer_surveys")}</div>
													<div>{this.trans("content_statistics")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("2_factor_authentication")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("user_mgmt")}</div>
													<Divider style={{marginTop:10,marginBottom:10}}/>
													<div>{this.trans("online_support")}<b>&nbsp;{this.trans("within_12h")}</b></div>
													{isTrial && enterprise?
														<div style={{marginTop:20}}>
															<Typography component={'span'} variant='body2' align='center' color='error'>{this.trans("trial_ends")}&nbsp; {this.state.until_date}</Typography><br/>
														</div>
														:
														<span/>
													}
												</Typography>
											</CardContent>
										<CardActions style={{justifyContent: 'center'}}>
											{this.getButton(isForever,isTrial,curStatus,'enterprise')}
										</CardActions>
									</Card>
							</div>
							{ !isForever && (this.state.cur_status==='basic'|| this.state.cur_status==='premium'|| this.state.cur_status==='enterprise') ?
									<div>
										<div style={{marginTop:20}}>
											{hasCustomerID?<Button size="small" variant='contained' color='primary' onClick={() => {this.manageSubscription()}}>{this.trans("manage_subscription_payment_details")}</Button>:<span/>}
										</div>
										<Divider style={{marginTop:20}}/>
										<Typography component={'span'} variant="body2">{this.trans("note_payment")}</Typography>
									</div>
								:
								<span/>
							}

							<Dialog
								open={this.state.window==='message_div'}
								onClose={()=>{this.closeInfoMessage()}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										{this.state.info_message}
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.closeInfoMessage()}} color="primary">
										Close
									</Button>
								</DialogActions>
							</Dialog>

							<Dialog
								open={this.state.showUpgradeDialog}
								onClose={()=>{this.setState({showUpgradeDialog:false, upgradePlan: undefined,})}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{this.state.isNewSubscripe?"Create Subscription":"Change Subscription"}</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										{this.state.isNewSubscripe?
											"You will be forwared to our trusted payment gateway provider in order to create a new subscription."
											:
											"Are you sure to change your current subscription? The current subscription will be canceled and the new subscription will become effective."
										}
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.setState({showUpgradeDialog:false, upgradePlan:undefined,})}} color="primary">
										Cancel
									</Button>
									<Button onClick={()=>{this.state.isNewSubscripe?this.createSubscription(this.state.upgradePlan):this.upgradeSubscription(this.state.upgradePlan)}} variant="contained" color="primary" autoFocus>
										Agree
									</Button>
								</DialogActions>
							</Dialog>

						</div>
					)
	    );
	  }

}
