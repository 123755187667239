import React, {Overlay} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as ETVConstants from '../ETVConstants';

class DraginoConfig extends React.Component {

		constructor(props) {
      super(props);
    }

    state = {
      isLoaded: false,
			config_changed:false,
			DI1_status:'',
			DI1_status_desc:'',
			DI2_status:'',
			DI2_status_desc:'',
			AVI1_V:'',
			AVI1_V_desc:'',
			AVI1_V_error:'',
			AVI2_V:'',
			AVI2_V_desc:'',
			AVI2_V_error:'',
			ACI1_mA:'',
			ACI1_mA_desc:'',
			ACI1_mA_error:'',
			ACI2_mA:'',
			ACI2_mA_desc:'',
			ACI2_mA_error:''
    }

    componentDidMount() {
			this.loadDeviceConfig();
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadDeviceConfig();
			}
		}

		loadDeviceConfig() {
			if(!this.props.device_id) {
				return;
			}
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=get_config&device_id="+this.props.device_id;
			//console.log(url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							DI1_status:result["DI1_status"],
							DI1_status_desc:result["DI1_status_desc"],
							DI2_status:result["DI2_status"],
							DI2_status_desc:result["DI2_status_desc"],
							AVI1_V:result["AVI1_V"],
							AVI1_V_desc:result["AVI1_V_desc"],
							AVI2_V:result["AVI2_V"],
							AVI2_V_desc:result["AVI2_V_desc"],
							ACI1_mA:result["ACI1_mA"],
							ACI1_mA_desc:result["ACI1_mA_desc"],
							ACI2_mA:result["ACI2_mA"],
							ACI2_mA_desc:result["ACI2_mA_desc"],
							isLoaded:true,
							config_changed:false
						});
					},
					(error) => {
						//console.log(error);
					}
				)
		}

		sendCMDToServer(url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((responseJson) => {
					 this.setState({
							isLoaded: false
					 })
				})
				.catch((error) => {
					 console.error(error);
				});
    }

		createConfigTableRow(whichSignal, key, condition, value) {
			return(
				<TableRow key={key}>
						<TableCell align="left">{key}</TableCell>
						<TableCell align="left">
								<FormControl>
									 <InputLabel>Condition</InputLabel>
									 <Select
										 labelId="signal"
										 id="signal"
										 style={{ width: 50}}
										 value={{condition}}
										 onChange={(e)=>{this.setState({signal_:e.target.value})}}>
										 <MenuItem value='equal'>==</MenuItem>
										 <MenuItem value='less_equal'>&ge;</MenuItem>
										 <MenuItem value='greater_equal'>&le;</MenuItem>
									 </Select>
								 </FormControl>
						</TableCell>
						<TableCell align="left">{value}</TableCell>
				</TableRow>
			)
		}

		isNumeric(n) {
		  return !isNaN(parseFloat(n)) && isFinite(n);
		}

		isCorrectInput( val) {
			if(!val) return true;
			if (val.indexOf(">=")!==0 && val.indexOf("<=")!==0) return false;
			val = val.substring(val.indexOf("=")+1);
			if (!this.isNumeric(val)) return false;
			return true;
		}

		saveConfig() {
			var res = {};
			if(!this.isCorrectInput(this.state.AVI1_V)) {
				this.setState({AVI1_V_error:"must start with >= or <="});
				return;
			}else{
				this.setState({AVI1_V_error:''});
			}

			if(!this.isCorrectInput(this.state.AVI2_V)) {
				this.setState({AVI2_V_error:"must start with >= or <="});
				return;
			}else{
				this.setState({AVI2_V_error:''});
			}

			if(!this.isCorrectInput(this.state.ACI1_mA)) {
				this.setState({ACI1_mA_error:"must start with >= or <="});
				return;
			}else{
				this.setState({ACI1_mA_error:''});
			}

			if(!this.isCorrectInput(this.state.ACI2_mA)) {
				this.setState({ACI2_mA_error:"must start with >= or <="});
				return;
			}else{
				this.setState({ACI2_mA_error:''});
			}
			res["DI1_status"]=this.state.DI1_status;
			res["DI1_status_desc"]=this.state.DI1_status_desc;
			res["DI2_status"]=this.state.DI2_status;
			res["DI2_status_desc"]=this.state.DI2_status_desc;
			res["AVI1_V"]=this.state.AVI1_V;
			res["AVI1_V_desc"]=this.state.AVI1_V_desc;
			res["AVI2_V"]=this.state.AVI2_V;
			res["AVI2_V_desc"]=this.state.AVI2_V_desc;
			res["ACI1_mA"]=this.state.ACI1_mA;
			res["ACI1_mA_desc"]=this.state.ACI1_mA_desc;
			res["ACI2_mA"]=this.state.ACI2_mA;
			res["ACI2_mA_desc"]=this.state.ACI2_mA_desc;

			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=save_config&device_id="+encodeURIComponent(this.props.device_id)+"&config_json="+encodeURIComponent(JSON.stringify(res));
			//console.log(url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({config_changed:false});
					},
					(error) => {
						//console.log(error);
					}
				)
		}

    render() {
      return(
				<Card>
					<CardContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell alight="right">input</TableCell>
									<TableCell alight="right">{ETVConstants.trans("desc")}</TableCell>
									<TableCell alight="right">value</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
									<TableRow>
											<TableCell align="left">DI1_Status</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="DI1_status_desc" label={ETVConstants.trans("desc")}  value={this.state.DI1_status_desc} onChange={(e)=>{this.setState({DI1_status_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
														 <InputLabel>Status</InputLabel>
														 <Select
															 labelId="signal"
															 id="signal"
															 style={{ width: 150}}
															 value={this.state.DI1_status}
															 onChange={(e)=>{this.setState({DI1_status:e.target.value,config_changed:true})}}>
															 <MenuItem value='-'>------</MenuItem>
															 <MenuItem value='H'>H</MenuItem>
															 <MenuItem value='L'>L</MenuItem>
														 </Select>
													 </FormControl>
											</TableCell>
									</TableRow>
									<TableRow>
											<TableCell align="left">DI2_Status</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="DI2_status_desc" label={ETVConstants.trans("desc")}  value={this.state.DI2_status_desc} onChange={(e)=>{this.setState({DI2_status_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
														 <InputLabel>Status</InputLabel>
														 <Select
															 labelId="signal"
															 id="signal"
															 style={{ width: 150}}
															 value={this.state.DI2_status}
															 onChange={(e)=>{this.setState({DI2_status:e.target.value,config_changed:true})}}>
															 <MenuItem value='-'>------</MenuItem>
															 <MenuItem value='H'>H</MenuItem>
															 <MenuItem value='L'>L</MenuItem>
														 </Select>
													 </FormControl>
											</TableCell>
									</TableRow>
									<TableRow>
											<TableCell align="left">AVI1_V</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="AVI1_V_desc" label={ETVConstants.trans("desc")}  value={this.state.AVI1_V_desc} onChange={(e)=>{this.setState({AVI1_V_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
													<TextField
															error={ (this.state.AVI1_V_error && this.state.AVI1_V_error.length>0) }
			 												label="Input Voltage"
			 												id="outlined-start-adornment"
			 												sx={{ m: 1, width: '25ch' }}
			 												InputProps={{
				 												endAdornment: <InputAdornment position="end">V</InputAdornment>,
			 												}}
															helperText={'use >= or <='}
															value={this.state.AVI1_V}
															onChange={(e)=>{this.setState({AVI1_V:e.target.value,config_changed:true})}}
		 												/>
													 </FormControl>
											</TableCell>
									</TableRow>
									<TableRow>
											<TableCell align="left">AVI2_V</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="AVI2_V_desc" label={ETVConstants.trans("desc")}  value={this.state.AVI2_V_desc} onChange={(e)=>{this.setState({AVI2_V_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
													<TextField
															error={this.state.AVI2_V_error && this.state.AVI2_V_error.length>0}
			 												label="Input Voltage (V)"
			 												id="outlined-start-adornment"
			 												sx={{ m: 1, width: '25ch' }}
			 												InputProps={{
				 												endAdornment: <InputAdornment position="end">V</InputAdornment>,
			 												}}
															helperText={'use >= or <='}
															value={this.state.AVI2_V}
															onChange={(e)=>{this.setState({AVI2_V:e.target.value,config_changed:true})}}
		 												/>
													 </FormControl>
											</TableCell>
									</TableRow>
									<TableRow>
											<TableCell align="left">ACI1_mA</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="ACI1_mA_desc" label={ETVConstants.trans("desc")}  value={this.state.ACI1_mA_desc} onChange={(e)=>{this.setState({ACI1_mA_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
													<TextField
															error={this.state.ACI1_mA_error && this.state.ACI1_mA_error.length>0}
			 												label="Input Current (mA)"
			 												id="outlined-start-adornment"
			 												sx={{ m: 1, width: '25ch' }}
			 												InputProps={{
				 												endAdornment: <InputAdornment position="end">mA</InputAdornment>,
			 												}}
															helperText={'use >= or <='}
															value={this.state.ACI1_mA}
															onChange={(e)=>{this.setState({ACI1_mA:e.target.value,config_changed:true})}}
		 												/>
													 </FormControl>
											</TableCell>
									</TableRow>
									<TableRow>
											<TableCell align="left">ACI2_mA</TableCell>
											<TableCell><TextField autoComplete='off' fullWidth={true} size="small" id="ACI2_mA_desc" label={ETVConstants.trans("desc")}  value={this.state.ACI2_mA_desc} onChange={(e)=>{this.setState({ACI2_mA_desc:e.target.value})}} /></TableCell>
											<TableCell align="left">
													<FormControl>
													<TextField
															error={this.state.ACI2_mA_error && this.state.ACI2_mA_error.length>0}
			 												label="Input Current (mA)"
			 												id="outlined-start-adornment"
			 												sx={{ m: 1, width: '25ch' }}
			 												InputProps={{
				 												endAdornment: <InputAdornment position="end">mA</InputAdornment>,
			 												}}
															helperText={'use >= or <='}
															value={this.state.ACI2_mA}
															onChange={(e)=>{this.setState({ACI2_mA:e.target.value,config_changed:true})}}
		 												/>
													 </FormControl>
											</TableCell>
									</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					</CardContent>
					<CardActions>
						<Button disabled={!this.state.config_changed} size="small" variant="contained" color="secondary" onClick={() => this.saveConfig()}>{ETVConstants.trans("save")}</Button>
					</CardActions>
				</Card>
			)
	}
}

export default DraginoConfig
