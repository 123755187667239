import React from 'react';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Delete from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import * as ETVConstants from '../ETVConstants';
import { Add, Save } from '@mui/icons-material';

class JobDefinition extends React.Component {

    state = {
      	isLoaded: false,
      	list: [],
		all_skills:[],
		edit_job_def_id:null,
		edit_duration:60,
		edit_desc:'',
		edit_skills:[],
    }

    componentDidMount() {
		this.loadAvailableSkills();
    }

	componentDidUpdate() {
		if(!this.state.isLoaded) {
			this.loadJobDefinitions();
		}
	}

	loadAvailableSkills() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/useradmin?action=get_skills&user_id="+this.props.user_id;
		fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
				//console.log("userID="+this.props.user_id+" skills available="+JSON.stringify(result));
				this.setState({
					all_skills: result.skills,
				});
			},
			(error) => {
				this.setState({
					error
				});
			}
		)
	}

	loadJobDefinitions() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/job_definition?action=list&user_id="+this.props.user_id;
		//console.log("url="+url);
      	fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+JSON.stringify(result.list));
            this.setState({
              list: result.list,
							isLoaded:true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

	insertNewJobDefinition() {
		var url = ETVConstants.getServerBase()+"/job_definition?action=create_job_definition&desc="+encodeURIComponent(this.state.new_desc);
		url +="&duration_min="+this.state.new_duration;
		url +="&owner_id="+this.props.user_id;
		for(var i=0; i<this.state.edit_skills.length; i++) {
			url += "&required_skills="+encodeURIComponent(this.state.edit_skills[i]);
		}
		//console.log("url="+url);
		this.sendCMDToServerLocal(url);
		this.showWindow('add_new_job_definition',false);
	}

	updateJobDefinition() {
		//console.log("-------> update job def <--------------");
		var url = ETVConstants.getServerBase()+"/job_definition?action=update_job_definition&desc="+encodeURIComponent(this.state.edit_desc);
		url +="&duration_min="+this.state.edit_duration;
		url +="&job_def_id="+this.state.edit_job_def_id;
		for(var i=0; i<this.state.edit_skills.length; i++) {
			url += "&required_skills="+encodeURIComponent(this.state.edit_skills[i]);
		}
		//console.log("url="+url);
		this.sendCMDToServerLocal(url);
		this.showWindow('edit_job_definition',false);
		//this.setState({isLoaded:false,window:''});
	}

    deleteJobDefID( jobDefID) {
		var url = ETVConstants.getServerBase()+"/job_definition?action=delete_job_definition&job_def_id="+jobDefID;
		this.sendCMDToServerLocal(url);
  	}

	showWindow(divID, show) {
		show?this.setState({window:divID}):this.setState({window:''});
	}

	showInfoMessage(title,text) {
		this.setState({info_title:title,info_message:text});
		this.showWindow('message_div',true);
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}

	showAddJobDefinition() {
		this.setState({edit_skills:[]});
		this.showWindow('add_job_definition',true);
	}

	sendCMDToServerLocal(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
			if(result["result"]) {
				//console.log("ok");
			}else{
				console.log("result not ok="+JSON.stringify(result));
			}
					this.setState({isLoaded:false});
			},
			(error) => {
			console.log("error="+error);
			}
		)
	}


////////////////////////////////////////////////////////////////////////////////
// requried skills
////////////////////////////////////////////////////////////////////////////////

	printRequiredSkills(skills) {
		return skills.map( (value) => <Chip size='small' color="primary" key={value} label={value} style={{marginLeft:1,marginTop:1}}/> );
	}

	handleCheckBoxChange( skill) {
		var curSkills = this.state.edit_skills;
		if(curSkills.includes(skill)) {
			curSkills.splice(curSkills.indexOf(skill),1);
		}else{
			curSkills.push(skill);
		}
		this.setState({edit_skills:curSkills});
	}

	printAvailableSkills() {
		if(!this.state.all_skills) return <span/>;
		return (
			this.state.all_skills.map( (item,index)=> {return <FormControlLabel id={index} key={index} control={<Checkbox checked={this.isSelectedSkill(item)} onClick={()=>this.handleCheckBoxChange(item)}>{item}</Checkbox>} name={item} label={item} />})
		)
	}

	isSelectedSkill( skill) {
		return this.state.edit_skills && this.state.edit_skills.includes(skill);
	}


////////////////////////////////////////////////////////////////////////////////
// edit
////////////////////////////////////////////////////////////////////////////////

		getJobDefinition(jobDefID) {
			for(var i=0; i<this.state.list.length; i++) {
				if(this.state.list[i].job_def_id===jobDefID) return this.state.list[i];
			}
			return null;
		}

		editJobDefinition(jobDefID) {
				var jobDef = this.getJobDefinition(jobDefID);
				this.setState({edit_job_def_id:jobDefID,edit_duration:jobDef.duration_min,edit_desc:jobDef.desc,edit_skills:jobDef.required_skills});
				this.showWindow("edit_job_definition",true);
		}


    render() {
      if(!this.state.isLoaded) {
        return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
      }

      const columnsData= [
			{ title: ETVConstants.trans("description"), field: 'desc', render: rowData => <div style={{width:250}}>{rowData.desc}</div>},
			{ title: ETVConstants.trans("duration_minutes"), field: 'duration_min', render: rowData => <div style={{width:10}}>{rowData.duration_min}</div>},
			{ title: ETVConstants.trans("required_skills"), field: '', render: rowData => <div style={{width:250}}>{this.printRequiredSkills(rowData.required_skills)}</div>},
			{ title: ETVConstants.trans("edit"), field: 'actions', render: rowData => <div style={{width:5}}><IconButton size='small' label='edit' variant='contained' color='primary' onClick={()=>this.editJobDefinition(rowData.job_def_id)}><EditIcon/></IconButton></div>},
			{ title: ETVConstants.trans("delete"), field: 'delete', render: rowData => <div style={{width:5}}><IconButton size='small' label='delete' variant='contained' color='error' onClick={()=>this.deleteJobDefID(rowData.job_def_id)}><Delete/></IconButton></div>},
        ];

      const tabletitle = <Typography variant='h6'>{ETVConstants.trans("job_definition")}</Typography>;

      return(
        <div style={{width:950}}>
			<BasicTable2 data={this.state.list} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />

			<Dialog
				open={this.state.window==='add_job_definition'}
				maxWidth='xs'
				fullWidth={true}
				onClose={()=>{}}>
				<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("add_new_job_definition")}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" component={'span'}>
									<TextField style={{marginTop:10}} fullWidth={true} size="small" id="desc" label={ETVConstants.trans("Description")} value={this.state.new_desc} onChange={(e)=>{this.setState({new_desc:e.target.value})}} />
									<TextField style={{marginTop:10}} fullWidth={false} type='number' size="small" id="duration" label={ETVConstants.trans("job_duration_minutes")} value={this.state.new_duration} onChange={(e)=>{this.setState({new_duration:e.target.value})}} />
									<Typography variant='body2' style={{marginTop:20}}>{ETVConstants.trans("required_skills")}</Typography>
										<div>
											{this.printAvailableSkills()}
										</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size='small' color="primary" onClick={() => this.showWindow('add_job_definition',false)}>{ETVConstants.trans("cancel")}</Button>
					<Button size='small' variant="contained" color="primary" startIcon={<Add/>} disabled={!this.state.new_desc || !this.state.new_duration} onClick={()=>{this.insertNewJobDefinition()}}>{ETVConstants.trans("insert")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='edit_job_definition'}
				maxWidth='xs'
				fullWidth={true}
				onClose={()=>{}}>
				<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("edit_job_definition")}</DialogTitle>
				<DialogContent>
					<TextField style={{marginTop:10}} fullWidth={true} size="small" id="desc" label={ETVConstants.trans("Description")} value={this.state.edit_desc} onChange={(e)=>{this.setState({edit_desc:e.target.value})}} />
					<TextField style={{marginTop:10}} fullWidth={false} type='number' size="small" id="duration" label={ETVConstants.trans("job_duration_minutes")} value={this.state.edit_duration} onChange={(e)=>{this.setState({edit_duration:e.target.value})}} />
					<Typography variant='body2' style={{marginTop:20}}>{ETVConstants.trans("required_skills")}</Typography>
					<div>
						{this.printAvailableSkills()}
					</div>
				</DialogContent>
				<DialogActions>
					<Button size='small' color="primary" onClick={() => this.showWindow('add_job_definition',false)}>{ETVConstants.trans("cancel")}</Button>
					<Button size='small' variant="contained" startIcon={<Save/>} color="primary" disabled={!this.state.edit_desc || !this.state.edit_duration} onClick={()=>{this.updateJobDefinition()}}>{ETVConstants.trans("save")}</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={this.state.window==='message_div'}
				maxWidth='xs'
				fullWidth={true}
				onClose={()=>{this.setState({window:''})}}>
				<DialogTitle id="message div">{this.state.info_title}</DialogTitle>
				<DialogContent>
						<Typography variant="body1">{this.state.info_message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
				</DialogActions>
			</Dialog>

        <div>
          <br/>
          <Button variant="contained" startIcon={<Add/>} color="primary" onClick={() => this.showAddJobDefinition()} >{ETVConstants.trans("add_new_job_definition")}</Button>
        </div>
	   </div>);
	}
}

export default JobDefinition
