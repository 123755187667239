import React from 'react';
import {Button} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as ETVConstants from '../ETVConstants';

class ConfirmDialog extends React.Component {


    render() {
      return (
        <Dialog
          open={this.props.open}
          onClose={()=>{}}
          size="xs"
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title"><InfoIcon fontSize="large" style={{color:'red'}}/>&nbsp;{ETVConstants.trans("confirm")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.text}
              {this.props.text_line_2?<span><br/>{this.props.text_line_2}</span>:<span/>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary" size="small">{ETVConstants.trans("cancel")}</Button>
            <Button onClick={this.props.onAgree} color="primary" variant="contained" size="small" autoFocus>{ETVConstants.trans("agree")}</Button>
          </DialogActions>
        </Dialog>
      )
    }
}


export default ConfirmDialog;
