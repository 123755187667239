import React from 'react';
import axios from 'axios';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Dropzone from 'react-dropzone';
import * as ETVConstants from '../ETVConstants';

class FileUploadAdmin extends React.Component {

	constructor(props) {
      super(props);
			this.getReplyStatus = this.getReplyStatus.bind(this);
    }

    state = {
			info_message:"",
			info_title:"",
			file_url:"",
			show_progress:false,
			msg_count:0,
			aborted:false,
			device_target_dir:'-',
			filename:'',
    }

		onDrop = (files) => {
				//console.log("on drop, files="+files);
				var targetDir = "/"+this.props.device_id+"/extra_files";
				for( var i=0; i<files.length; i++) {
					//console.log("drop files="+files[i].name);
					this.setState({filename:files[i].name});
					this.uploadFileToGCS( files[i], targetDir);
				}
		}

		// step 1
		uploadFileToGCS( file, targetDir) {
			this.showInfoMessage("info","uploading to storage...",true);
			//console.log("---------------------uploading file to GCS");
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var filename = file.name;
			//console.log("filename="+filename);
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
      //console.log("xxxxxxxxxxxxxx request GCS Upload, url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);
						//formData.append('success_action_redirect',)
						const config = {
	    				headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
									this.showInfoMessage("info","uploaded to cloud storage",true);
									this.givePublicAccess(targetDir,filename);
								})
								.catch((error) => {
									 this.showInfoMessage("error","error: "+error);
									 console.error(error);
								});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

		// step 2
		givePublicAccess(target_dir,filename) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+encodeURIComponent(filename);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log("result public access="+JSON.stringify(result));
							this.setState({file_url:result.url});
							//this.showInfoMessage("info","uploaded to storage, url="+result.url);
							var deviceID = this.props.device_id;
							var url = result.url;
							var targetDirOnDevice = this.state.device_target_dir;
							this.sendDownloadCommand(deviceID,url,filename,targetDirOnDevice);
						},
						(error) => {
							//console.log("yyy-------> "+error);
						}
					)
		}

		// step 3: submit download command
		sendDownloadCommand(deviceID, url,filename,targetDirOnDevice) {
			//console.log("send download command to deviceID="+deviceID+" url="+filename+" targetDir="+targetDirOnDevice);
			this.setState({msg_count:0,aborted:false});
			this.showInfoMessage("info","send downloading command to device",true);
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'file_download', fb_param_0:url, fb_param_1:filename, fb_param_2:targetDirOnDevice })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						//console.log('------------->'+JSON.stringify(result));
						if(result['result']) {
							//this.showInfoMessage('Device','....submit password to device, messageID='+result.data.message_id);
							this.setState({message_id:result.data.message_id});
							//console.log("sent message reply="+JSON.stringify(result));
							if(!this.state.aborted) setTimeout(this.getReplyStatus, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result.reason);
						}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),error);
				}
			)
		}

		// step 4, waiting and polling for reply
		getReplyStatus() {
			//console.log("getReplyStatus");
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("info"),ETVConstants.trans("could_not_reach_device"));
				this.deleteFile(this.state.filename,this.props.device_id+"/extra_files");
				return;
			}
			this.showInfoMessage(ETVConstants.trans("info"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log("reply message status="+JSON.stringify(result));
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						//console.log("replyMsg="+JSON.stringify(replyMsg));
						var pw_is_set = replyMsg.success;
						if(pw_is_set) {
							this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("file_downloaded"));
							this.deleteFile(this.state.filename,this.props.device_id+"/extra_files");
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),replyMsg.reason);
							this.deleteFile(this.state.filename,this.props.device_id+"/extra_files");
						}
					}else{
						//console.log("////////////////// error //////////////////, not restarting");
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getReplyStatus, 1000);
					}
				},
				(error) => {
				}
			)
		}

		// step 5: delete file
		deleteFile( file, dir) {
			//console.log("trying to delete file on server: "+file+" targetDir="+dir);
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var imgPath = dir+"/"+this.state.filename;
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=delete&file_uri="+encodeURIComponent(imgPath);
			//console.log("xxxxxxxxxxxxxx delete file_uri="+imgPath);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
							this.setState({filename:'',device_target_dir:'-'});
							//console.log("file deleted, result="+JSON.stringify(result));
					},
					(error) => {
							this.setState({filename:'',device_target_dir:'-'});
							//console.log("error "+error);
					}
				)
		}

		showWindow(divID, show) {
			{ show?this.setState({window:divID}):this.setState({window:''}) };
		}

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg, show_progress:false});
		}

		showInfoMessage(title,msg,showProgess) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg, show_progress:showProgess});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

    render() {
      return(
				<div>
						<FormControl>
							 <FormLabel component="legend">{ETVConstants.trans("target_dir_on_device")}</FormLabel>
							 <Select
								 labelId="target_dir"
								 id="targetDirOnDevice"
								 value={this.state.device_target_dir}
								 onChange={(e)=>{this.setState({device_target_dir:e.target.value})}}>
								 		 <MenuItem value={"-"}>--- choose target directory on device ---</MenuItem>
										 <MenuItem value={""}>elevatortv</MenuItem>
										 <MenuItem value={"channel0"}>elevatortv/channel0</MenuItem>
										 <MenuItem value={"channel0/local"}>elevatortv/channel0/local</MenuItem>
										 <MenuItem value={"channel1"}>elevatortv/channel1</MenuItem>
										 <MenuItem value={"channel1/local"}>elevatortv/channel1/local</MenuItem>
										 <MenuItem value={"channel2"}>elevatortv/channel2</MenuItem>
										 <MenuItem value={"channel2/local"}>elevatortv/channel2/local</MenuItem>
										 <MenuItem value={"channel3"}>elevatortv/channel3</MenuItem>
										 <MenuItem value={"channel3"}>elevatortv/channel3/local</MenuItem>
										 <MenuItem value={"channel4"}>elevatortv/channel4</MenuItem>
										 <MenuItem value={"channel4"}>elevatortv/channel4/local</MenuItem>
										 <MenuItem value={"floorpics"}>elevatortv/floorpics</MenuItem>
										 <MenuItem value={"layout"}>elevatortv/layout</MenuItem>
							 </Select>
						 </FormControl>

						 {this.state.device_target_dir==='-'?<span/>:
								<div style={{marginTop:20}}>
								<Dropzone
										onDrop={this.onDrop}
										showPreviewsInDropzone={false}
										filesLimit={1}
										dropzoneText={ETVConstants.trans("drag_and_drop_file_here_or_click_to_upload_a_new_file")}
										maxFileSize={1048576}
										showAlerts={false}
								/>
								</div>
							}

						<Dialog
							open={this.state.window==='message_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={() => this.setState({window:'',aborted:true, show_progress:false})  }
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
							<DialogContent>
								 <Typography variant='body1'>{this.state.info_message}</Typography>
								 {this.state.show_progress?<CircularProgress/>:<span/>}
							</DialogContent>
							<DialogActions>
								<Button onClick={() => this.setState({window:'',showProgress:false}) } color="primary">{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>


		   	</div>
			);
	}
}

export default FileUploadAdmin
