import React from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import {Button,Divider,Paper,TextField} from '@mui/material';
import CheckBoxList2 from '../tools/CheckBoxList2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker,TimePicker } from '@mui/x-date-pickers';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import {CardContent,CardActions,OutlinedInput} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import * as ETVConstants from '../ETVConstants';
import dayjs from 'dayjs';
import LazyLoadImage from '../tools/LazyLoadImage';

class ContentSettings extends React.Component {

	constructor(props) {
      super(props);
      this.weekdayCallback = this.weekdayCallback.bind(this);
    }

    state = {
		content_id: undefined,
		fromTime: ETVConstants.createTimeHoursMin(0,0),
		toTime: ETVConstants.createTimeHoursMin(0,0),
		fromDate: dayjs(),
		toDate: ETVConstants.createDateYearsPlus(1),
		updateDate: false,
		updateTime: false,
		cardMediaUrl: undefined,
		showingTime: 20,
		weekdayList: [],
		qr_code: '',
		qr_code_type:'url',
		qr_code_helpertxt:'',
		description: '',
		content_category:'',
		needs_save: false,
		loading_thumbnail:false,
    }


    componentDidMount() {
      this.initSettings(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevProps.library_element!==this.props.library_element) {
        this.initSettings();
      }
      if(this.state.updateDate) {
        this.updateDate();
      }
      if(this.state.updateTime) {
        this.updateTime();
      }
    }

    initSettings() {
		var elem = this.props.library_element;
		if(!elem) {
			return;
		}
		var id=elem.content_id;

		var dFrom = null;
		if(elem.from_date && elem.from_date.length>0) {
			dFrom = ETVConstants.createDate(elem.from_date);
		}else{
			dFrom = ETVConstants.createDateNow();
		}

		var dTo = null;
		if(elem.to_date && elem.to_date.length>0) {
			dTo =  ETVConstants.createDate(elem.to_date);
		}

		var dFromTime = null;
		if(elem.from_time && elem.from_time.length>0) {
			dFromTime = ETVConstants.createTime(elem.from_time);
		}else{
			dFromTime = ETVConstants.createTimeHoursMin(0,0);
		}

		var dToTime = null;
		if(elem.to_time && elem.to_time.length>0) {
			dToTime = ETVConstants.createTime(elem.to_time);
		}else{
			dToTime = ETVConstants.createTimeHoursMin(0,0);
		}

		var sT = elem.showing_time;
		var weekdays = this.createWeekdayList(elem.weekdays);
		var thumb = this.getThumbnail(elem);
		var qrCode = elem.qr_code;
		var desc = elem.description;
		var cat = elem.content_category;
		this.setState( {content_id:id, content_category:cat, fromDate:dFrom, toDate:dTo, fromTime:dFromTime, toTime:dToTime, cardMediaUrl:thumb, showingTime: sT, weekdayList: weekdays, qr_code:qrCode, description:desc, needs_save:false} );
    }


	createWeekdayList( list) {
		var checkBoxList = [];
		checkBoxList.push( {name:'monday',isChecked:this.setContains(list,'monday')});
		checkBoxList.push( {name:'tuesday',isChecked:this.setContains(list,'tuesday')});
		checkBoxList.push( {name:'wednesday',isChecked:this.setContains(list,'wednesday')});
		checkBoxList.push( {name:'thursday',isChecked:this.setContains(list,'thursday')});
		checkBoxList.push( {name:'friday',isChecked:this.setContains(list,'friday')});
		checkBoxList.push( {name:'saturday',isChecked:this.setContains(list,'saturday')});
		checkBoxList.push( {name:'sunday',isChecked:this.setContains(list,'sunday')});
		return checkBoxList;
	}

	updateContentRegistry( url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then( res => res.json() )
			.then(
					(result) => {
						this.setState({isLoaded:false, needs_save:false});
					},
					(error) => {
						//console.log("yyy-------> "+error);
					}
				)
	}

    isMovie( filename) {
      if (filename.endsWith(".mpg")) return true;
      if (filename.endsWith(".mpeg")) return true;
      if (filename.endsWith(".mp4")) return true;
      return false;
    }

    isHTML( filename) {
      if (filename.endsWith(".htm")) return true;
      if (filename.endsWith(".html")) return true;
      return false;
    }

    getThumbnail(elem) {
      var imageUrl = elem.thumbnail;
      if(!imageUrl) {
        if(this.isMovie(elem.filename.toLowerCase())) {
          imageUrl = "/images/content/movie_placeholder.png";
        }else if(this.isHTML(elem.filename.toLowerCase())) {
          imageUrl = "/images/content/html_placeholder.png";
        }
      }
      return imageUrl;
    }

	setFromDate = (date) => {
		if(this.state.toDate<date) {
			this.setState({fromDate:date, toDate:date, updateDate:true});
		}else{
			this.setState({fromDate:date, updateDate:true});
		}
  	}

	setToDate = (date) => {
		if(this.state.fromDate>date) {
			this.setState({toDate:date, fromDate:date, updateDate:true});
		}else{
			this.setState({toDate:date, updateDate:true});
		}
	};

	resetDate() {
		this.setState({fromDate:null,toDate:null, updateDate:true});
	}

	updateDate() {
		var from = "";
		if(this.state.fromDate && this.state.fromDate!=='') {
			from = ETVConstants.formatDate(this.state.fromDate);
		}
		var to = "";
		if(this.state.toDate && this.state.toDate!=='') {
			to = ETVConstants.formatDate(this.state.toDate);
		}
		var url = ETVConstants.getServerBase()+"/content_library?action=set_date&content_id="+this.state.content_id+"&from_date="+from+"&to_date="+to;
		//console.log("update date url="+url);
		this.updateContentRegistry(url);
		this.setState({updateDate:false})
	}

	updateTime() {
		var from = ETVConstants.formatTime(this.state.fromTime);
		var to = ETVConstants.formatTime(this.state.toTime);
		//console.log("("+this.state.fromTime+"|"+this.state.toTime+")---->fromtime="+from+" toTime="+to);		
		var url = ETVConstants.getServerBase()+"/content_library?action=set_time&content_id="+this.state.content_id+"&from_time="+from+"&to_time="+to;
		this.updateContentRegistry(url);
		this.setState({updateTime:false})
	}

	setFromTime = (time) => {
		if(this.state.toTime<time) {
			this.setState({fromTime:time, toTime:time, updateTime: true});
		}else{
			this.setState({fromTime:time, updateTime: true});
		}
	};

	resetTime() {
		this.setState({fromTime:ETVConstants.createTimeHoursMin(0,0), toTime:ETVConstants.createTimeHoursMin(0,0), updateTime: true});
	}

	setToTime = (date) => {
		if(this.state.fromTime>date) {
			this.setState({toTime:date, fromTime:date, updateTime: true});
		}else{
			this.setState({toTime:date, updateTime: true});
		}
	};

	handleShowingTimeChange = (event) => {
		this.setState({showingTime:event.target.value, needs_save:true});
	}

	setContains( setItems, item) {
		for(var i=0; i<setItems.length; i++) {
			if(setItems[i]===item) {
				return true;
			}
		}
		return false;
	}

	weekdayCallback( weekdayList) {
		var str = "";
		for(var i=0; i<weekdayList.length; i++) {
			str+="&weekday=";
			str+=weekdayList[i];
		}
		var url = ETVConstants.getServerBase()+"/content_library?action=update_weekdays&content_id="+this.state.content_id+""+str;
		this.updateContentRegistry(url);
	}

    saveSettings() {
      var showingTime = Math.max(5,this.state.showingTime);
      var url = ETVConstants.getServerBase()+"/content_library?action=set_config&content_id="+this.state.content_id+"&showing_time="+showingTime;
      var qrCode = this.state.qr_code;
      if(qrCode) {
        url += "&qr_code="+encodeURIComponent(qrCode);
      }
      var desc = this.state.description;
      if(desc) {
        url += "&description="+encodeURIComponent(desc);
      }
      var contentCat = this.state.content_category;
      if(contentCat) {
        url += "&content_category="+contentCat;
      }
      this.setState({showingTime:showingTime})
      this.updateContentRegistry(url);
    }

    isMovieOrMp3() {
      	var filename = this.props.library_element.filename;
      	if(filename.toLowerCase().endsWith(".mp4")) return true;
      	if(filename.toLowerCase().endsWith(".mp3")) return true;
      	if(filename.toLowerCase().endsWith(".mpeg")) return true;
      	if(filename.toLowerCase().endsWith(".mpg")) return true;
		if(filename.toLowerCase().endsWith(".mkv")) return true;
		if(filename.toLowerCase().endsWith(".webm")) return true;
      	if(filename.toLowerCase().endsWith(".m4a")) return true;
		if(filename.toLowerCase().endsWith(".3gp")) return true;
		if(filename.toLowerCase().endsWith(".wmv")) return true;
		if(filename.toLowerCase().endsWith(".mov")) return true;
		if(filename.toLowerCase().endsWith(".avi")) return true;
      	return false;
    }

    registerThumbnail( contentID, fileUrl) {
      	var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
      	var url = ETVConstants.getServerBase()+"/content_library?action=register_thumbnail&content_id="+contentID+"&thumbnail="+encodeURIComponent(fileUrl);
      	fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        	.then( res => res.json() )
        	.then(
            	(result) => {
              		this.setState({isLoaded:false});
              		this.setState({loading_thumbnail:false,cardMediaUrl:fileUrl});
            	},
            	(error) => {
              		//console.log("yyy-------> "+error);
            	}
          )
    }

    onDrop = (files) => {
		var targetDir = "/"+this.props.user_id+"/content_library";
		this.setState({loading_thumbnail:true});
		for( var i=0; i<files.length; i++) {
			//console.log("drop files="+files[i]);
			this.uploadFileToGCS( files[i], targetDir);
		}
	}

	uploadFileToGCS( file, targetDir) {
      var myRand = Math.floor((Math.random() * 100000) + 1);
      var filename = myRand+"_"+file.name;
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);
						//formData.append('success_action_redirect',)
						const config = {
							headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
									this.givePublicAccess(targetDir,filename);
									this.setState({isLoaded:false});
								})
								.catch((error) => {
									 console.error(error);
								});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

    // make public access to uploaded files
    givePublicAccess(target_dir,filename) {
      //console.log("---------------------giving public access");
      var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
      //console.log("url="+url);
      fetch(url)
        .then( res => res.json() )
        .then(
            (result) => {
              //console.log("result after public access="+JSON.stringify(result));
              var contentID = this.props.library_element.content_id;
              var url = result.url;
              this.registerThumbnail(contentID,url);
            },
            (error) => {
            }
          )
    }

	updateQRCodeType( type) {
		if(type==='url') {
			this.setState({qr_code_type:type, qr_code_helpertxt:'', needs_save:true});
		}else if(type==='phone') {
				this.setState({qr_code_type:type,qr_code_helpertxt:"tel:+65xxxxxx", needs_save:true});
		}else if(type==='whatsapp') {
				this.setState({qr_code_type:type, qr_code_helpertxt:"https://wa.me/+65xxxxx:subject", needs_save:true});
		}else if(type==='sms') {
				this.setState({qr_code_type:type,qr_code_helpertxt:"SMSTO:+65xxxxx:subject", needs_save:true});
		}
	}

	//input: 23:11
	createTime(hourMinStr) {
		//console.log("---->hourMinStr="+hourMinStr);
		var hours = hourMinStr.substring(0,2);
		var min = hourMinStr.substring(3);
		var d = dayjs();
		const updatedDateTime = d.hour(hours).minute(min).second(0); 
		return updatedDateTime;
	}

    render() {
      var fromTime = this.state.fromTime;
      var toTime = this.state.toTime;
      var fromDate = this.state.fromDate;
      var toDate = this.state.toDate;
      var editable = this.props.editable;
      var isMovieOrMp3 = this.isMovieOrMp3();
      var isNewsProvider = this.props.user_roles && this.props.user_roles.includes('admin.content.contribute_marketplace');
      //var fingerprint = "fingerprint: "+this.props.library_element.fingerprint;
      //console.log("contentsettings, user_roles="+this.props.user_roles+" isNewsProvider="+isNewsProvider);
      return(
            <span>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<Card>
							<CardContent>
								{ this.state.cardMediaUrl && !this.state.loading_thumbnail?
									<img src={this.state.cardMediaUrl} alt="ff" style={{width:300, height:'auto'}} />
									:<span/>
								}
								{!isNewsProvider?<span/>:
									<div style={{display: 'inline-block', width: 200, height:200, margin: 5}}>
										<Dropzone
											onDrop={this.onDrop}
											disabled={false}
											accept="image/jpeg"
											multiple={false}
											minSize={1024}
											maxSize={2000000}
											style={{width:'100%',height:'100%'}}>
											{({ getRootProps, getInputProps }) => (
												<div {...getRootProps({ className: "dropzone" })}>
														<input {...getInputProps()} />
														<p>{ETVConstants.trans("drag_and_drop_or_click_to_upload_thumbnail_max_2mb")}</p>
												</div>
											)}
										</Dropzone>
									</div>
								}
							</CardContent>
						</Card>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Card>
							<CardContent style={{width:400}}>
								<Typography variant="h6" color="primary" gutterBottom>{ETVConstants.trans("general_settings")}</Typography>
								<FormControl sx={{ m: 1, width: 300 }}>
        							<InputLabel id="content_category">{ETVConstants.trans("content_category")}</InputLabel>
									<Select
										labelId="content_category"
										disabled={!editable}
										fullWidth
										size="small"
										input={<OutlinedInput label={"content_category"} />}
										id="content_category"
										value={this.state.content_category}
										onChange={(e)=>{this.setState({content_category:e.target.value, needs_save:true})}}>
										<MenuItem value='---'>---</MenuItem>
										<MenuItem value='info'>{ETVConstants.trans("information")}</MenuItem>
										<MenuItem value='news'>{ETVConstants.trans("news")}</MenuItem>
										<MenuItem value='entertainment'>{ETVConstants.trans("entertainment")}</MenuItem>
										<MenuItem value='education'>{ETVConstants.trans("education")}</MenuItem>
										<MenuItem value='inspiration'>{ETVConstants.trans("inspiration")}</MenuItem>
										<MenuItem value='music'>{ETVConstants.trans("music")}</MenuItem>
										<MenuItem value='template'>{ETVConstants.trans("template")}</MenuItem>
									</Select>
									{isMovieOrMp3?<span/>:
										<TextField
											style={{marginTop:20}}
											label={ETVConstants.trans("showing_time_s")} 
											fullWidth
											size="small"
											value={this.state.showingTime}
											disabled={!editable}
											type="number"
											onChange={this.handleShowingTimeChange}
										/>
									}
									{!this.props.library_element?<span/>:
										<TextField 
											style={{marginTop:20}} 
											variant='outlined' 
											multiline 
											minRows={2} 
											maxRows={2} 
											fullWidth 
											disabled={!editable} 
											label={ETVConstants.trans("description")} 
											value={this.state.description} 
											onChange={(e)=>{this.setState({description:e.target.value, needs_save:true})}} />
									}
									<Typography style={{marginTop:20}} variant="h6" color="primary" gutterBottom>{ETVConstants.trans("qr_code_optional")}</Typography>
									<Typography variant="body2" gutterBottom>{ETVConstants.trans("qr_code_info")}</Typography>
									{!this.props.library_element?<span/>:
										<span>
											<Select
												labelId="qr_code_type_label"
												size="small"
												disabled={!editable}
												id="qr_code_type_label_id"
												style={{ width:200, marginTop:10}}
												value={this.state.qr_code_type}
												onChange={(e)=>{ this.updateQRCodeType(e.target.value)}}>
													<MenuItem value='url'>URL</MenuItem>
													<MenuItem value='whatsapp'>{ETVConstants.trans("whatsapp")}</MenuItem>
													<MenuItem value='phone'>{ETVConstants.trans("phone")}</MenuItem>
													<MenuItem value='sms'>{ETVConstants.trans("sms")}</MenuItem>
											</Select>
											<TextField 
												style={{marginTop:10}}
												helperText={this.state.qr_code_helpertxt}
												fullWidth
												disabled={!editable}
												label={''}
												variant="standard"
												value={this.state.qr_code}
												onChange={(e)=>{this.setState({qr_code:e.target.value, needs_save:true})}} 
											/>
										</span>
									}
								</FormControl>
							</CardContent>
							<CardActions>
								{!editable?<span/>:
									<Button size='small' disabled={!this.state.needs_save} variant='contained' color="success" onClick={() => this.saveSettings()}>{ETVConstants.trans("save")}</Button>
								}
							</CardActions>
						</Card>
					</Grid>
				</Grid>

				<Divider style={{marginTop:20, marginBottom:20}} />

				<Grid container spacing={2}>
					
					<Grid item sm={6} xs={12}>
						<Card style={{padding:20,paddingTop:15,paddingBottom:0}} elevation={5}>
							<Typography variant="h6" color="primary" gutterBottom>{ETVConstants.trans("select_weekdays")}</Typography>
							{this.state.weekdayList?
								<CheckBoxList2 disabled={!editable} translateF={this.trans} callback={this.weekdayCallback} title='' list={this.state.weekdayList} />
								:
								<span/>
							}
						</Card>
					</Grid>
					
					{/* first column */}
					<Grid item sm={6} xs={12} key={1}>
						<Card elevation={5}>
							<CardContent>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Typography variant="h6" color="primary" gutterBottom>{ETVConstants.trans("select_time")}</Typography>
									<br/>
									<TimePicker
										style={{marginTop:20}}
										disabled={!editable}
										margin="normal"
										id="time-picker_from"
										minutesStep={5}
										format="HH:mm"
										ampm={false}
										label={ETVConstants.trans("from_time")}
										value={fromTime}
										onChange={this.setFromTime}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
									/>
									<br/><br/>
									<TimePicker
										style={{marginTop:20}}
										disabled={!editable}
										margin="normal"
										id="time-picker_to"
										minutesStep={5}
										format="HH:mm"
										ampm={false}
										label={ETVConstants.trans("to_time")}
										value={toTime}
										onChange={this.setToTime}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
									/>
								</LocalizationProvider>
							</CardContent>
							<CardActions>
								<Button disabled={!editable} size='small' variant='contained' color="error" onClick={()=>{this.resetTime()}}>{ETVConstants.trans("clear_time")}</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Card elevation={5}>
							<CardContent>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Typography variant="h6" color="primary" gutterBottom>{ETVConstants.trans("select_date")}</Typography>
									<br/>
									<DatePicker
										margin="normal"
										disabled={!editable}
										id="date-picker-dialog_year_from"
										label={ETVConstants.trans("from_date")}
										format='YYYY-MM-DD'
										value={fromDate?fromDate:null}
										onChange={this.setFromDate}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
									<br/><br/>
									<DatePicker
										margin="normal"
										disabled={!editable}
										id="date-picker-dialog_year_to"
										label={ETVConstants.trans("until_date")}
										format='YYYY-MM-DD'
										value={toDate?toDate:null}
										onChange={this.setToDate}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}} 
									/>
								</LocalizationProvider><br/>
							</CardContent>
							<CardActions>
								<Button disabled={!editable} size='small' variant='contained' color="error" onClick={()=>{this.resetDate()}}>{ETVConstants.trans("clear_date")}</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
            </span>
 		);
	}
}

export default ContentSettings
