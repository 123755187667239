import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as ETVConstants from '../ETVConstants';

class AuditLog extends React.Component {

    state = {
      isLoaded: false,
      list:[],
      window:''
    }

    componentDidMount() {
			this.loadData();
    }

		loadData() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/audit_log?user_id="+this.props.user_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
      .then((response) => response.json())
      .then((logs) => {
          //console.log("list="+logs.list);
          this.setState({
              list: logs.list.reverse(),
						  isLoaded:true,
          })
      })
      .catch((error) => {
      });
    }

		showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

		showInfoMessage(title,text) {
			this.setState({info_message_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_message:'',info_message_title:''});
			this.showWindow('message_div',false);
		}

    showDate( dateStr) {
      var d = new Date(dateStr);
      return <Typography sx={{fontWeight: 'bold'}}>{d.toLocaleString()}</Typography>
    }

    createTable() {
      return(
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1200}} size="small" aria-label="log table">
          <TableHead>
            <TableRow>
              <TableCell align="left"><Typography variant="h6">{ETVConstants.trans("date_time")}</Typography></TableCell>
              <TableCell align="left"><Typography variant="h6">{ETVConstants.trans("user")}</Typography></TableCell>
              <TableCell align="left"><Typography variant="h6">{ETVConstants.trans("log")}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.list.map((row) => (
              <TableRow key={row.audit_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{this.showDate(row.date_time)}</TableCell>
                <TableCell component="th" scope="row">{row.user_name}</TableCell>
                <TableCell align="left">{row.log}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
    }

    render() {
      return(
				<span>
          <Card sx={{ minHeight: '60vh', overflow: 'auto' }}>
                  {this.createTable()}
          </Card>

          <Dialog
              open={this.state.window==='message_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={() => this.setState({window:''})  }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
              <DialogContent>
                  <Typography variant='body1'>{this.state.info_message}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({window:''}) } color="primary">{ETVConstants.trans("close")}</Button>
              </DialogActions>
          </Dialog>
	   		</span>);
	    }
}

export default AuditLog
