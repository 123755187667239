import React from 'react';
import SelectDevice from './SelectDevice';
import PlaylistChannel from './PlaylistChannel';
import * as ETVConstants from '../ETVConstants';

class Playlist extends React.Component {

    constructor(props) {
      super(props);
      this.layoutSelected = this.layoutSelected.bind(this);
    }

    state = {
      curDevice:undefined,
      curDeviceID:'',
      curDeviceAPK:'',
      curGroupID:-1,
      user_access: undefined,
      layoutInfo:undefined,
    }

    componentDidUpdate(prevProps,prevState) {
      if(!this.state.isLoaded) {
        this.loadUserAccess();
      }
      if(prevProps.user_id!==this.props.user_id) {
        this.loadItems();
      }
    }

    componentDidMount() {
      this.loadUserAccess();
    }

    layoutSelected( deviceObj, groupID, layoutID) {
        if(groupID>=0) {
          this.setState({curDeviceID:null,curGroupID:groupID});
        }else{
          this.setState({curDeviceAPK:deviceObj.sw_version, curDevice:deviceObj,curDeviceID:deviceObj.id,curGroupID:groupID});
        }
        this.loadLayoutInfo(layoutID);
    }

    loadUserAccess() {
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              user_access:result,
              isLoaded: true,
            });
          })
    }

    canSeeChannel( deviceID, channelNr) {
      var channels = this.state.user_access[deviceID];
      if(!channels) {
        return true;
      }
      var included = channels.includes(channelNr);
      return included;
    }

    canSeeChannelOnAllDevices(deviceIDs,channelNr) {
      for(var i=0; i<deviceIDs.length; i++) {
        if(!this.canSeeChannel(deviceIDs[i],channelNr)) return false;
      }
      return true;
    }

    canCopy(deviceID) {
      // if there is restrictions on channels, then can not copy
      var channels = this.state.user_access[deviceID];
      //console.log("can copy: channels="+channels);
      if(channels) {
        // there is a restrictions on channels --> dont allow to copy
        return false;
      }
      return true;
    }

    loadLayoutInfo( layoutID) {
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/layout_mgmt?action=get_layout&layout_id="+layoutID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							this.setState({layoutInfo:result});
						},
						(error) => {
              this.setState({layoutInfo:undefined});
						}
					)
    }

    getDeviceIDsInGroup(groupID) {
      var groups = this.state.user_access.groups;
      for(var group=0; group<groups.length; group++ ) {
        if(groups[group].group_id===groupID) {
          return groups[group].device_ids;
        }
      }
      return [];
    }


    render() {
      //console.log("roles="+this.props.roles);
      var rows = [];
      var hasMarketAccess = this.props.roles && this.props.roles.includes('premium.content.access_market_place');
      if(this.state.layoutInfo) {
        for(var channel=0; channel<this.state.layoutInfo.channels; channel++) {
          if(this.state.curGroupID>=0) {
            //make sure that user can see the channel in all devices in the group
            var groupDevices = this.getDeviceIDsInGroup(this.state.curGroupID);
            if (this.canSeeChannelOnAllDevices(groupDevices,channel)) {
              rows.push(channel);
            }
          }else if(this.canSeeChannel(this.state.curDeviceID,channel)) {
            rows.push(channel);
          }
        }
      }
      var isDeviceOwner = false;
      if(this.state.curDevice) {
        isDeviceOwner = (this.state.curDevice.owner_id === this.props.user_id);
      }
      return (
        <div>
          <SelectDevice user_id={this.props.user_id} layoutSelectedF={this.layoutSelected} can_copy={this.state.curDevice?this.canCopy(this.state.curDevice.id):false} />
          {rows.map( (element,i) => { return <div key={i} ><br /><PlaylistChannel key={'playlist_'+element} channel={element} sw_version={this.state.curDeviceAPK} is_device_owner={isDeviceOwner} group_id={this.state.curGroupID} device_id={this.state.curDeviceID} marketaccess={hasMarketAccess} user_id={this.props.user_id} children={this.props.children} /></div>} ) }
        </div>
      )
    }
}

export default Playlist;
