import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker,DatePicker } from '@mui/x-date-pickers';
import * as ETVConstants from '../ETVConstants';
import dayjs from 'dayjs';

class Countdown extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
      ticker_text:"",
      channel: "0",
      selected_screens: [],
      validating: false,
      selected_date: ETVConstants.createDateNow(),
      selected_time: ETVConstants.createDateNow(),
    }

    selectedScreens(screenIDs) {
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    getFilename() {
      var m = new Date();
      var dateString = ""+m.getUTCFullYear() + (m.getUTCMonth()+1) + m.getUTCDate() + m.getUTCHours() +  m.getUTCMinutes() + m.getUTCSeconds();
      return dateString+"_countdown.html";
    }

    saveCountdown() {
      this.setState({validating:true});
      var d0 = this.state.selected_date;
      var time = this.state.selected_time;
      d0.setHours(time.getHours());
      d0.setMinutes(time.getMinutes());
      this.showInfoMessage("Uploading...");
      var url = ETVConstants.getServerBase()+"/template_servlet?action=create_and_save_template&user_id="+this.props.user_id;
      url += "&template_path=templates/countdown/countdown_template.html";
      url += "&filename="+this.getFilename();
      url += "&showing_time=20";
      url += "&param_0.name=@@title@@&param_0.value="+encodeURIComponent(this.state.ticker_text);
      url += "&param_1.name=@@date@@&param_1.value="+encodeURIComponent(d0);
      url += "&desc="+encodeURIComponent(this.state.ticker_text);
      url += "&thumbnail="+encodeURIComponent(ETVConstants.getServerBase()+"/images/thumbnails/countdown_thumbnail.jpg");

      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        url += "&channel="+this.state.channel;
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&device_id="+this.state.selected_screens[i];
        }
      }

      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      console.log("url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              ticker_text:"",
              channel: "0",
              selected_screens: [],
              validating: false,
              selected_date: new Date(),
              selected_time: new Date(),
            });
            this.showInfoMessage(ETVConstants.trans("the_countdown_ticker_was_stored_in_your_content_library"));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
            this.setState({
              ticker_text:"",
              channel: "0",
              selected_screens: [],
              validating: false,
              selected_date: new Date(),
              selected_time: new Date(),
            });
          }
        )
    }

    buttonIsDisabled() {
      if(!this.state.ticker_text ) return true;
      if(this.state.ticker_text.trim().length===0) return true;
      return false;
    }

    render() {

      return (
        <div style={{width:'700px'}} >
          <Card>
            <CardContent>
                <Typography variant="h5">
                    {ETVConstants.trans("countdown_ticker")}
                </Typography>
                <div style={{marginTop:20}} />
                <Typography variant="body1" >
                    {ETVConstants.trans("countdown_clock_until_given_date")}
                </Typography>
                <div style={{marginTop:20}} />
                     <TextField required
                                key='countdown_text'
                                label={ETVConstants.trans("countdown_text_max_100_chars")}
                                InputProps={{ maxLength: 100 }}
                                fullWidth={true}
                                value={this.state.ticker_text}
                                onChange={(event)=>{this.setState({ticker_text:event.target.value})}} />
                <div style={{marginTop:20}} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <Grid container justifycontent="space-around">
                       <DatePicker
                         disableToolbar
                         format="MM/DD/YYYY"
                         margin="normal"
                         id="date-picker-inline"
                         label={ETVConstants.trans("countdown_date")}
                         value={this.state.selected_date}
                         onChange={(date)=>{this.setState({selected_date:date})}}
                         KeyboardButtonProps={{
                           'aria-label': 'change date',
                         }}
                       />
                       <span style={{margin:'20px'}} />
                       <TimePicker
                         margin="normal"
                         id="time-picker"
                         label={ETVConstants.trans("countdown_time")}
                         value={this.state.selected_time}
                         onChange={(time)=>{this.setState({selected_time:time})}}
                         KeyboardButtonProps={{
                           'aria-label': 'change time',
                         }}
                       />
                     </Grid>
                  </LocalizationProvider>
                  <div style={{marginTop:20}}>
                    <Button variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
                  </div>
                  <br/>
                  <FormControl style={{marginTop:10}}>
                      <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                      <Select
                        label={ETVConstants.trans("channel")}
                        id="channel_id"
                        style={{ width: 300}}
                        value={this.state.channel}
                        onChange={(e)=>{this.setState({channel:e.target.value})}}>
                        <MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
                        <MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
                        <MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
                        <MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
                        <MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
                      </Select>
                    </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={this.buttonIsDisabled()} color="primary" startIcon={<UploadFileIcon />} variant='contained' onClick={() => this.saveCountdown()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
								<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
					</div>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default Countdown;
